import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {ToastContainer} from "react-toastify";
import {handleSubmitActions, formFail, formSuccess} from "../../libs";

const ServerSystemSettings = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [saveBtn, setSaveBtn] = useState(false);
    const [item1, setItem1] = useState(props.emergency_contact);
    const [campusId, setCampusId] = useState(dData?.acnt);


    function performSave(){
        let payload;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        payload = [{emergency_contact: item1}]
        ApiRequester({reqEndpoint: "mytransaction", TransType: "system_server_settings", Payload: payload,
                            CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
            if(data[0]){
                setSaveBtn(false);
                props?.setServerData(prevVal => ({
                    ...prevVal, emergency_contact: item1
                }));
                formSuccess("Saved app settings", "editSubmit", "loadEditSubmit");
            }
            else{
                formFail("Error encountered, save failed", "editSubmit", "loadEditSubmit");
            }
        })
    }

    useEffect(() => {
        if ([item1 !== (props.emergency_contact)].some((item) => {return item})){
            setSaveBtn(true);
        }else{
            setSaveBtn(false);
        }
    }, [item1, props]);

    return (
        <>
            <h4 style={{maxWidth: "650px"}}>* Settings changed on this page require your local palcare server to be
                rebooted to take effect.</h4>
            <div className={"card"} style={{maxWidth: "650px"}}>
                <div className={"card-header"}>
                    <h4 className={"card-header-title"}>Server Settings</h4>
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                            spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                    {saveBtn && <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"}
                                        class={"btn btn-primary"} onClick={() => performSave()} />}
                </div>
                <div className={"card-body"}>
                    <form  className={"was-validated"}>
                        <div className={"list-group list-group-flush my-n3"}>
                            <div className={"list-group-item"}>
                                <div className={"form-floating"} style={{maxWidth: "400px"}}>
                                    <input className={"form-control"} id={"emergcontact"} value={item1}
                                           pattern={"^([A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,})(?:,\\s*([A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}))*$"}
                                           // pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}
                                           required={true}
                                           onChange={(e) => {
                                               setItem1(e.target.value);
                                           }}
                                           onKeyUp={(e) => {
                                               setItem1(e.target.value);
                                           }}/>
                                    <label htmlFor={"emergcontact"}>System Offline Notification Email Address</label>
                                    <div className={"invalid-feedback"}>
                                        Provide a valid email address to get notified when your system is offline!
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default ServerSystemSettings;