import { ReactSession } from 'react-client-session';
import {Navigate} from "react-router-dom";
import {AES, enc} from "crypto-js";
import Navigation from "./Navigation";
import ProfileContent from "./ProfileContent";
import LegacyProfileContent from "./LegacyProfileContent";
import SessionReload from "./component-assets/SessionReload";
import HeaderBanner from "./component-assets/HeaderBanner";
import {compareRole, iAmHigherAuthority} from "../libs";

function Profile(props) {
    let dData, bData, profileContent;
    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData.migrated){
        profileContent = <ProfileContent />;
    }else{
        profileContent = <LegacyProfileContent />;
    }

    return (compareRole("", dData?.userrole || iAmHigherAuthority(0, dData?.role_weight))) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {profileContent}
        </>
    ) : <Navigate to={"/"}/>;
}

export default Profile;