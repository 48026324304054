import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import { useParams } from "react-router";
import LegacyEventReports from "./LegacyEventReports";
import EventReports from "./EventReports";
import ScheduledReports from "./ScheduledReports";
import WellnessReports from "./WellnessReports";
import SessionReload from "./component-assets/SessionReload";
import HeaderBanner from "./component-assets/HeaderBanner";
import GroupReports from "./GroupReports";
import ExecutiveReport from "./ExecutiveReport";
import EnvironmentReports from "./EnvironmentReports";
import MaintenanceReports from "./MaintenanceReports";
import {compareRole, iAmHigherAuthority} from "../libs";

const Reports = (props) => {
    let reportContent, dData, paramVars, bData;
    paramVars = useParams();

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    if (dData?.migrated){
        if (paramVars.dType === "alerts"){
            reportContent = <EventReports toFetch={"alerts"} run={paramVars?.eType === "run"} />;
        }else if (paramVars.dType === "maintenance"){
            reportContent = <MaintenanceReports />;
        }else if (paramVars.dType === "wellness"){
            reportContent = <WellnessReports />;
        }else if (paramVars.dType === "scheduled"){
            reportContent = <ScheduledReports />;
        }else if (paramVars.dType === "grouped"){
            reportContent = <GroupReports />;
        }else if (paramVars.dType === "executive"){
            reportContent = <ExecutiveReport />;
        }else if (paramVars.dType === "environmental"){
            reportContent = <EnvironmentReports />;
        }else{
            reportContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }else{
        if (paramVars.dType === "alerts"){
            reportContent = <LegacyEventReports toFetch={"alerts"}/>;
        }
        else if (paramVars.dType === "resident"){
            reportContent = <LegacyEventReports toFetch={"resident"}/>;
        }
        else if (paramVars.dType === "caregiver"){
            reportContent = <LegacyEventReports toFetch={"caregiver"}/>;
        }
        else if (paramVars.dType === "maintenance"){
            reportContent = <LegacyEventReports toFetch={"maintenance"}/>;
        }
        else if (paramVars.dType === "inspection"){
            reportContent = <LegacyEventReports toFetch={"inspection"}/>;
        }
        else if (paramVars.dType === "checkin-transaction"){
            reportContent = <LegacyEventReports toFetch={"checkin-transaction"}/>;
        }
        else if (paramVars.dType === "checkin-history"){
            reportContent = <LegacyEventReports toFetch={"checkin-history"}/>;
        }
        else{
            reportContent = (
                <div>
                    <h3>Page not found. 404</h3>
                </div>
            );
        }
    }

    return (compareRole("caregiver", dData?.userrole) || iAmHigherAuthority(10, dData?.role_weight)) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            {reportContent}
        </>
    ) : <Navigate to={"/"}/>;
};

export default Reports;