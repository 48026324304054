import {Link} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "./component-assets/Button";
import ApiRequester from "./ApiRequester";
import {
    paginateData, sortFunc, formFail, handleSubmitActions, actionAppearance, deleteModalSingle,
    formClear, bulkDeleteModal, swapModal, checkAll, formCheck, formSuccess,
    formCheckWithElement, showModal, closeModal, hideModal, iAmHigherAuthority, areaLearninBehaviorToggle
} from "../libs"
import {toast, ToastContainer} from "react-toastify";
import {Modal} from "bootstrap";
import {CSVLink} from "react-csv";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Header from "./Header";
import Footer from "./Footer";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import SingleDeleteModal from "./component-assets/SingleDeleteModal";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";

const Areas = (props) => {
    let dData, bData, headerData, csvHeaders, headerData2;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    headerData = [
        {label: "Name", key: "area_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Area Group", key: "area_group_name", align: "left", type: "ellipsis"},
        {label: "Area Type", key: "area_type_description", align: "left"},
        {label: "Residents", key: "residents", align: "center",
            link: {enabled: true, linkto: "/profiles/resident", linkkey: "resident_id", type: "obj", displayKey: "resident_name"}},
        {label: "Devices", key: "description", align: "left"},
    ];

    if (dData?.rounds){
        headerData.splice(-1, 0, {label: "Last Rounds", key: "last_rounds_time", align: "center", type: "datetime"});
    }

    csvHeaders = [
        {label: "Name", key: "area_name"},
        {label: "Area Group", key: "area_group_name"},
        {label: "Area Type", key: "area_type_description"},
        {label: "Max Occupancy", key: "max_occupancy"},
        {label: "Devices", key: "description"},
    ];
    if (dData?.rounds){
        csvHeaders.splice(-1, 0, {label: "Last Rounds", key: "last_rounds_time"});
    }

    headerData2 = [
        {label: "Group", key: "area_group_name", align: "left"},
        {label: "Description", key: "area_group_description", align: "left"},
    ];

    // Main Table Data Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [loadState, setLoadState] = useState("individuals");

    // Permission Variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(80, dData?.role_weight));
    const [editRights, setEditRights] = useState(iAmHigherAuthority(80, dData?.role_weight));
    const [devEditRights, setDevEditRights] = useState(iAmHigherAuthority(10, dData?.role_weight));
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(80, dData?.role_weight));

    // Main Table Control Variables
    const [areaIdList, setAreaIdList] = useState([]);
    const [count, setCount] = useState(0);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [storedIndex, setStoredIndex] = useState(0);
    const [areaGroupIdList, setAreaGroupIdList] = useState([]);
    const [oldAreaGroupIdList, setOldAreaGroupIdList] = useState([]);
    const [search, setSearch] = useState("");
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [sorter, setSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "asc"
    });
    // Device asset variables
    const [deviceData, setDeviceData] = useState([]);
    const [editDeviceList, setEditDeviceList] = useState([]);
    const [editSystemDeviceList, setEditSystemDeviceList] = useState([]);
    // Device Learn in Variables
    const [listening, setListening] = useState(false); // Flag indicating listening started, stopped
    const [listenEnabled, setListenEnabled] = useState(false); // Flag if listen feature ready or not
    const [listenCount, setListenCount] = useState(60);  // Counter for fetching and toggle listen off
    const [listenData, setListenData] = useState([]);  // Data response of devices heard
    const [listenList, setListenList] = useState([]); // Drop down options for heard devices serial numbers
    const [deviceCatData, setDeviceCatData] = useState([]);  // Device category data used for drop down in learn in
    const [deviceCatOptions, setDeviceCatOptions] = useState([]);  // Device category drop down options for learn in
    const [deviceTypeData, setDeviceTypeData] = useState([]); // Data used for device type drop down in learn in
    const [deviceTypeOptions, setDeviceTypeOptions] = useState([]); // Device type drop down options for learn in
    const [deviceCatId, setDeviceCatId] = useState(0);  // Device Category set in learn in
    const [deviceTypeId, setDeviceTypeId] = useState([]);  // Device Type set in learn in
    const [modelId, setModelId] = useState(0);  // Model selected in list
    const [modelOptions, setModelOptions] = useState([]);
    const [msgClass, setMsgClass] = useState(0);
    const [productCode, setProductCode] = useState(0);
    const [marketId, setMarketId] = useState(0);
    const [allProductCodes, setAllProductCodes] = useState([]);
    const [showDeviceList, setShowDeviceList] = useState(false); // Toggles showing drop down serial or not and required validation
    const [listenDevice, setListenDevice] = useState("");
    const [deviceTypeName, setDeviceTypeName] = useState("");
    const [listenPCode, setListenPCode] = useState(0);
    const [useOptions, setUseOptions] = useState([]);
    const [useId, setUseId] = useState(0);
    const [showAlarmBehaviors, setShowAlarmBehaviors] = useState(false);
    // Area Group Variables
    const [groupData, setGroupData] = useState([]);
    const [groupDataCnt, setGroupDataCnt] = useState(0);
    const [groupTableRows, setGroupTableRows] = useState([]);
    const [search2, setSearch2] = useState("");
    const [sorter2, setSorter2] = useState({
        sortColumn: "area_group_name",
        sortOrder: "asc"
    });
    // Area Type Data and dd Vars
    const [areaTypes, setAreaTypes] = useState([]);
    const [areaDd, setAreaDd] = useState([]);
    const [assignedArea, setAssignedArea] = useState("");
    const [areaTypeSorter, setAreaTypeSorter] = useState({
        sortColumn: "area_type_name",
        sortOrder: "asc"
    });
    // Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "Areas.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getdeviceandgroupforareabycampus", reqType: "stored"}).then(data => {
                setDataOrigin(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_area_type",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setAreaTypes(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "core_area_groups",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setGroupData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getInovonicsDevices", reqType: "stored"}).then(data => {
                setDeviceData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getareadevicecategories", reqType: "stored"}).then(data => {
                setDeviceCatData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "getinovonicsdevicetypes", reqType: "stored"}).then(data => {
                setDeviceTypeData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                        class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        let tmpLocations, input, pRules;
        tmpLocations = [];
        input = search.toString().toLowerCase();
        for (let i=0; i<dataOrigin.length; i++){
            if ((dataOrigin[i]?.area_name && dataOrigin[i]?.area_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.area_type_description && dataOrigin[i]?.area_type_description?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.area_group_name && dataOrigin[i]?.area_group_name?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.description && dataOrigin[i]?.description?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.resident_name && dataOrigin[i]?.resident_name?.toString()?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.max_occupancy && dataOrigin[i]?.max_occupancy?.toString()?.toLowerCase()?.search(input) !== -1)){
                tmpLocations.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpLocations, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpLocations);
    }, [search, dataOrigin]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={deleteRights}
                                writeAccess={editRights} unk={"a-"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={editModal} modal={editRights} parentComponent={"Areas"}
                                dData={dData} checkboxlist={areaIdList}
                                setCheckboxId={(value) => {setAreaIdList(value)}} checkidkey={"area_id"} />);
    }, [activeData, sorter, page, perPage, areaIdList, groupData]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search2?.toString()?.toLowerCase();
        for (i = 0; i < groupData.length; i++){
            if (groupData[i]?.area_group_category_id !== 11){
                if ((groupData[i]?.area_group_name && groupData[i]?.area_group_name?.toLowerCase()?.search(input) !== -1) ||
                    (groupData[i]?.area_group_description && groupData[i]?.area_group_description?.toLowerCase()?.search(input) !== -1)){
                    tmp.push(groupData[i]);
                }
            }
        }
        setGroupDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter2.sortColumn, sorter2.sortOrder));
        setGroupTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={editRights} unk={"x-"}
                                     actionAppearance={() => {}} tableHeaders={headerData2} checkboxId={"uCheckGroup"}
                                     parentComponent={"Areas"} dData={dData} checkboxlist={areaGroupIdList} modal={false} editModal={() => {}}
                                     setCheckboxId={(value) => {setAreaGroupIdList(value)}} checkidkey={"area_group_id"} />);
    }, [groupData, areaGroupIdList, sorter2, search2, activeData]);

    useEffect(() => {
        if (areaTypes.length > 0){
            let tmpOptions = [];
            areaTypes.sort((a, b) => sortFunc(a, b, areaTypeSorter.sortColumn, areaTypeSorter.sortOrder));
            for (let i=0; i<areaTypes.length; i++){
                tmpOptions.push(
                    <option key={`area-type-option-${areaTypes[i].area_type_id}`}
                            value={areaTypes[i].area_type_id}>{areaTypes[i].area_type_description}
                    </option>
                );
            }
            setAreaDd(() => tmpOptions);
        }
    }, [areaTypes]);

    useEffect(() => {
        if (deviceCatData.length > 0){
            let tmpOptions = []
            for (let i=0; i < deviceCatData.length; i++){
                tmpOptions.push(
                    <option key={`device-category-option-${deviceCatData[i].device_category_id}`}
                            value={deviceCatData[i].device_category_id}>{deviceCatData[i].device_category_short_name}
                    </option>
                );
            }
            setDeviceCatOptions(() => tmpOptions);
        }
    }, [deviceCatData]);

    useEffect(() => {
        let i, tmp = [];
        deviceTypeData.sort((a, b) => sortFunc(a, b, "device_type_name", "asc"));
        for (i = 0; i < deviceTypeData.length; i++){
            if ((deviceTypeData[i]?.device_category_id.toString() === deviceCatId.toString()) &&
                (iAmHigherAuthority(80, dData.role_weight) || !deviceTypeData[i]?.is_third_party)){
                tmp.push(
                    <option key={`device-use-option-${deviceTypeData[i]?.inovonics_type_id}`}
                            value={deviceTypeData[i]?.inovonics_type_id}>{deviceTypeData[i]?.device_type_name}
                    </option>
                );
            }
        }
        setDeviceTypeOptions(() => tmp);
    }, [deviceTypeData, deviceCatId]);

    useEffect(() => {
        let tmp = [], models = [], tmp2 = [], uses = [], tmp3 = [];
        for (let i=0; i < deviceTypeData.length; i++){
            if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId){
                models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                uses = deviceTypeData[i]?.uses ? deviceTypeData[i].uses : [];
                break
            }
        }
        for (let i2=0; i2 < models.length; i2++){
            if (i2 === 0){
                setModelId(models[i2].model_id);
                setMsgClass(models[i2].message_class);
                setProductCode(models[i2].product_type_id);
                setMarketId(models[i2].market_id);
            }
            tmp2.push(models[i2].product_type_id);
            tmp.push(
                <option key={`device-model-option-${i2}`}
                        value={models[i2]?.model_id}>{models[i2]?.model_name}
                </option>
            );
        }
        for (let i3=0; i3 < uses.length; i3++){
            if (i3 === 0){
                setUseId(uses[i3].inovonics_device_use_id);
                setShowAlarmBehaviors(uses[i3].option_number !== 0);
            }
            if (uses[i3].option_number !== 0){
                tmp3.push(
                    <div className={"form-check form-switch mt-1"} id={`use-option-${uses[i3].option_number}-container`}>
                        <input className={"form-check-input"} id={`use-option-${uses[i3].option_number}`} type={"radio"}
                               name={"alarmBehavior"} value={uses[i3].inovonics_device_use_id} defaultChecked={i3 === 0}
                               onChange={(e) => {setUseId(parseInt(e.target.value))}} />
                        <label className={"form-check-label me-4 mt-1 text-dark"}
                               htmlFor={`use-option-${uses[i3].option_number}`} id={`use-option-label-${uses[i3].option_number}`}>
                            {uses[i3].option_text}
                        </label>
                    </div>
                );
            }
        }
        setAllProductCodes(tmp2);
        setModelOptions(() => tmp);
        setUseOptions(() => tmp3);
    }, [deviceTypeData, deviceTypeId]);

    useEffect(() => {
        let models = [];
        for (let i = 0; i < deviceTypeData.length; i++) {
            if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId) {
                models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                break;
            }
        }
        for (let i2 = 0; i2 < models.length; i2++) {
            if (models[i2].model_id === modelId) {
                setMsgClass(models[i2].message_class);
                setProductCode(models[i2].product_type_id);
                setMarketId(models[i2].market_id);
            }
        }
        setListenEnabled(modelId !== 0);
    }, [modelId]);

    useEffect(() => {
        buildDeviceList(-1);
    }, [activeData]);

    useEffect(() => {
        formCheck("pendantForm", "pendantSubmit");
    }, [listenDevice]);

    useEffect(() => {
        let interval;
        if (checkListenValid()){
            interval = setInterval(() => {
                setListenCount(listenCount  => listenCount  + 1);
            }, 1000);
            if (listenCount < 60){
                if (listenCount % 4 === 0){
                    ApiRequester({reqEndpoint: "getitems", Table: "LearnIn", PKey: "ClientId",
                        PVal: dData.sessionId, CampusId: campusId}).then(data => {
                            setListenData(data);
                    });
                }
            }
            if (listenCount === 60){
                setListening(false);
                toast.info("Capture timeout, restart capture if device not in list.");
            }
        }
        return () => clearInterval(interval);
    }, [listenCount]);

    useEffect(() => {
        let tmpList = [];
        for (let i=0; i < listenData.length; i++){
            tmpList.push(
                <option key={`listen-${listenData[i]?.trx_id}`} value={[listenData[i]?.trx_id, listenData[i]?.pType]}>
                    {listenData[i]?.name} ({listenData[i]?.trx_id})
                </option>
            );
        }
        setListenList(tmpList);
        if (listenDevice === "" && listenData.length > 0){
            setListenDevice(() => listenData[0]?.trx_id);
            setListenPCode(() => listenData[0]?.pType);
            toast.success("Devices found.");
        }
    }, [listenData]);

    function checkListenValid(){
        return [listening, deviceCatId !== 0, deviceTypeId !== 0].every((item) => item === true);
    }

    function editModal(editIndex){
        let name, dataIndex, locId, modal, delBtn, groupIdList = [];
        if (editRights){
            dataIndex = parseInt(editIndex);
            setSearch2("");
            setStoredIndex(dataIndex);
            buildDeviceList(dataIndex);
            name = document.getElementById("nameE");
            locId = document.getElementById("areaIdE");
            document.getElementById("livingcapE").value = activeData[dataIndex]?.max_occupancy;
            document.getElementById("areaTypeE").value = activeData[dataIndex]?.area_type_id;
            name.value = activeData[dataIndex]?.area_name;
            locId.value = activeData[dataIndex]?.area_id;
            document.getElementById("uCheckHead2").checked = false;
            for (let i2=0; i2<activeData[dataIndex]?.area_groups?.length; i2++){
                groupIdList.push(activeData[dataIndex]?.area_groups[i2]?.area_group_id.toString());
            }
            setAreaGroupIdList(groupIdList);
            setOldAreaGroupIdList(groupIdList);
            document.getElementById("uCheckHead2").checked = groupIdList && groupIdList.length === groupData.length;
            delBtn = document.getElementById("deleteSingleBtn");
            delBtn.onclick = () => {
                setDContent([[locId.value, ""]]);
                deleteModalSingle(name.value, name.value);
            };
            formCheck("editForm", "editSubmit");
            modal = new Modal(document.getElementById("editmodal"), {});
            modal.show();
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function removeDevice(deviceIdList){
        let payload = [];
        if (devEditRights){
            for (let i=0; i < deviceIdList.length; i++){
                payload.push({device_id: deviceIdList[i], is_active: false, area_id: null});
            }
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "POST", User: dData.user,
                                PKey: "device_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
                if (data && data[0] === true){
                    setCount(0);
                    toast.success("Devices have been removed");
                }
                else{
                    toast.error("Failed to remove devices");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function buildDeviceList(dataIndex){
        let tmp = [], tmp2 = [];
        if (dataIndex === -1){
            dataIndex = storedIndex;
        }
        for (let i=0; i < activeData[dataIndex]?.devices?.length; i++){
            if ([4, 5, 13, 14, 0, null, undefined].includes(activeData[dataIndex]?.devices[i]?.device_category_id)){
                tmp2.push(
                    <tr key={`tr-item-${activeData[dataIndex]?.devices[i].device_id}`}>
                        <td>
                            {editRights &&
                                <i className={"fe fe-x-square red-hover fs-1 me-3"}
                                   onClick={() => removeDevice([activeData[dataIndex]?.devices[i].device_id])}/>}
                        </td>
                        <td>
                            {activeData[dataIndex]?.devices[i].description} - {activeData[dataIndex]?.devices[i].manufacture_uuid}
                        </td>
                    </tr>
                );
            }else{
                tmp.push(
                    <tr key={`tr-item-${activeData[dataIndex]?.devices[i].device_id}`}>
                        <td>
                            {devEditRights &&
                                <i className={"fe fe-x-square red-hover fs-1 me-3"}
                                   onClick={() => removeDevice([activeData[dataIndex]?.devices[i].device_id])}/>}
                        </td>
                        <td>
                            <Link to={`/profiles/inovonics/${activeData[dataIndex]?.devices[i].device_id}`} onClick={() => closeModal("cancelEditBtn")}>
                                {activeData[dataIndex]?.devices[i].description} - {activeData[dataIndex]?.devices[i].manufacture_uuid}
                            </Link>
                        </td>
                    </tr>
                );
            }
        }
        setEditDeviceList(tmp);
        setEditSystemDeviceList(tmp2);
    }

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        setAreaIdList([]);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    function formAddSubmit(){
        // User add form submit function
        let name, livingcap, payload, areaTypeId;
        if (addRights){
            handleSubmitActions("addSubmit", "loadAddSubmit");
            name = document.getElementById("name").value;
            livingcap = document.getElementById("livingcap").value;
            areaTypeId = document.getElementById("areaType").value;
            payload = [{area_name: name, campus_id: campusId, max_occupancy: parseInt(livingcap),
                        area_type_id: parseInt(areaTypeId), area_group_ids: areaGroupIdList}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "area_add", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0] === true){
                    setAreaIdList([]);
                    formSuccess(`${name} has been added.`, "addSubmit", "loadAddSubmit", "cancelAddBtn", "addForm");
                    setCount(0);
                }
                else{
                    formFail(`Error during add, error code: ${data[1]}`, "addSubmit", "loadAddSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    // * * * * * * * * * * * * * * * * * * *
    // All Function For User Edit Behavior
    // * * * * * * * * * * * * * * * * * * *

    function formEditSubmit(){
        // Location add form submit function
        let name, livingcap, payload, locUnqId, areaTypeId, oldName, oldLivingCap, oldCampusId, oldAreaTypeId,
            oldPayload;
        if (editRights){
            handleSubmitActions("editSubmit", "loadEditSubmit");
            name = document.getElementById("nameE").value;
            livingcap = document.getElementById("livingcapE").value;
            locUnqId = document.getElementById("areaIdE").value;
            areaTypeId = document.getElementById("areaTypeE").value;
            oldName = activeData[storedIndex]?.area_name;
            oldLivingCap = activeData[storedIndex]?.max_occupancy;
            oldAreaTypeId = activeData[storedIndex]?.area_type_id;
            oldCampusId = activeData[storedIndex]?.campus_id;
            payload = [{area_id: locUnqId, area_name: name, max_occupancy: parseInt(livingcap),
                        area_type_id: parseInt(areaTypeId), area_group_ids: areaGroupIdList, campus_id: campusId}];
            oldPayload = [{area_id: locUnqId, area_name: oldName, max_occupancy: oldLivingCap,
                           area_type_id: oldAreaTypeId, area_group_ids: oldAreaGroupIdList, campus_id: oldCampusId}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "area_edit", Payload: payload,
                                CampusId: campusId, User: dData.user, OldPayload: oldPayload,
                                ClientId: dData.sessionId}).then(data => {
                    if (data[0]){
                        setAreaIdList([]);
                        formSuccess(`${name} updated.`, "editSubmit", "loadEditSubmit", "cancelEditBtn");
                        setCount(0);
                    }
                    else{
                        formFail(`Error during save, error code: ${data[1]}`, "editSubmit", "loadEditSubmit");
                    }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function removeDevicesFromAreas(areaList){
        let deviceList = [];
        for (let i=0; i < activeData.length; i++){
            if (areaList.includes(activeData[i].area_id)){
                if (activeData[i]?.devices){
                    for (let i2=0; i2 < activeData[i]?.devices.length; i2++){
                        deviceList.push(activeData[i]?.devices[i2].device_id);
                    }
                }
            }
        }
        if (deviceList.length > 0){
            removeDevice(deviceList);
        }
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload, areaList = [];
        if (deleteRights){
            handleSubmitActions(submitBtn, loadBtn);
            payload = [];
            for (let i=0; i < dContent.length; i++){
                areaList.push(dContent[i][0]);
                payload.push({area_id: dContent[i][0]});
            }
            removeDevicesFromAreas(areaList);
            ApiRequester({reqEndpoint: "mytransaction", TransType: "area_delete", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    setCount(0);
                    setAreaIdList([]);
                    formSuccess("Areas deleted.", submitBtn, loadBtn, closeId);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["deleteBtn"]);
                }
                else{
                    formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deviceExists(serialNum){
        for (let i=0; i < deviceData.length; i++){
            if (serialNum.toString() === deviceData[i]?.manufacture_uuid.toString()) {
                return deviceData[i];
            }
        }
        return false;
    }

    function getModelId(){
        let models = [], ret = 0;
        for (let i=0; i < deviceTypeData.length; i++){
            if (deviceTypeData[i]?.inovonics_type_id === deviceTypeId){
                models = deviceTypeData[i]?.models ? deviceTypeData[i].models : [];
                break;
            }
        }
        for (let i2=0; i2 < models.length; i2++){
            if (listenPCode === models[i2].product_type_id){
                ret = models[i2].model_id;
            }
        }
        return ret;
    }

    function pendantSubmit(initial){
        let description, msg, payload, serialNum, name, areaId, dRec, nid, mId, oldPayload;
        if (devEditRights){
            if (showDeviceList){
                serialNum = listenDevice;
            }else{
                if (!serialNum || serialNum === ""){
                    serialNum = document.getElementById("serialNum").value;
                }
                if (serialNum && !isNaN(serialNum)){
                    serialNum = parseInt(serialNum);
                }
            }
            dRec = deviceExists(serialNum);
            if (initial){
                if (dRec && dRec?.is_active){
                    setAssignedArea(dRec?.area_name);
                    hideModal("pendantmodal");
                    showModal("pendantconfirmmodal");
                    return
                }else{
                    handleSubmitActions("pendantSubmit", "loadPendantSubmit");
                }
            }else{
                handleSubmitActions("pendantConfirmSubmit", "loadPendantConfirmSubmit");
            }

            description = document.getElementById("description").value;
            areaId = document.getElementById("areaIdE");
            mId = modelId;
            if (!iAmHigherAuthority(80, dData.role_weight)){
                mId = getModelId(listenPCode);
            }

            if (dRec){
                // Edit device and add to area
                payload = [{device_id: dRec.device_id, campus_id: campusId, is_active: true, area_id: parseInt(areaId),
                            device_use_id: parseInt(useId), device_name: deviceTypeName, descriptions: description,
                            manufacture_model_id: parseInt(mId)}];
                oldPayload = [{device_id: dRec.device_id, campus_id: dRec?.campus_id, is_active: dRec?.is_active,
                               area_id: dRec?.area_id, device_use_id: dRec?.device_use_id,
                               device_name: dRec?.device_name, descriptions: dRec?.descriptions,
                               manufacture_model_id: dRec?.manufacture_model_id}];
                if (["135", "131"].includes(useId.toString())){
                    if (["135"].includes(useId.toString())){
                        nid = document.getElementById("nid")?.value;
                        if (!nid){
                            nid = 0;
                        }
                    }else{
                        nid = -1;
                    }
                    payload[0]["network_id"] = parseInt(nid);
                    ApiRequester({reqEndpoint: "mytransaction", TransType: "system_device_edit", Payload: payload,
                                CampusId: campusId, User: dData.user, OldPayload: oldPayload,
                                ClientId: dData.sessionId}).then(data => {
                        if (data[0]) {
                            msg = `${deviceTypeName} has been added to ${name}`;
                            if (!initial){
                                setCount(0);
                                setAreaIdList([]);
                                handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                                handleSubmitActions("loadPendantSubmit", "pendantSubmit");
                                swapModal("pendantConfirmClose", "editmodal");
                                toast.success(msg);
                                document.getElementById("pendantForm").reset();
                            }else{
                                formSuccess(msg, "pendantSubmit", "loadPendantSubmit",
                                "cancelPendantBtn", "pendantForm");
                                setCount(0);
                            }
                        } else {
                            toast.error(`Failed to add ${deviceTypeName}`);
                        }
                        if (listening){
                            setListening(false);
                            setListenCount(60);
                        }
                    });
                }else{
                    ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", PKey: "device_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", Payload: payload, OldPayload: oldPayload,
                                User: dData.user, CampusId: campusId}).then(data => {
                        if (data && data[0] === true){
                            msg = `${deviceTypeName} has been added to ${name}`;
                            if (!initial){
                                setCount(0);
                                setAreaIdList([]);
                                handleSubmitActions("loadPendantConfirmSubmit", "pendantConfirmSubmit");
                                swapModal("pendantConfirmClose", "editmodal");
                                toast.success(msg);
                                document.getElementById("pendantForm").reset();
                            }else{
                                formSuccess(msg, "pendantSubmit", "loadPendantSubmit", "cancelPendantBtn", "pendantForm");
                                setCount(0);
                            }
                        }
                        else{
                            toast.error(`Failed to add ${deviceTypeName}`);
                        }
                        if (listening){
                            setListening(false);
                            setListenCount(60);
                        }
                    });
                }
            }else{
                // Add device and add to area
                payload = [{campus_id: campusId, manufacture_id: 1, manufacture_uuid: serialNum.toString(), device_name: deviceTypeName,
                            device_use_id: parseInt(useId), is_active: true, is_alerting_now: false, is_server: false,
                            is_area_device: true, is_resident: false, area_id: parseInt(areaId), manufacture_model_id: parseInt(mId),
                            escalation_enabled: true, descriptions: description}];
                if (["135", "131"].includes(useId.toString())){
                    if (["135"].includes(useId.toString())){
                        nid = document.getElementById("nid")?.value;
                        if (!nid){
                            nid = 0;
                        }
                    }else{
                        nid = -1;
                    }
                    payload[0]["network_id"] = parseInt(nid);
                    ApiRequester({reqEndpoint: "mytransaction", TransType: "system_device_add", Payload: payload,
                                CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                        if (data[0]) {
                            formSuccess(`${deviceTypeName} has been added to ${name}`, "pendantSubmit", "loadPendantSubmit",
                                "cancelPendantBtn", "pendantForm");
                            setCount(0);
                        } else {
                            toast.error(`Failed to add ${deviceTypeName}`);
                        }
                        if (listening){
                            setListening(false);
                            setListenCount(60);
                        }
                    });
                }else{
                    ApiRequester({reqEndpoint: "mysave", Tbl: "core_device", Payload: payload, urlType: "PUT",
                                    CampusId: campusId, User: dData.user, ClientId: dData.sessionId}).then(data => {
                        if (data && data[0] === true){
                            formSuccess(`${deviceTypeName} has been added to ${name}`, "pendantSubmit", "loadPendantSubmit",
                                "cancelPendantBtn", "pendantForm");
                            setCount(0);
                        }
                        else{
                            toast.error(`Failed to add ${deviceTypeName}`);
                        }
                        if (listening){
                            setListening(false);
                            setListenCount(60);
                        }
                    });
                }
            }
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function publishListen(){
        ApiRequester({reqEndpoint: "mqtt", Operation: "learnin", ClientId: dData.sessionId,
                            ProductType: iAmHigherAuthority(80, dData.role_weight) ? [productCode] : allProductCodes,
                            CampusId: dData?.acnt, MsgClass: msgClass}).then(data => {

            if (data[0]){
                toast.info(`Listening for ${deviceTypeName} devices`);
                setListenCount(0);
            }
            else{
                toast.error("Error with auto listening, try again, " +
                    "if persist contact your customer success for assistance.");
                setListening(false);
                setShowDeviceList(false);
            }
        });
    }

    const tableHeaders = <TableHeaders checkbox={deleteRights} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end, areaIdList, setAreaIdList)}
                                       writeAccess={editRights} sortOrder={sorter.sortOrder} checkboxHeaderId={"uCheckHead"}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} modal={editRights} checkboxlist={areaIdList}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])} modal_name={"Edit"}/>

    const groupTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxlist={areaGroupIdList}
                                         checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, areaGroupIdList, setAreaGroupIdList, "uCheckHead2", "uCheckGroup")}
                                         writeAccess={editRights} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead2"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />
    // Must have separate header component per table so rendered header ID's do not conflict
    const groupAddTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxlist={areaGroupIdList}
                                         checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, areaGroupIdList, setAreaGroupIdList, "uCheckHead3", "uCheckGroup")}
                                         writeAccess={editRights} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead3"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {deleteRights && <Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                     id={"deleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                     onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData,
                                         "area_name", ["area_id", ""], "", setDContent)} />}
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                </div>
                {csvButton}
                {addRights && <Button text={"New Area"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                     icon={true} iconClass={"fe fe-plus me-2"}
                                     onClick={() => {
                                         setAreaGroupIdList([]);
                                         setSearch2("");
                                         document.getElementById("uCheckHead3").checked = false;
                                         setStoredIndex(undefined);
                                         showModal("addmodal");
                                         formCheck("addForm", "addSubmit")}} />}
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    )

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Community"} Title={"Areas"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                        <li className={"nav-item"}>
                            <Link to={"/community/residents"}>
                                <Button text={"Individuals"} class={loadState === "individuals" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("individuals")} />
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/community/areas/groups"}>
                                <Button text={"Groups"} class={loadState === "groups" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("groups")} />
                            </Link>
                        </li>
                    </ul>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Area</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"name"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"name"}>Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a name
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} id={"areaType"} defaultValue={undefined}
                                            required={true}>
                                        <option value={""}></option>
                                        {areaDd}
                                    </select>
                                    <label htmlFor={"areaType"}>Area Type</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"number"} className={"form-control"} id={"livingcap"} required={true}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           step={1} min={0} max={4} title={"Limits number of residents assigned to area."} />
                                    <label htmlFor={"livingcap"}>Max Occupancy</label>
                                    <div className={"invalid-feedback"}>
                                        0-4 Max. Best practice is to set 0 for common areas.
                                    </div>
                                </div>
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover d-sm-table"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {groupAddTableHead}
                                            </thead>
                                            <tbody>
                                                {groupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} onClick={() => formAddSubmit()} id={"addSubmit"}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticEditBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticEditBackdropLabel"}>Edit Area</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"w-50 d-inline-block"}>
                                    <input type={"hidden"} id={"areaIdE"} />
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"nameE"}
                                               onKeyUp={() => formCheck("editForm", "editSubmit")} required={true}
                                               pattern={"^[0-9a-zA-Z_\\- ]+"}/>
                                        <label htmlFor={"nameE"}>Name</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter a name!
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <select className={"form-select"} id={"areaTypeE"} required={true}>
                                            <option value={""}></option>
                                            {areaDd}
                                        </select>
                                        <label htmlFor={"areaTypeE"}>Area Type</label>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"number"} className={"form-control"} id={"livingcapE"}
                                               onKeyUp={() => formCheck("addForm", "addSubmit")}
                                               step={1} min={0} max={100} />
                                        <label htmlFor={"livingcapE"}>Max Occupancy</label>
                                        <div className={"invalid-feedback"}>
                                            Enter capacity from 0 - 100!
                                        </div>
                                    </div>
                                </div>
                                <div className={"w-50 d-inline-block align-top"} style={{paddingLeft: ".5em"}}>
                                    <div className={"modal-header px-1 pt-0 pb-3"}>
                                        <h2 className={"d-inline my-0 ms-2"}>Devices</h2>
                                        {editRights
                                            && <Button text={"Device"} type={"button"} class={"btn btn-primary ms-3"}
                                                     id={"pendantBtn"} icon={true} iconClass={"fe fe-plus me-2"}
                                                     onClick={() => {
                                                         document.getElementById("content-heading-container")?.classList?.add("d-none");
                                                         document.getElementById("alarm-heading-container")?.classList?.add("d-none");
                                                         setDeviceTypeId(0);
                                                         setDeviceCatId(0);
                                                         swapModal("cancelEditBtn", "pendantmodal")
                                                     }} />}
                                    </div>
                                    <div className={"modal-body px-1 py-3"}>
                                        <h4>Community Devices</h4>
                                        <table>
                                            <tbody>
                                                {editDeviceList}
                                            </tbody>
                                        </table>
                                    </div>
                                    {iAmHigherAuthority(80, dData.role_weight) && editSystemDeviceList.length > 0
                                        ? <div className={"modal-body px-1 py-3"}>
                                            <h4>System Devices</h4>
                                            <table>
                                                <tbody>
                                                    {editSystemDeviceList}
                                                </tbody>
                                            </table>
                                         </div>
                                        :  <></>
                                    }
                                </div>
                                <hr/>
                                <div className={"mt-1"}>
                                    <h3>Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2E"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover d-sm-table"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {groupTableHead}
                                            </thead>
                                            <tbody>
                                                {groupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                    {deleteRights && <Button text={"Delete"} type={"button"} class={"btn btn-danger me-3"}
                                                         id={"deleteSingleBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"} />}
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} onClick={() => formEditSubmit()} id={"editSubmit"}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticDeviceBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticDeviceBackdropLabel"}>Add Device</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantClose"} data-bs-dismiss={"modal"} aria-label={"Close"}
                                    onClick={() => setListening(false)}/>
                        </div>
                        <form id={"pendantForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3 d-inline-block me-2"} style={{width: "68%"}}>
                                    <select className={"form-select"} value={undefined}
                                            required={true} id={"device_category"}
                                            onChange={(e) => {
                                                setDeviceCatId(parseInt(e.target.value));
                                                setDeviceTypeId(0);
                                                setModelId(0);
                                                formCheck("pendantForm", "pendantSubmit");
                                            }}>
                                        <option value={""}></option>
                                        {deviceCatOptions}
                                    </select>
                                    <label htmlFor={"device_category"}>Device Category</label>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3 d-inline-block me-2"} style={{width: "68%"}}>
                                        <select className={"form-select"} value={undefined}
                                                required={true} id={"device_type_id"}
                                                onChange={(e) => {
                                                    setDeviceTypeId(parseInt(e.target.value));
                                                    setDeviceTypeName(e.target[e.target.selectedIndex].innerText);
                                                    areaLearninBehaviorToggle(e.target.value);
                                                    formCheck("pendantForm", "pendantSubmit");
                                                }}>
                                            <option value={""}></option>
                                            {deviceTypeOptions}
                                        </select>
                                        <label htmlFor={"device_type_id"}>Device Type</label>
                                    </div>
                                    <div className={"form-floating mb-3 d-inline-block ms-auto"} style={{width: "30%"}}>
                                        <select className={"form-select"} id={"modelId"} value={modelId}
                                                disabled={!iAmHigherAuthority(80, dData.role_weight)}
                                                onChange={(e) => {
                                                    setModelId(parseInt(e.target.value));
                                                    formCheck("pendantForm", "pendantSubmit");
                                                }}>
                                            {modelOptions}
                                        </select>
                                        <label htmlFor={"modelId"}>Model</label>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"description"}
                                           pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"description"}>Description (optional)</label>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={`form-floating mb-3 w-75 ${showDeviceList && "d-none"}`}
                                         id={"serialToggle"}>
                                        <input type={"text"} className={"form-control"} id={"serialNum"}
                                               required={!showDeviceList}
                                               onKeyUp={(e) => formCheckWithElement("pendantForm", "pendantSubmit", e, deviceData)}
                                               pattern={"^[0-9]+$"}/>
                                        <label htmlFor={"serialNum"}>Serial Number</label>
                                    </div>
                                    <div className={`form-floating mb-3 w-75 ${!showDeviceList && "d-none"}`}
                                         id={"serial2Toggle"}>
                                        <select className={"form-select"} id={"serialNum2"} required={showDeviceList}
                                                onClick={(e) => {
                                                    let tmp = e.target.value.split(",");
                                                    setListenDevice(tmp[0]);
                                                    setListenPCode(parseInt(tmp[1]));
                                                }}>
                                            {listenList}
                                        </select>
                                        <label htmlFor={"serialNum2"}>Serial Numbers</label>
                                    </div>
                                    <Button text={"Auto Listen"} id={"listen-btn-1"}
                                            class={`btn ${listenEnabled ? "btn-primary" : "btn-danger"} m-auto ${showDeviceList && "d-none"}`}
                                            disabled={!listenEnabled} onClick={() => {
                                        setListening(true);
                                        setShowDeviceList(true);
                                        publishListen()
                                    }}/>
                                    <Button text={"Stop Listen"} id={"listen-btn-2"}
                                            class={`btn ${listening ? "btn-success" : "btn-warning"} m-auto ${!showDeviceList && "d-none"}`}
                                            disabled={!listenEnabled} onClick={() => {
                                        setListening(false);
                                        setShowDeviceList(false);
                                    }}/>
                                </div>
                                <div id={"ncsettings"} className={"d-none"}>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"number"} className={"form-control"} id={"nid"}
                                               required={false} min={0} max={31} step={1} onChange={() => {
                                            formCheck("pendantForm", "pendantSubmit");
                                        }}/>
                                        <label htmlFor={"serialNum"}>Network ID</label>
                                        <div className={"invalid-feedback"}>
                                            Please enter a valid network id for coordinator. Valid 0 - 31
                                        </div>
                                    </div>
                                </div>
                                <div className={`list-group-item ${!showAlarmBehaviors && 'd-none'}`}
                                     id={"behavior-heading-container"}>
                                    <h4 className={"mb-4 text-center"}>Alarm Behavior</h4>
                                    {useOptions}
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPendantBtn"}
                                            onClick={() => {
                                                swapModal("pendantClose", "editmodal");
                                                formClear("pendantForm", "pendantSubmit");
                                                setListening(false);
                                            }}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadPendantSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantSubmit"}
                                            onClick={() => {
                                                pendantSubmit(true);
                                            }}>Submit
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"pendantconfirmmodal"} data-bs-backdrop={"static"}
                 data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticPendantConfirmLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticPendantConfirmLabel"}>Confirm Add</h2>
                            <button type={"button"} className={"btn-close"} id={"pendantConfirmClose"}
                                    data-bs-dismiss={"modal"} aria-label={"Close"}/>
                        </div>
                        <div className={"modal-body"}>
                            <p>Device is already linked to {assignedArea} Do you wish to re-assign this device?</p>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}}
                                    id={"pendantConfirmCancelBtn"}
                                    onClick={() => swapModal("pendantConfirmClose", "pendantmodal")}>No
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadPendantConfirmSubmit"}/>
                            <button type={"button"} className={"btn btn-primary ms-3"} id={"pendantConfirmSubmit"}
                                    onClick={() => pendantSubmit(false)}>YES
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <SingleDeleteModal
                deletePerform={() => deletePerform("deleteSubmit", "loadDeleteSubmit", "confirmClose", ppRules.start, ppRules.end)}
                header={"Delete Area"}/>
            <BulkDeleteModal
                deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", ppRules.start, ppRules.end)}
                start={ppRules.start} end={ppRules.end} header={"Delete Areas"}/>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage}
                          setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)}
                          setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default Areas;