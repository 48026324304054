import {Link} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import Header from "../Header";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {
    formatPhone, iAmHigherAuthority,
    formCheck,
    formFail,
    formSuccess,
    getCountryData,
    getCountryOptions, getStateOptionsByCountry,
    getZipRegexByCountry,
    handleSubmitActions,
    reloadSessionCorp,
    showModal, setRoleInfo,
    toggleCollapse, sortFunc
} from "../../libs";


const CorporateProfile = (props) => {
    let dData, bData, countryOptions = [];

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Permission Variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(80, dData.my_role_weight));
    const [editRights, setEditRights] = useState(iAmHigherAuthority(80, dData.my_role_weight));
    // Main Page Variables
    const [corpId, setCorpId] = useState(props?.id);
    const [count, setCount] = useState(0);
    const [count2, setCount2] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [pageLoaded, setPageLoaded] = useState(false);
    // Basic Information Variables
    const [basicData, setBasicData] = useState([]);
    const [corpName, setCorpName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [zipcode, setZip] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState(0);
    const [cntry, setCntry] = useState("US");
    const [campuses, setCampuses] = useState("");
    const [tzData, setTZData] = useState([]);
    const [tzOptions, setTZOptions] = useState([]);
    const [country, setCountry] = useState("");
    const [oldIndex, setOldIndex] = useState(-1);
    const [oldIndexText, setOldIndexText] = useState("");
    const [mCampData, setMCampData] = useState([])  // Data hold for campuses from dynamodb to migrate.
    const [mCampOptions, setMCampOptions] = useState([]);
    const [stateOptions, setStateOptions] = useState([]);
    const [zipReg, setZipReg] = useState(getZipRegexByCountry("US"));
    const [campusColumns, setCampusColumns] = useState(1);
    const [basicLoaded, setBasicLoaded] = useState(false);
    const [roleData, setRoleData] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);

    const countries = getCountryData();
    for (let i2=0; i2 < countries?.length; i2++){
        countryOptions.push(
            <option key={"country-option-"+i2} value={countries[i2][0]}>{countries[i2][1]}</option>
        );
    }

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                payload: {corp_filter: corpId.toString(), campus_filter: ""},
                                procedure: "getcorps", reqType: "stored"}).then(data => {
                setBasicData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_timezones",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setTZData(data ? data : []);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_user_roles",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setRoleData(data);
            });
            setCount(() => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (basicData && basicData.length > 0){
            let interval = null;
            interval = setInterval(() => {
                setCount2(count => count - 1);
            }, 1000);
            if (count2 <= 0){
                ApiRequester({reqEndpoint: "getall", Table: "Campus",
                            Search: ["CorporationId", basicData[0]?.corporate_guid],
                            Projection: "CampusId,CampusName", CampusId: campusId}).then(data => {
                    setMCampData(data);
                });
                setCount2(() => 600);
            }
            return () => clearInterval(interval);
        }
    }, [basicData, count2])

    useEffect(() => {
        if (![basicLoaded].includes(false)){
            setPageLoaded(true);
        }
    }, [basicLoaded]);

    useEffect(() => {
        let tmp = [];
        if (tzData && tzData?.length > 0){
            for (let i=0; i < tzData?.length; i++){
                tmp.push(<option key={`tz-option-${i}`} value={tzData[i]?.timezone_id}>
                    {tzData[i]?.timezone_name} ({tzData[i]?.timezone})
                </option>);
            }
            setTZOptions(tmp);
        }
    }, [tzData]);

    useEffect(() => {
        let tmp = [];
        if (roleData){
            roleData.sort((a, b) => sortFunc(a, b, "user_role_weight", "asc"));
            for (let i=0; i < roleData.length; i++){
                if (iAmHigherAuthority(roleData[i]?.user_role_weight, dData.my_role_weight)) {
                    tmp.push(
                        <option key={`role-option-${i}`} value={roleData[i]?.user_role_weight}>
                            {roleData[i]?.user_role_name}
                        </option>
                    );
                }
            }
            setRoleOptions(tmp);
        }
    }, [roleData]);

    useEffect(() => {
        let tmp = [];
        if (basicData?.length > 0){
            setCorpName(() => basicData[0]?.corporate_name ? basicData[0]?.corporate_name : "");
            setEmail(() => basicData[0]?.email ? basicData[0]?.email : "");
            setPhone(() => basicData[0]?.phone ? basicData[0]?.phone : "");
            setLatitude(() => basicData[0]?.latitude ? basicData[0]?.latitude : "");
            setLongitude(() => basicData[0]?.longitude ? basicData[0]?.longitude : "");
            setZip(() => basicData[0]?.zip ? basicData[0]?.zip : "");
            setCity(() => basicData[0]?.city ? basicData[0]?.city : "");
            setState(() => basicData[0]?.state ? basicData[0]?.state : "");
            setCntry(() => basicData[0]?.country ? basicData[0]?.country : "");
            for(let i=0; i < basicData[0]?.campuses?.length; i++){
                if (i > 0){
                    tmp.push(<br key={`break${i}`}/>);
                    tmp.push(<Link key={`campuses${i}`} to={`/profiles/campus/${basicData[0]?.campuses[i].campus_id}`}>
                        {basicData[0]?.campuses[i].campus_name}
                    </Link>);
                }else{
                    tmp.push(<Link key={`campuses${i}`} to={`/profiles/campus/${basicData[0]?.campuses[i].campus_id}`}>
                        {basicData[0]?.campuses[i].campus_name}
                    </Link>);
                }
            }
            if (tmp.length > 5 && tmp.length < 12){
                setCampusColumns(2);
            }else if (tmp.length > 11){
                setCampusColumns(3);
            }
            setCampuses(() => tmp);
            setBasicLoaded(true);
        }
    }, [basicData]);

    useEffect(() => {
        let tmp = [];
        if (mCampData && mCampData?.length > 0){
            for (let i=0; i < mCampData?.length; i++){
                tmp.push(<option key={`migrate-option-${mCampData[i]?.CampusId}`} value={mCampData[i]?.CampusId}>{mCampData[i]?.CampusName}</option>)
            }
            setMCampOptions(tmp);
        }
    }, [mCampData]);

    useEffect(() => {
        setCountryOptionsFunction(cntry);
    }, [cntry]);

    function setCountryOptionsFunction(inCountry){
        setStateOptions(getStateOptionsByCountry(inCountry));
        setZipReg(getZipRegexByCountry(inCountry));
    }

    function basicActionHandling(){
        document.getElementById("name-static").classList.toggle("d-none");
        document.getElementById("name-edit").classList.toggle("d-none");
        document.getElementById("country-static").classList.toggle("d-none");
        document.getElementById("country-edit").classList.toggle("d-none");
        document.getElementById("state-static").classList.toggle("d-none");
        document.getElementById("state-edit").classList.toggle("d-none");
        document.getElementById("city-static").classList.toggle("d-none");
        document.getElementById("city-edit").classList.toggle("d-none");
        document.getElementById("zipcode-static").classList.toggle("d-none");
        document.getElementById("zipcode-edit").classList.toggle("d-none");
        document.getElementById("email-static").classList.toggle("d-none");
        document.getElementById("email-edit").classList.toggle("d-none");
        document.getElementById("phone-static").classList.toggle("d-none");
        document.getElementById("phone-edit").classList.toggle("d-none");
        if (document.getElementById("edit-basic-btn").innerText === "Edit"){
            document.getElementById("edit-basic-btn").innerText = "Cancel";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
            document.getElementById("name-edit").value = corpName;
            document.getElementById("country-edit").value = cntry;
            document.getElementById("state-edit").value = state;
            document.getElementById("city-edit").value = city;
            document.getElementById("zipcode-edit").value = zipcode;
            document.getElementById("email-edit").value = email;
            document.getElementById("phone-edit").value = phone.replaceAll("-", "");
        }else{
            document.getElementById("edit-basic-btn").innerText = "Edit";
            document.getElementById("save-basic-btn").classList.toggle("d-none");
        }
        formCheck("basic-form", "save-basic-btn");
    }

    function basicSubmit(){
        let name, countryX, stateX, cityX, zipX, emailX, phoneX, payload, oldPayload;
        if (editRights){
            handleSubmitActions("save-basic-btn", "load-basic-btn");
            name = document.getElementById("name-edit").value;
            countryX = document.getElementById("country-edit").value;
            stateX = document.getElementById("state-edit").value;
            cityX = document.getElementById("city-edit").value;
            zipX = document.getElementById("zip-edit").value;
            emailX = document.getElementById("email-edit").value;
            phoneX = document.getElementById("phone-edit").value;
            payload = [{corporate_id: parseInt(corpId), corporate_name: name, country: countryX, state: stateX,
                        city: cityX, zip: zipX, email: emailX, phone: phoneX}];
            oldPayload = [{corporate_id: parseInt(corpId), corporate_name: corpName, country: country, state: state,
                           city: city, zip: zipcode, email: email, phone: phone}]
            ApiRequester({reqEndpoint: "mysave", Tbl: "core_corporate", PKey: "corporate_id", ClientId: dData.sessionId,
                                Condition: "primary", urlType: "POST", User: dData.user, Payload: payload,
                                OldPayload: oldPayload, CampusId: campusId}).then(data => {
                if (data && data[0]){
                    setCount(0);
                    formSuccess("Basic information saved.", "save-basic-btn", "load-basic-btn",
                        "edit-basic-btn");
                }
                else{
                    formFail(`Error during save, error code: ${data && data?.length > 1 ? data[1] : ""}`,
                        "save-basic-btn", "load-basic-btn");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function formAddSubmit(){
        // User add form submit function
        let campusName, payload, guid, address, city, state, zipcode, country, timezone, planexpire;
        if (addRights){
            handleSubmitActions("addSubmit", "loadAddSubmit");
            campusName = document.getElementById("name").value;
            guid = document.getElementById("campusGuid")?.value;
            address = document.getElementById("address").value;
            city = document.getElementById("city").value;
            state = document.getElementById("state").value;
            zipcode = document.getElementById("zip").value;
            country = document.getElementById("country").value;
            timezone = document.getElementById("timezone").value;
            planexpire = document.getElementById("planexpires").value;
            payload = [{campus_name: campusName, corporate_id: parseInt(corpId), plan_expire: `${planexpire} 00:00:00`,
                        campus_guid: guid, address: address, city: city, state: state, zipcode: zipcode,
                        timezone_id: parseInt(timezone), country: country}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "campus_add", Payload: payload,
                                User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0] === true){
                    formSuccess(`${campusName} has been added.`, "addSubmit", "loadAddSubmit",
                        "cancelAddBtn", "addForm");
                    setCount(0);
                }
                else{
                    formFail(`Error during add, error code: ${data[1]}`, "addSubmit", "loadAddSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function addModal(){
        document.getElementById("campusMigrate").checked = false;
        document.getElementById("campusGuid").required = false;
        document.getElementById("name").required = true;
        document.getElementById("address").required = true;
        document.getElementById("city").required = true;
        document.getElementById("state").required = true;
        document.getElementById("country").required = true;
        document.getElementById("zip").required = true;
        document.getElementById("timezone").required = true;
        document.getElementById("planexpires").required = true;
        document.getElementById("campusGuid").value = "";
        document.getElementById("name").value = "";
        document.getElementById("address").value = "";
        document.getElementById("city").value = "";
        document.getElementById("state").value = "";
        document.getElementById("country").value = "";
        document.getElementById("zip").value = "";
        document.getElementById("timezone").value = "";
        document.getElementById("planexpires").value = "";
        document.getElementById("campusGuidDiv").classList.add("d-none");
        document.getElementById("name-div").classList.remove("d-none");
        document.getElementById("address-div").classList.remove("d-none");
        document.getElementById("city-div").classList.remove("d-none");
        document.getElementById("state-div").classList.remove("d-none");
        document.getElementById("country-div").classList.remove("d-none");
        document.getElementById("zip-div").classList.remove("d-none");
        document.getElementById("timezone-div").classList.remove("d-none");
        document.getElementById("planexpires-div").classList.remove("d-none");
        showModal("addmodal");
        formCheck("addForm", "addSubmit")
    }

    function toggleMigrateForm(migratingOn){
        document.getElementById("campusGuid").required = migratingOn;
        document.getElementById("name").required = !migratingOn;
        document.getElementById("address").required = !migratingOn;
        document.getElementById("city").required = !migratingOn;
        document.getElementById("state").required = !migratingOn;
        document.getElementById("country").required = !migratingOn;
        document.getElementById("zip").required = !migratingOn;
        document.getElementById("planexpires").required = !migratingOn;
        if (migratingOn) {
            document.getElementById("campusGuidDiv").classList.remove("d-none");
            document.getElementById("name-div").classList.add("d-none");
            document.getElementById("address-div").classList.add("d-none");
            document.getElementById("city-div").classList.add("d-none");
            document.getElementById("state-div").classList.add("d-none");
            document.getElementById("country-div").classList.add("d-none");
            document.getElementById("zip-div").classList.add("d-none");
            document.getElementById("planexpires-div").classList.add("d-none");
       } else {
            document.getElementById("campusGuidDiv").classList.add("d-none");
            document.getElementById("name-div").classList.remove("d-none");
            document.getElementById("address-div").classList.remove("d-none");
            document.getElementById("city-div").classList.remove("d-none");
            document.getElementById("state-div").classList.remove("d-none");
            document.getElementById("country-div").classList.remove("d-none");
            document.getElementById("zip-div").classList.remove("d-none");
            document.getElementById("planexpires-div").classList.remove("d-none");
       }
        formCheck("addForm", "addSubmit");
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"} style={{height: "45px"}}>
            {dData.corp.toString() === corpId.toString()
                ?   dData.myCorp.toString() === corpId.toString()
                    ? <></>
                    :<Button text={"Impersonating"} type={"button"} class={"btn btn-success ms-2 mt-1"}
                             icon={true} iconClass={"fe fe-eye me-2"} disabled={true} />
                :   <Button text={"Impersonate"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                            icon={true} iconClass={"fe fe-eye me-2"}
                            onClick={() => {
                                document.getElementById("userrole").value = dData.my_role_weight;
                                showModal("impersonatemodal");
                            }} />
            }
            {addRights &&
                <Button text={"New Campus"} type={"button"} class={"btn btn-primary ms-2 mt-1"} icon={true}
                        iconClass={"fe fe-plus me-2"} onClick={() => {addModal()}} />
            }
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Corporate Profile"} Title={corpName} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <div className={"card"} style={{minWidth: "550px"}}>
                        <div className={"card-header d-flex justify-content-between"}>
                            <div>
                                <h3 className={"card-header-title d-inline-block"}>Basic Information</h3>
                            </div>
                            <div>
                                <i className={"fe fe-minus as-btn collapse-toggle p-2"} id={"basic-card-btn"}
                                   onClick={() => toggleCollapse("basic-card-btn", "basic-card")}></i>
                            </div>
                        </div>
                        <div className={"card-body collapse collapse-content show"} id={"basic-card"}>
                            <form className={"d-inline align-top form-control-plaintext was-validated"}
                                  id={"basic-form"}>
                                <div className={"mx-5 mt-2 align-top d-inline-block"} id={"basic-col1-div"}>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Id:</p>
                                        <p className={"ms-4"} id={"id-static"}>{corpId}</p>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Name:</p>
                                        <p className={"ms-4"} id={"name-static"}>{corpName}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm mb-1"}
                                               required={true}
                                               style={{height: "30px", width: "225px"}} id={"name-edit"}
                                               defaultValue={corpName} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p className={"mb-1"} style={{minWidth: "125px"}}>Country:</p>
                                        <p className={"ms-4 mb-1"} id={"country-static"}>{cntry}</p>
                                        <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                onChange={(e) => {
                                                    formCheck("basic-form", "save-basic-btn");
                                                    setCountryOptionsFunction(e?.target?.value?.toString())
                                                }}
                                                style={{height: "30px", width: "225px"}} id={"country-edit"}
                                                defaultValue={cntry}>
                                            {countryOptions}
                                        </select>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p className={"mb-1"} style={{minWidth: "125px"}}>State:</p>
                                        <p className={"ms-4 mb-1"} id={"state-static"}>{state}</p>
                                        <select className={"ms-4 d-none form-select form-select-sm mb-1"}
                                                onChange={() => formCheck("basic-form", "save-basic-btn")}
                                                style={{height: "30px", width: "225px"}} id={"state-edit"}
                                                defaultValue={state}>
                                            {stateOptions}
                                        </select>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p className={"mb-1"} style={{minWidth: "125px"}}>City:</p>
                                        <p className={"ms-4 mb-1"} id={"city-static"}>{city}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm mb-1"}
                                               style={{height: "30px", width: "225px"}} id={"city-edit"}
                                               defaultValue={city} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Zip Code:</p>
                                        <p className={"ms-4"} id={"zipcode-static"}>{zipcode}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "225px"}} id={"zipcode-edit"}
                                               defaultValue={zipcode} pattern={zipReg}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Email:</p>
                                        <p className={"ms-4"} id={"email-static"}>{email}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "225px"}} id={"email-edit"}
                                               defaultValue={email}
                                               pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Phone #:</p>
                                        <p className={"ms-4"} id={"phone-static"}>{formatPhone(phone)}</p>
                                        <input className={"ms-4 d-none form-control form-control-sm"}
                                               style={{height: "30px", width: "225px"}} id={"phone-edit"}
                                               defaultValue={phone} pattern={"[0-9]{3}-?[0-9]{3}-?[0-9]{4}"}/>
                                    </div>
                                    <div className={"d-flex"}>
                                        <p style={{minWidth: "125px"}}>Lat/Long:</p>
                                        <p className={"ms-4"}
                                           id={"latlong-static"}>{latitude ? latitude : "?"} / {longitude ? longitude : "?"}</p>
                                    </div>
                                    <div className={"mt-2"}>
                                        <ul className={"ps-0 d-inline-block align-top"} style={{listStyle: "none"}}>
                                            <li><p style={{minWidth: "125px"}}
                                                   className={"me-4 mb-0 fw-bold"}>Campuses:</p></li>
                                        </ul>
                                        <ul className={"ps-0 d-inline-block"}
                                            style={{listStyle: "none", columnCount: campusColumns}}>
                                            <li>{campuses}</li>
                                        </ul>
                                    </div>
                                </div>
                            </form>
                            {editRights &&
                                <>
                                    <Button text={"Edit"} class={"btn btn-primary float-end"} id={"edit-basic-btn"}
                                            disabled={!editRights} onClick={() => {
                                        if (editRights) {
                                            basicActionHandling()
                                        }
                                    }}/>
                                    <Button text={"Save"} class={"btn btn-primary float-end me-3 d-none"}
                                            id={"save-basic-btn"} disabled={!editRights} onClick={() => {
                                        if (editRights) {
                                            basicSubmit()
                                        }
                                    }}/>
                                    <Button type={"button"} class={"btn btn-primary float-end mx-3 d-none"}
                                            disabled={true} spinner={true} text={""} id={"load-basic-btn"}/>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Campus to {corpName}</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"align-top ms-3 mb-3"}>
                                    <input className={"form-check-input"} id={"campusMigrate"}
                                           style={{width: "1.25rem", height: "1.25rem"}} type={"checkbox"}
                                           onChange={(e) => toggleMigrateForm(e.target.checked)}/>
                                    <label className={"form-check-label ms-2 fs-3"} style={{color: "#1d1d1d"}}
                                           htmlFor={"campusMigrate"}>Migrating from 2.0?</label>
                                </div>
                                <div id={"campusGuidDiv"} className={"form-floating mb-3 d-none"}>
                                    <select className={"form-select"} id={"campusGuid"} defaultValue={undefined}
                                            required={true} onChange={() => formCheck("addForm", "addSubmit")}>
                                        <option disabled={true} value={""}></option>
                                        {mCampOptions}
                                    </select>
                                    <label htmlFor={"campusGuid"}>Migration Campus</label>
                                    <div className={"invalid-feedback"}>
                                        Select campus to migrate to 4.0
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"name-div"}>
                                    <input type={"text"} className={"form-control"} id={"name"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"name"}>Campus Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter the name of the campus.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"country-div"}>
                                    <select className={"form-select"} id={"country"} defaultValue={undefined}
                                            required={true}
                                            onChange={(e) => {
                                                setOldIndex(-1);
                                                setOldIndexText("");
                                                document.getElementById("state").value = "";
                                                setCountry(e.target.value);
                                            }}>
                                        <option value={""}></option>
                                        {getCountryOptions()}
                                    </select>
                                    <label htmlFor={"country"}>Country</label>
                                    <div className={"invalid-feedback"}>
                                        Select country
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"address-div"}>
                                    <input type={"text"} className={"form-control"} id={"address"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                    <label htmlFor={"address"}>Street Address</label>
                                    <div className={"invalid-feedback"}>
                                        Enter a valid street address.
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-start"}>
                                    <div className={"form-floating mb-3"} id={"city-div"} style={{width: "47%"}}>
                                        <input type={"text"} className={"form-control"} id={"city"}
                                               onKeyUp={() => formCheck("addForm", "addSubmit")}
                                               required={true} pattern={"^[0-9a-zA-Z_\\- ]+$"}/>
                                        <label htmlFor={"city"}>City</label>
                                        <div className={"invalid-feedback"}>
                                            Enter a valid city.
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3 mx-3"} id={"state-div"} style={{width: "22%"}}>
                                        <select className={"form-select"} id={"state"} value={undefined}
                                                required={true} onChange={(e) => {
                                            if (oldIndex !== -1) {
                                                e.target.options[oldIndex].innerText = oldIndexText;
                                            }
                                            setOldIndex(e.target.selectedIndex);
                                            setOldIndexText(e.target.options[e.target.selectedIndex].innerText);
                                            e.target.options[e.target.selectedIndex].innerText = e.target[e.target.selectedIndex].value;
                                            formCheck("addForm", "addSubmit");
                                        }}>
                                            <option value={""}></option>
                                            {getStateOptionsByCountry(country)}
                                        </select>
                                        <label htmlFor={"state"}>State</label>
                                        <div className={"invalid-feedback"}>
                                            Select the state/province
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"} id={"zip-div"} style={{width: "27%"}}>
                                        <input type={"text"} className={"form-control"} id={"zip"}
                                               onKeyUp={() => formCheck("addForm", "addSubmit")}
                                               required={true} pattern={getZipRegexByCountry(country)}/>
                                        <label htmlFor={"zip"}>Zipcode</label>
                                        <div className={"invalid-feedback"}>
                                            Enter a valid zip code.
                                        </div>
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"timezone-div"}>
                                    <select className={"form-select"} id={"timezone"} value={undefined} required={true}
                                            onChange={() => formCheck("addForm", "addSubmit")}>
                                        <option value={""}></option>
                                        {tzOptions}
                                    </select>
                                    <label htmlFor={"timezone"}>Timezone</label>
                                    <div className={"invalid-feedback"}>
                                        Select timezone for campus.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"} id={"planexpires-div"}>
                                    <input type={"date"} className={"form-control"} id={"planexpires"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true}/>
                                    <label htmlFor={"planexpires"}>Service Plan Expiry Date</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter the date the plan will expire.
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                {addRights &&
                                    <>
                                        <Button type={"button"} class={"btn btn-primary d-none"} disabled={true}
                                                spinner={true}
                                                text={"Loading"} id={"loadAddSubmit"}/>
                                        <button type={"button"} className={"btn btn-primary"}
                                                onClick={() => formAddSubmit()}
                                                id={"addSubmit"}>Submit
                                        </button>
                                    </>
                                }
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"impersonatemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Role to impersonate as?</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"}
                                    aria-label={"Close"}/>
                        </div>
                        <form id={"impersonateForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating"}>
                                    <select className={"form-select"} required={true} id={"userrole"}
                                            onChange={(e) => {
                                                formCheck("impersonateForm", "impersonateSubmit");
                                            }}>
                                        <option value={dData.my_role_weight}>My Role</option>
                                        {roleOptions}
                                    </select>
                                    <label htmlFor={"userrole"}>Impersonating User Role</label>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelImpersonateBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true}
                                        spinner={true}
                                        text={"Loading"} id={"loadImpersonateSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} id={"impersonateSubmit"}
                                        onClick={() => {
                                            let rweight;
                                            handleSubmitActions("impersonateSubmit", "loadImpersonateSubmit");
                                            rweight = document.getElementById("userrole").value;
                                            setRoleInfo(parseInt(rweight), dData);
                                            reloadSessionCorp(corpId, 0, dData.cognito, dData);
                                        }}>Impersonate
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    );
};

export default CorporateProfile;