import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useState} from "react";
import Button from "../component-assets/Button";
import ApiRequester from "../ApiRequester";
import {ToastContainer} from "react-toastify";
import {
    getUTCTimestamp,
    getTTL,
    campusToGuid,
    handleSubmitActions,
    showModal,
    closeModal,
    formFail,
    formSuccess,
    formCheck,
    sortFunc
} from "../../libs";

const ReasonSystemSettings = (props) => {

    let dData, bodyList, optionList, reasonStart = [];
    bodyList = []
    optionList = []
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    for (let i3=0; i3 < props?.ReasonCodes.length; i3++){
        reasonStart.push(props?.ReasonCodes[i3]?.ReasonCodeId);
    }

    const GlobalReasons = props?.GlobalCodes;

    const [startList, setStartList] = useState(reasonStart);
    const [saveBtn, setSaveBtn] = useState(false);
    const [ReasonCodes, setReasonData] = useState(props?.ReasonCodes);
    const [changeLst, setChangeLst] = useState([]);

    GlobalReasons.sort((a, b) => sortFunc(a, b, "ReasonDescription", "asc"));
    for (let i2=0; i2 < GlobalReasons.length; i2++){
        optionList.push(
            <option key={"category"+i2} value={GlobalReasons[i2]?.ReasonCodeId}>{GlobalReasons[i2]?.ReasonDescription}</option>
        )
    }

    for (let i=0; i < ReasonCodes.length; i++){
        bodyList.push(
            <div key={"reason"+i} className={"ps-4 mb-4 w-50 d-inline-block"} style={{minWidth: "200px"}}>
                <i className={"fe fe-x-square text-danger fs-1"} style={{cursor: "pointer", verticalAlign: "bottom"}}
                    onClick={() => deleteModal(ReasonCodes[i]?.ReasonCodeId, i, ReasonCodes[i]?.Description)}/>
                <h4 className={"d-inline-block fs-3 ms-2"}>Reason {i+1}</h4>
                <div className={"form-floating"}>
                    <input className={"form-control"} id={"reason"+i} value={ReasonCodes[i]?.Description} onChange={(e) => {
                        setSaveBtn(true);
                        setReasonData(prevVal => updateArrayObj(prevVal, i, "Description", e.target.value))
                        if (!changeLst.includes(i)){
                            setChangeLst(prevVal => {
                                return [...prevVal, i]
                            })
                        }
                    }}/>
                    <label className={"floatingInput"} htmlFor={"reason"+i}>Reason Name</label>
                </div>
                <div className={"form-floating"}>
                    <select className={"form-select"} id={"category"+i} value={ReasonCodes[i]?.GlobalReasonCode}
                            onChange={(e) => {
                        setSaveBtn(true);
                        setReasonData(prevVal => updateArrayObj(prevVal, i, "GlobalReasonCode", e.target.value))
                        if (!changeLst.includes(i)){
                            setChangeLst(prevVal => {
                                return [...prevVal, i]
                            })
                        }
                    }}>
                        {optionList}
                    </select>
                    <label htmlFor={"category"+i}>Reason Category</label>
                </div>
            </div>
        )
    }

    function updateArrayObj(oldArray, index, attributeChange, newVal){
        let newArray = [...oldArray];
        newArray[index][attributeChange] = newVal;
        return newArray
    }

    function performSave(){
        let payload = [], timestamp;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        for (let i=0; i < changeLst.length; i++){
            timestamp = getUTCTimestamp();
            payload.push({ReasonCodeId: ReasonCodes[parseInt(changeLst[i])].ReasonCodeId,
                        Description: ReasonCodes[parseInt(changeLst[i])].Description,
                        GlobalReasonCode: ReasonCodes[parseInt(changeLst[i])].GlobalReasonCode, CampusId: campusToGuid(dData, dData?.acnt),
                        ModifiedBy: dData.username, DateModified: timestamp})
        }
        ApiRequester({reqEndpoint: "save", Tbl: "CampusReasonCode", PKey: "CampusId", SKey: "ReasonCodeId",
                            Payload: payload, CampusId: campusToGuid(dData, dData?.acnt)}).then(data => {
            if(data[0]){
                setSaveBtn(false);
                formSuccess("Reasons updated", "editSubmit", "loadEditSubmit");
            }
            else{
                formFail("Error encountered, save failed", "editSubmit", "loadEditSubmit")
            }
        })
    }

    function performSaveAdd(){
        let nxtReasonCode, timestamp, lookup, payload;
        handleSubmitActions("editSubmit", "loadEditSubmit");
        lookup = true;
        nxtReasonCode = 1
        while (lookup){
            if (!startList.includes(nxtReasonCode.toString())){
                break
            }else{
                nxtReasonCode++
            }
        }
        timestamp = getUTCTimestamp();
        payload = {ReasonCodeId: nxtReasonCode.toString(),
                    Description: document.getElementById("reasonX").value, Deleted: false,
                    GlobalReasonCode: document.getElementById("categoryX").value, CampusId: campusToGuid(dData, dData?.acnt),
                    ModifiedBy: dData.username, DateModified: timestamp, DateCreated: timestamp}
        ApiRequester({reqEndpoint: "save", Tbl: "CampusReasonCode", PKey: "CampusId", SKey: "ReasonCodeId",
                            Payload: [payload], CampusId: campusToGuid(dData, dData?.acnt)}).then(data => {
            if(data[0]){
                setReasonData(prevVal => {
                    return [...prevVal, payload];
                })
                setStartList((prevVal) => {
                    return [...prevVal, nxtReasonCode.toString()];
                })
                formSuccess("Reasons added", "editSubmit", "loadEditSubmit", "editClose");
            }
            else{
                formFail("Error encountered, reason failed to add", "editSubmit", "loadEditSubmit");
            }
        })
    }
    function deletePerform(delBtn, loadBtn, closeBtn){
        let idVal, payload, timestamp, index, msg;
        handleSubmitActions(delBtn, loadBtn)
        idVal = document.getElementById("dContent").value;
        index = document.getElementById("dIndex").value;
        timestamp = getUTCTimestamp();
        payload = {Deleted: true, ReasonCodeId: idVal, CampusId: campusToGuid(dData, dData?.acnt), DateModified: timestamp,
                    ModifiedBy: dData?.username, TTL: getTTL(7)}
        ApiRequester({reqEndpoint: "save", Tbl: "CampusReasonCode", PKey: "CampusId", SKey: "ReasonCodeId",
                            Payload: [payload], CampusId: campusToGuid(dData, dData?.acnt)}).then(data => {
            if (data[0]){
                setReasonData(prevVal => {
                    return [...prevVal.slice(0, parseInt(index)), ...prevVal.slice(parseInt(index) + 1, prevVal.length)]
                })
                props.setGlobalReasonData(prevVal => {
                    return [...prevVal.slice(0, parseInt(index)), ...prevVal.slice(parseInt(index) + 1, prevVal.length)]
                })
                formSuccess("Reason deleted", delBtn, loadBtn, closeBtn);
            }
            else{
                msg = "Reason failed to delete. Contact customer support if failure persists"
                formFail(msg, delBtn, loadBtn);
            }
        });
    }

    function deleteModal(recId, index, display){
        let modalContent;
        modalContent = document.getElementById("deleteModalContent");
        modalContent.innerText = `Are you sure you wish to delete reason ${display}?`;
        document.getElementById("dContent").value = recId;
        document.getElementById("dIndex").value = index;
        showModal("deletemodal");
    }

    return (
        <>
            <div className={"card"}>
                <div className={"card-header"}>
                    <h4 className={"card-header-title"}>Reason Codes</h4>
                    <Button icon={true} iconClass={"fe fe-edit me-2"} text={"Add Reason"} class={"btn btn-primary ms-4"} onClick={() => {
                        document.getElementById("reasonX").value = "";
                        document.getElementById("categoryX").value = "";
                        formCheck("addForm", "editSubmit")
                        showModal("editModal");
                    }} />
                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                    {saveBtn && <Button id={"editSubmit"} style={{width: "75px"}} text={"Save"} class={"btn btn-primary ms-3"} onClick={() => performSave()} />}
                </div>
                <div className={"card-body"}>
                    <div className={"list-group list-group-flush my-n3"} style={{display: "block"}}>
                        {bodyList}
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editModal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Custom Reason</h2>
                            <button type={"button"} className={"btn-close"} id={"editClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <div className={"modal-body"}>
                            <form id={"addForm"}>
                                <div className={"list-group list-group-flush my-n3 was-validated"}>
                                    <h4>Reason {ReasonCodes.length + 1}</h4>
                                    <div className={"form-floating mb-3"}>
                                        <input className={"form-control"} id={"reasonX"} required={true}
                                               onKeyUp={() => formCheck("addForm", "editSubmit")}/>
                                        <label className={"floatingInput"} htmlFor={"reasonX"}>Reason Name</label>
                                    </div>
                                    <div className={"form-floating"}>
                                        <select className={"form-select"} id={"categoryX"} required={true}
                                                onChange={() => {
                                                    formCheck("addForm", "editSubmit");
                                                }}>
                                            {optionList}
                                        </select>
                                        <label htmlFor={"categoryX" + (ReasonCodes.length + 1)}>Reason Category</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"}
                                    onClick={() => closeModal("editClose")}>Cancel
                            </button>
                            <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true}
                                    text={"Loading"} id={"loadEditSubmit"}/>
                            <Button type={"button"} class={"btn btn-primary ms-3"} id={"editSubmit"}
                                    onClick={() => performSaveAdd()}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"deletemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                    <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Delete Reason</h2>
                            <button type={"button"} className={"btn-close"} id={"confirmClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <h2 className={"m-3"} style={{textAlign: "center"}} id={"deleteModalContent"}> </h2>
                        <input id={"dContent"} type={"hidden"} />
                        <input id={"dIndex"} type={"hidden"} />
                        <div className={"modal-footer"}>
                            <button type={"button"} className={"btn btn-secondary"} style={{width: "75px"}} id={"cancelDeleteBtn"} onClick={() => closeModal("confirmClose")}>No</button>
                            <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Deleting"} id={"loadDeleteSubmit"}/>
                            <button type={"button"} className={"btn btn-danger"} style={{width: "75px"}} id={"deleteSubmit"}
                                    onClick={() => deletePerform("deleteSubmit", "loadDeleteSubmit", "confirmClose")}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default ReasonSystemSettings;