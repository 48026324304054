import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {
    getCurrentEpochTimeInSeconds,
    iAmHigherAuthority,
    reloadSessionCampus,
    reloadSessionCorp,
    setReloadTimer
} from "../../libs";
import {useEffect, useState} from "react";

const SessionReload = (props) => {
    let bData, dData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [count, setCount] = useState(dData.reloadTime - parseInt(getCurrentEpochTimeInSeconds()))

    useEffect(() => {
        let interval, newEpoch;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            if (iAmHigherAuthority(80, dData.role_weight)){
                reloadSessionCorp(dData.corp, -1, dData.cognito, dData, dData.acnt);
            }else{
                reloadSessionCampus(dData.acnt, -1, dData.cognito);
            }
            newEpoch = parseInt(getCurrentEpochTimeInSeconds()) + 300;
            setCount(300);
            setReloadTimer(dData, newEpoch);
        }
        return () => clearInterval(interval);
    }, [count])

    return (
        <></>
    );
};

export default SessionReload;