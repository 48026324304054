import moment from "moment";
import {Modal} from "bootstrap";
import {toast} from "react-toastify";
import {v4 as uuidv4} from "uuid";
import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {DateTime} from "luxon";
import ApiRequester from "./components/ApiRequester";


// Math & String Formatting

export function pt2(num){
    return num.toString().padStart(2, "0");
}

export function padNumber(item, padNum){
    return item.toString().padStart(padNum, "0");
}

function divMod(incoming, dividedBy) {
    if (incoming <= 0) {
        return {total: 0, remainder: 0};
    }else{
        return {total: Math.floor(incoming / dividedBy), remainder: incoming % dividedBy};
    }
}

export function percentageDifference(curVal, newVal) {
  // Calculate the difference between the two numbers.
  let difference = curVal - newVal;
  // Calculate the percentage of the difference.
  let percentage = Math.round(difference / curVal * 100);
  // Return the percentage as string formatted.
  return `${percentage < 0 ? "Down" : "Up"} ${Math.abs(percentage)}%` ;
}

export function formatPhone(inStr){
    if (inStr.length > 9){
        return `${inStr.slice(0,3)}-${inStr.slice(3,6)}-${inStr.slice(6,10)}`;
    }
    return inStr;
}

// Date Time Based Utility Functions

export function getTTL(days){
    return Math.round(new Date().getTime() / 1000) + (86400 * days);
}

export function getTimeZone(){
    let dt = new Date();
    if (dt.getTimezoneOffset() >= 0){
        return dt.getTimezoneOffset()/60;
    }
    else{
        return dt.getTimezoneOffset()/-60;
    }
}

export function timezoneAsStringFormat(tzoffset){
    let sign = Math.sign(tzoffset);
    let hours = Math.abs(tzoffset);
    let hoursInt = Math.floor(tzoffset);
    let minutesDecimal = hours - hoursInt;
    let minutes = Math.round(minutesDecimal * 60);
    let formattedMinutes = minutes.toString().padStart(2, "0");
    return `${sign === -1 ? '-' : ''}${hoursInt.toString().padStart(2, '0')}:${formattedMinutes}`;
}

export function getTimestamp(){
    // Creates local datetime formatted %YYYY-%MM-%dd %hh:%mm:%ss
    let dt = new Date(Date.now());
    return `${dt.getFullYear()}-${pt2(dt.getMonth()+1)}-${pt2(dt.getDate())} ${pt2(dt.getHours())}:${pt2(dt.getMinutes())}:${pt2(dt.getSeconds())}`;
}

export function getUTCTimestamp(){
    // Creates UTC datetime formatted %YYYY-%MM-%dd %hh:%mm:%ss
    let ret;
    let dt = new Date(Date.now());
    ret = `${dt.getUTCFullYear()}-${pt2(dt.getUTCMonth()+1)}-${pt2(dt.getUTCDate())} ${pt2(dt.getUTCHours())}:${pt2(dt.getUTCMinutes())}:${pt2(dt.getUTCSeconds())}`;
    return ret;
}

export function formatTimestamp(inDateStr){
    // Creates datetime formated from date string formatted in local format %MM/%dd/%YYYY %hh:%mm:%ss
    let dt = new Date(inDateStr);
    return `${pt2(dt.getMonth()+1)}/${pt2(dt.getDate())}/${dt.getUTCFullYear()} ${pt2(dt.getUTCHours())}:${pt2(dt.getUTCMinutes())}:${pt2(dt.getUTCSeconds())}`;
}

export function formatTimestampIso(inDateStr){
    // Creates datetime formated from date string formatted in iso format %YYYY-%MM-%dd %hh:%mm:%ss
    let dt = new Date(inDateStr);
    return `${dt.getUTCFullYear()}-${pt2(dt.getUTCMonth()+1)}-${pt2(dt.getUTCDate())} ${pt2(dt.getUTCHours())}:${pt2(dt.getUTCMinutes())}:${pt2(dt.getUTCSeconds())}`;
}

export function getQuarterRange(offset=0){
    let ret = ["", ""];
    let dt = new Date(Date.now());
    let quarter = Math.floor(dt.getMonth() / 3 + 1 + offset);
    if (quarter === 1){
        ret[0] = new Date(dt.getFullYear(), 0, 1);
        ret[1] = new Date(dt.getFullYear(), 3, 0);
    }else if (quarter === 2){
        ret[0] = new Date(dt.getFullYear(), 3, 1);
        ret[1] = new Date(dt.getFullYear(), 5, 30);
    }else if (quarter === 3){
        ret[0] = new Date(dt.getFullYear(), 6, 1);
        ret[1] = new Date(dt.getFullYear(), 8, 30);
    }else if (quarter === 4){
        ret[0] = new Date(dt.getFullYear(), 9, 1);
        ret[1] = new Date(dt.getFullYear(), 11, 31);
    }else if (quarter === 0){
        ret[0] = new Date(dt.getFullYear()-1, 9, 1);
        ret[1] = new Date(dt.getFullYear()-1, 11, 31);
    }
    return ret;
}

export function getWeekRange(offset=0){
    let dt = new Date(Date.now());
    let day = dt.getDay();
    let diff = dt.getDate() - day + (day === 0 ? -6 : 1)+(7*offset);
    let first = new Date(dt.setDate(diff));
    let diff2 = first.getDate() + 6;
    let last = new Date(dt.setDate(diff2));
    return [first, last];
}

export function getMonthRange(offset=0){
    let dt = new Date(Date.now());
    let last = new Date(dt.getFullYear(), dt.getMonth()+1+offset, 0);
    return [new Date(dt.getFullYear(), dt.getMonth()+offset, 1), last];
}

export function getYear(offset=0){
    let first = new Date(new Date(Date.now()).getFullYear()+offset, 0, 1);
    let last = new Date(first.getFullYear(), 11, 31);
    return [first, last];
}

export function getToday(offset=0){
    return new Date(new Date().setDate(new Date().getDate() + offset));
}

export function getY2Date(){
    return [new Date(new Date().getFullYear(), 0, 1), new Date(Date.now())];
}

export function formatDateIso(dateObj){
    // Incoming date object, outputs YYYY-MM-DD formatted string
    return `${dateObj.getFullYear().toString()}-${pt2(dateObj.getMonth()+1)}-${pt2(dateObj.getDate())}`;
}

export function formatDate(dateObj){
    // Incoming date object, outputs MM/DD/YYYY formatted string
    return `${pt2(dateObj.getMonth() + 1)}/${pt2(dateObj.getDate())}/${dateObj.getFullYear()}`;
}

export function getCurrentYear(){
    let dt = new Date(Date.now());
    return dt.getFullYear();
}

export function durationToNow(eventStart){
    let seconds, h, m, s, modRet, duration, eventdatetime, nowTime;
    h = 0;
    m = 0;
    s = 0;
    eventdatetime = moment.utc(eventStart).unix();
    nowTime = moment().unix();
    seconds = (nowTime - eventdatetime);
    modRet = divMod(seconds, 60);
    m = modRet.total;
    s = modRet.remainder;
    modRet = divMod(m, 60);
    h = modRet.total;
    m = modRet.remainder;
    if (h > 0){
        duration = h+":"+m.toString().padStart(2, "0")+" (hours)";
    }
    else if(m > 0){
        duration = m+":"+s.toString().padStart(2, "0")+" (min)";
    }
    else if (s > 0){
        duration = s+" Seconds";
    }
    else{
        duration = "";
    }
    return duration;
}

export function luxonParseSQLDate(inDate){
    let ret = "";
    if (inDate){
        ret = DateTime.fromSQL(inDate);
    }
    return ret;
}

export function luxonCompareDateTimes(compareTime, compare="lt"){
    if (compare === "lt"){
        return compareTime < DateTime.now();
    }else{
        return compareTime > DateTime.now();
    }
}

export function unpackLabels(inObj) {
    return DateTime.utc().toMillis() / 1000;
}

export function getCurrentEpochTimeInSeconds() {
  return DateTime.utc().toMillis() / 1000;
}

export function getLocalTimeFromEpochMillis(epoch, offset=0){
    return DateTime.fromMillis(epoch ? epoch/1000000 : 0, { zoneOffset: offset ? offset : 0 }).toFormat("D h:mm a");
}

export function getDurationFromFutureEpoch(futureEpoch, offset=0) {
    return parseInt((futureEpoch+offset) - (DateTime.utc().toMillis() / 1000));
}

export function secToTime(insec, offset=0){
    return DateTime.fromSeconds(insec).setZone('utc').toFormat("h:mm a");
}

export function secToTimeUTC(insec, offset=0){
    return DateTime.fromSeconds(insec).setZone('utc').plus(offset*3600000).toFormat("h:mm a");
}

export function convertIsoToLocal(inIsoDatetime){
  let ret = "";
  if (inIsoDatetime){
      try{
          ret = DateTime.fromSQL(inIsoDatetime).toFormat("MM/dd/yyyy h:mma");
      }catch (e){
          ret = "";
      }
  }
  return ret;
}

export function convertIsoToLocalTime(inIsoDatetime){
  let ret = "";
  if (inIsoDatetime){
      try{
          ret = DateTime.fromSQL(inIsoDatetime).toFormat("h:mma");
      }catch (e){
          ret = "";
      }
  }
  return ret;
}

export function convertIsoToLocalDate(inIsoDatetime){
  let ret = "";
  if (inIsoDatetime){
      try{
          ret = DateTime.fromSQL(inIsoDatetime).toFormat("MM/dd/yyyy");
      }catch (e){
          ret = "";
      }
  }
  return ret;
}


export function convertIsoUTCToLocal(inIsoDatetime, offset){
    let ret = "", tmpDt;
    if (inIsoDatetime){
        try{
            tmpDt = DateTime.fromSQL(inIsoDatetime);
            if (offset < 0){
                ret = tmpDt.plus({hours: offset}).toFormat("MM/dd/yyyy h:mma");
            }else if (offset > 0){
                ret = tmpDt.minus({hours: offset}).toFormat("MM/dd/yyyy h:mma");
            }
        }catch (e){
            ret = "";
        }
    }
    return ret;
}

export function getLocalTimeWithOffset(offset){
    let ret, utcTime;
    try{
        if (offset !== undefined){
            utcTime = DateTime.utc().plus({hours: offset});
            ret = utcTime.toFormat("yyyy-MM-dd HH:mm:ss");
        }else{
            ret = DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss");
        }
    }catch (e) {
        ret = "";
    }
    return ret;
}

export function getLuxonUTCTime(){
    return DateTime.utc().toFormat("yyyy-MM-dd HH:mm:ss");
}

// Misc Functions

export function getAccessSettings(role, migrated=false){
    let ret
    if (["palcare-admin", "palcare", "corporate", "corporate-admin", 1, 2, 3, 4].includes(role)){
        ret = {Dashboards: {Main: {Read: true, Write: true},
                    Corporate: {Read: true, Write: true}},
              Alerts: {All: {Read: true, Write: true},
                         Emergency: {Read: true, Write: true},
                         Maintenance: {Read: true, Write: true},
                         System: {Read: true, Write: true}},
              Reports: {Alerts: {Read: true, Write: true},
                  Residents: {Read: true, Write: true},
                  Caregiver: {Read: true, Write: true},
                  Inspection: {Read: true, Write: true}},
              Users: {UserManagement: {Read: true, Write: true},
                  Groups: {Read: true, Write: true}},
              Settings: {Mobile: {Read: true, Write: true}},
              Profile: {Read: true, Write: true}}
        if (migrated){
            ret["Community"] = {Residents: {Read: true, Write: true},
                                Buildings: {Read: true, Write: true},
                                Locations: {Read: true, Write: true},
                                Devices: {Read: true, Write: true}}
        }else{
            ret["Community"] = {Residents: {Read: false, Write: false},
                                Buildings: {Read: false, Write: false},
                                Locations: {Read: false, Write: false},
                                Devices: {Read: false, Write: false}}
        }
    }
    else if (["super-admin", "admin", 5, 7].includes(role)){
        ret = {Dashboards: {Main: {Read: true, Write: true},
                            Corporate: {Read: false, Write: false}},
              Alerts: {All: {Read: true, Write: true},
                         Emergency: {Read: true, Write: true},
                         Maintenance: {Read: true, Write: true},
                         System: {Read: true, Write: true}},
              Reports: {Alerts: {Read: true, Write: true},
                        Residents: {Read: true, Write: true},
                        Caregiver: {Read: true, Write: true},
                        Inspection: {Read: true, Write: true}},
              Users: {UserManagement: {Read: true, Write: true},
                      Groups: {Read: true, Write: true}},
              Settings: {Mobile: {Read: true, Write: true}},
              Profile: {Read: true, Write: true}}
        if (migrated){
            ret["Community"] = {Residents: {Read: true, Write: true},
                                Buildings: {Read: true, Write: true},
                                Locations: {Read: true, Write: true},
                                Devices: {Read: true, Write: true}}
        }else{
            ret["Community"] = {Residents: {Read: false, Write: false},
                                Buildings: {Read: false, Write: false},
                                Locations: {Read: false, Write: false},
                                Devices: {Read: false, Write: false}}
        }
    }
    else{
        ret = {Dashboards: {Main: {Read: true, Write: true},
                            Corporate: {Read: false, Write: false}},
            Alerts: {All: {Read: true, Write: true},
                    Emergency: {Read: true, Write: true},
                    Maintenance: {Read: true, Write: true},
                    System: {Read: true, Write: true}},
            Reports: {Alerts: {Read: false, Write: false},
                    Residents: {Read: false, Write: false},
                    Caregiver: {Read: false, Write: false},
                    Inspection: {Read: false, Write: false}},
            Users: {UserManagement: {Read: false, Write: false},
                    Groups: {Read: false, Write: false}},
            Community: {Residents: {Read: false, Write: false},
                        Buildings: {Read: false, Write: false},
                        Locations: {Read: false, Write: false},
                        Devices: {Read: false, Write: false}},
            Settings: {Mobile: {Read: false, Write: false}},
            Profile: {Read: true, Write: true}}
    }
    return ret;
}

export function serverid_to_mac(sId){
    return `${sId.slice(0,2)}:${sId.slice(2,4)}:${sId.slice(4,6)}:${sId.slice(6,8)}:${sId.slice(8,10)}:${sId.slice(10,12)}`;
}

export function mac_to_serverid(mac){
    return mac.replaceAll(":", "").replaceAll("-", "");
}

export function iAmHigherAuthority(comparedRoleWeight, myRoleWeight){
    if ([null, undefined].includes(myRoleWeight)){
        return false;
    }
    return parseInt(myRoleWeight) >= 1000 || parseInt(myRoleWeight) > parseInt(comparedRoleWeight);
}

export function roleWeightById(myRoleId, roleData){
    for (let i = 0; i < roleData?.length; i++){
        if (roleData[i]?.user_role_id === myRoleId){
            return roleData[i]?.user_role_weight;
        }
    }
    return 10;
}

export function roleWeightByName(myRoleName, roleData){
    for (let i = 0; i < roleData?.length; i++){
        if (roleData[i]?.user_role_name === myRoleName){
            return roleData[i]?.user_role_weight;
        }
    }
    return 10;
}

export function compareRole(compared, role){
    if ([undefined, null, ""].includes(role)){
        return false;
    }else{
        if (role === "palcare-admin"){
            return true;
        }
        else if (role === "palcare" && ["corporate-admin", "corporate", "super-admin", "admin", "caregiver", ""].includes(compared)){
            return true;
        }
        else if(role === "corporate-admin" && ["corporate", "super-admin", "admin", "caregiver", ""].includes(compared)){
            return true;
        }
        else if(role === "corporate" && ["super-admin", "admin", "caregiver", ""].includes(compared)){
            return true;
        }
        else if (role === "super-admin" && ["admin", "caregiver", ""].includes(compared)){
            return true;
        }
        else if(role === "admin" && ["caregiver", ""].includes(compared)){
            return true;
        }
        else if (role === "caregiver" && [""].includes(compared)){
            return true;
        }else{
            return false;
        }
    }
}

export function handleSuccess(msg, buttonId, loadBtnId, cancelBtnId){
    let add, load;
    document.getElementById(cancelBtnId).click();
    toast.success(msg);
    add = document.getElementById(buttonId);
    load = document.getElementById(loadBtnId);
    load.classList.add("d-none");
    add.classList.remove("d-none");
    add.classList.add("d-inline-block");
    load.classList.remove("d-inline-block");
}

export function formSuccess(msg, buttonId, loadBtnId, cancelBtnId="", formId=""){
    handleSubmitActions(loadBtnId, buttonId);
    if (cancelBtnId !== ""){
        document.getElementById(cancelBtnId).click();
    }
    if (formId !== ""){
        formClear(formId, buttonId);
    }
    toast.success(msg);
}

export function formClear(formId, buttonId){
    let formElement;
    formElement = document.getElementById(formId);
    if (formElement){
        formElement?.reset();
    }
    formCheck(formId, buttonId);
}

export function handleSubmitActions(submitBtn, loadBtn){
    let load, add;
    add = document.getElementById(submitBtn);
    load = document.getElementById(loadBtn);
    add?.classList?.add("d-none");
    load?.classList?.add("d-inline-block");
    load?.classList?.remove("d-none");
}

export function formFail(msg, buttonId, loadBtnId, cancelBtnId=""){
    handleSubmitActions(loadBtnId, buttonId);
    if (cancelBtnId !== ""){
        document.getElementById(cancelBtnId)?.click();
    }
    toast.error(msg);
}

export function formCheck(formId, buttonId){
    // Checks form for validation, toggles save button based on validation being met.
    let form, form2, form3, formArray, formArray2, formArray3, newArray, btn;
    form = document.getElementById(formId).querySelectorAll("input");
    form2 = document.getElementById(formId).querySelectorAll("textarea");
    form3 = document.getElementById(formId).querySelectorAll("select");
    btn = document.getElementById(buttonId);
    formArray = Array.from(form);
    formArray2 = Array.from(form2);
    formArray3 = Array.from(form3);
    newArray = formArray.concat(formArray2).concat(formArray3);
    if (newArray.every((formItem) => {return formItem.checkValidity()})){
        if (btn){
            btn.disabled = false;
            btn.classList.remove("btn-danger");
            btn.classList.add("btn-primary");
        }
    }
    else{
        if (btn){
            btn.disabled = true;
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-danger");
        }
    }
}

export function formCheckWithElement(formId, buttonId, e=null, attributeData=[]){
    // Checks form for validation, toggles save button based on validation being met.
    let form, formArray, btn, inputArray, selectArray, formSelect;
    form = document.getElementById(formId).querySelectorAll("input");
    formSelect = document.getElementById(formId).querySelectorAll("select");
    btn = document.getElementById(buttonId);
    inputArray = Array.from(form);
    selectArray = Array.from(formSelect);
    formArray = [...inputArray, ...selectArray];
    if (formArray.every((formItem) => elementCheck(formItem, e, attributeData))){
        btn.disabled = false;
        btn.classList.remove("btn-danger");
        btn.classList.add("btn-primary");
    }
    else{
        btn.disabled = true;
        btn.classList.remove("btn-primary");
        btn.classList.add("btn-danger");
    }
}

function elementCheck(formItem, e, attributeData=[]) {
    if (formItem.id === "serialNum") {
        let exists = false;
        let active = false;
        if (formItem.value.length > 0 && !isNaN(formItem.value)) {
            for (let i = 0; i < attributeData.length; i++) {
                if (formItem.value.toString() === attributeData[i]?.manufacture_uuid.toString()) {
                    exists = true;
                    if (attributeData[i]?.is_active) {
                        active = true;
                    }
                }
            }
            if (exists && active) {
                if (!['shift', 'control', 'tab'].includes(e?.key?.toString()?.toLowerCase())) {
                    toast.warn("Device already assigned to resident.");
                }
            }
        }
    } else if (formItem.id === "password2") {
        if (document.getElementById("password").value !== formItem.value) {
            return false;
        }
    }
    return formItem.checkValidity();
}

export function closeModal(cancelModalBtn){
    document.getElementById(cancelModalBtn).click();
}

export function actionAppearance(start, end, itemList, checkboxId="uCheck"){
    let status, nxtCheck;
    status = false;
    for (let i=start; i < end; i++){
        nxtCheck = document.getElementById(checkboxId+i.toString());
        if (nxtCheck?.checked){
            status = true;
            break;
        }
    }
    if (status) {
        for (let i2=0; i2 < itemList.length; i2++){
            document.getElementById(itemList[i2]).classList.remove("hide");
        }
    }else{
        for (let i2=0; i2 < itemList.length; i2++){
            document.getElementById(itemList[i2]).classList.add("hide");
        }
    }
}

export function toggleAllCheckboxes(byNames, checkbox){
    let toggle, checkBoxes;
    toggle = document.getElementById(checkbox);
    checkBoxes = document.getElementsByName(byNames);
    for (let i = 0; i < checkBoxes?.length; i++){
        checkBoxes[i].checked = toggle.checked;
    }
}

export function getZipRegexByCountry(country){
    let zipData = {
        US: "\\d{5}([ \\-]\\d{4})?",
        CA: "[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d",
        PR: "00[679]\\d{2}([ \\-]\\d{4})?"
    };
    let ret = zipData[country]
    if (!ret){
        ret = "";
    }
    return ret
}

export function getStatesByCountry(country){
    let ret;
    let stateData = {
        "US": [["AL", "Alabama"], ["AK", "Alaska"], ["AZ", "Arizona"], ["AR", "Arkansas"],
               ["CA", "California"], ["CO", "Colorado"], ["CT", "Connecticut"], ["DE", "Delaware"], ["FL", "Florida"],
               ["GA", "Georgia"], ["HI", "Hawaii"], ["ID", "Idaho"], ["IL", "Illinois"], ["IN", "Indiana"],
               ["IA", "Iowa"], ["KS", "Kansas"], ["KY", "Kentucky"], ["LA", "Louisiana"], ["ME", "Maine"],
               ["MD", "Maryland"], ["MA", "Massachusetts"], ["MI", "Michigan"], ["MN", "Minnesota"],
               ["MS", "Mississippi"], ["MO", "Missouri"], ["MT", "Montana"], ["NE", "Nebraska"], ["NV", "Nevada"],
               ["NH", "New Hampshire"], ["NJ", "New Jersey"], ["NM", "New Mexico"], ["NY", "New York"],
               ["NC", "North Carolina"], ["ND", "North Dakota"], ["OH", "Ohio"], ["OK", "Oklahoma"], ["OR", "Oregon"],
               ["PA", "Pennsylvania"], ["RI", "Rhode Island"], ["SC", "South Carolina"], ["SD", "South Dakota"],
               ["TN", "Tennessee"], ["TX", "Texas"], ["UT", "Utah"], ["VT", "Vermont"], ["VA", "Virginia"],
               ["WA", "Washington"], ["WV", "West Virginia"], ["WI", "Wisconsin"], ["WY", "Wyoming"]],
        "CA": [["AB", "Alberta"], ["BC", "British Columbia"], ["NB", "New Brunswick"],
               ["NL", "Newfoundland and Labrador"], ["NS", "Nova Scotia"], ["MB", "Manitoba"], ["ON", "Ontario"],
               ["PE", "Prince Edward Island"], ["QC", "Quebec"], ["SK", "Saskatchewan"]],
        "PR": []
    };
    ret = stateData[country];
    if (!ret){
        ret = [];
    }
    return stateData[country];
}

export function getStateOptionsByCountry(country){
    let ret
    let stateData = {
        "US": [
            <option key={"state-option-al"} value={"AL"}>Alabama</option>,
            <option key={"state-option-ak"} value={"AK"}>Alaska</option>,
            <option key={"state-option-az"} value={"AZ"}>Arizona</option>,
            <option key={"state-option-ar"} value={"AR"}>Arkansas</option>,
            <option key={"state-option-ca"} value={"CA"}>California</option>,
            <option key={"state-option-co"} value={"CO"}>Colorado</option>,
            <option key={"state-option-ct"} value={"CT"}>Connecticut</option>,
            <option key={"state-option-de"} value={"DE"}>Delaware</option>,
            <option key={"state-option-fl"} value={"FL"}>Florida</option>,
            <option key={"state-option-ga"} value={"GA"}>Georgia</option>,
            <option key={"state-option-hi"} value={"HI"}>Hawaii</option>,
            <option key={"state-option-id"} value={"ID"}>Idaho</option>,
            <option key={"state-option-il"} value={"IL"}>Illinois</option>,
            <option key={"state-option-in"} value={"IN"}>Indiana</option>,
            <option key={"state-option-ia"} value={"IA"}>Iowa</option>,
            <option key={"state-option-ks"} value={"KS"}>Kansas</option>,
            <option key={"state-option-ky"} value={"KY"}>Kentucky</option>,
            <option key={"state-option-la"} value={"LA"}>Louisiana</option>,
            <option key={"state-option-me"} value={"ME"}>Maine</option>,
            <option key={"state-option-md"} value={"MD"}>Maryland</option>,
            <option key={"state-option-ma"} value={"MA"}>Massachusetts</option>,
            <option key={"state-option-mi"} value={"MI"}>Michigan</option>,
            <option key={"state-option-mn"} value={"MN"}>Minnesota</option>,
            <option key={"state-option-ms"} value={"MS"}>Mississippi</option>,
            <option key={"state-option-mo"} value={"MO"}>Missouri</option>,
            <option key={"state-option-mt"} value={"MT"}>Montana</option>,
            <option key={"state-option-ne"} value={"NE"}>Nebraska</option>,
            <option key={"state-option-nv"} value={"NV"}>Nevada</option>,
            <option key={"state-option-nh"} value={"NH"}>New Hampshire</option>,
            <option key={"state-option-nj"} value={"NJ"}>New Jersey</option>,
            <option key={"state-option-nm"} value={"NM"}>New Mexico</option>,
            <option key={"state-option-ny"} value={"NY"}>New York</option>,
            <option key={"state-option-nc"} value={"NC"}>North Carolina</option>,
            <option key={"state-option-nd"} value={"ND"}>North Dakota</option>,
            <option key={"state-option-oh"} value={"OH"}>Ohio</option>,
            <option key={"state-option-ok"} value={"OK"}>Oklahoma</option>,
            <option key={"state-option-or"} value={"OR"}>Oregon</option>,
            <option key={"state-option-pa"} value={"PA"}>Oregon</option>,
            <option key={"state-option-ri"} value={"RI"}>Oregon</option>,
            <option key={"state-option-sc"} value={"SC"}>Oregon</option>,
            <option key={"state-option-sd"} value={"SD"}>Oregon</option>,
            <option key={"state-option-tn"} value={"TN"}>Tennessee</option>,
            <option key={"state-option-tx"} value={"TX"}>Texas</option>,
            <option key={"state-option-ut"} value={"UT"}>Utah</option>,
            <option key={"state-option-vt"} value={"VT"}>Vermont</option>,
            <option key={"state-option-va"} value={"VA"}>Virginia</option>,
            <option key={"state-option-wa"} value={"WA"}>Washington</option>,
            <option key={"state-option-wv"} value={"WV"}>West Virginia</option>,
            <option key={"state-option-wi"} value={"WI"}>Wisconsin</option>,
            <option key={"state-option-wy"} value={"WY"}>Wyoming</option>],
        "CA": [
            <option key={"state-option-ab"} value={"AB"}>Alberta</option>,
            <option key={"state-option-bc"} value={"BC"}>British Columbia</option>,
            <option key={"state-option-nb"} value={"NB"}>New Brunswick</option>,
            <option key={"state-option-nl"} value={"NL"}>Newfoundland and Labrador</option>,
            <option key={"state-option-ns"} value={"NS"}>Nova Scotia</option>,
            <option key={"state-option-mb"} value={"MB"}>Manitoba</option>,
            <option key={"state-option-on"} value={"ON"}>Ontario</option>,
            <option key={"state-option-pe"} value={"PE"}>Prince Edward Island</option>,
            <option key={"state-option-qc"} value={"QC"}>Quebec</option>,
            <option key={"state-option-sk"} value={"SK"}>Saskatchewan</option>],
        "PR": []
    };
    ret = stateData[country];
    if (!ret){
        ret = [];
    }
    return ret;
}

export function getCountryOptions(){
    return [
        <option key={"country-option-us"} value={"US"}>United States</option>,
        <option key={"country-option-ca"} value={"CA"}>Canada</option>,
        <option key={"country-option-pr"} value={"PR"}>Puerto Rico</option>
    ]
}

export function getCountryData(){
    return [
        ["US", "United States"],
        ["CA", "Canada"],
        ["PR", "Puerto Rico"]
    ];
}

export function makeLocalUUID(localId, tableName, sId){
    let localPadded = padNumber(localId, 10);
    return `${sId.slice(0, 8)}-${sId.slice(8, 12)}-${getTableId(tableName)}${localPadded.slice(0, 2)}-${localPadded.slice(2, 6)}-${localPadded.slice(6, 10)}${uuidv4().toString().slice(-8,)}`;
}

export function hexToRGB(hexStr){
    let r, g, b;
    hexStr = hexStr.replace("#", "");
    r = parseInt(hexStr.substring(0,2), 16);
    g = parseInt(hexStr.substring(2,4), 16);
    b = parseInt(hexStr.substring(4,6), 16);
    return [r, g, b];
}

export function rgbToHex(rgbArray){
    let r, g, b;
    r = parseInt(rgbArray[0]).toString(16).padStart(2, '0');
    g = parseInt(rgbArray[1]).toString(16).padStart(2, '0');
    b = parseInt(rgbArray[2]).toString(16).padStart(2, '0');
    return `#${r}${g}${b}`
}

function getTableId(table){
    let tbl = table.toLowerCase();
    let tableIdDict = {
        "alarms": "01",
        "campus": "02",
        "corporation": "03",
        "server": "04",
        "service": "05",
        "locations": "06",
        "locationtable": "06",
        "loc": "06",
        "buildings": "08",
        "devices": "09",
        "inovonics": "09",
        "logicaldevices": "10",
        "devicetype": "10",
        "devicetable": "10",
        "dev": "10",
        "residents": "11",
        "groups": "12",
        "group": "12",
        "alarmgrouplist": "13",
        "groupcontactlist": "14",
        "users": "15",
        "user": "15",
        "contacts": "16",
        "contact": "16",
        "matchterms": "17",
        "devicetypes": "18",
        "deviceuses": "19",
        "deviceusage": "19",
        "area": "20",
        "locationareas": "20",
        "deviceschedule": "21",
        "campusconfig": "22",
        "config": "23",
        "sysdata": "25",
        "udpwhitelist": "26",
        "udptable": "26",
        "rerouting": "27",
        "areatable": "20",
        "dnr": "29",
        "scheduletable": "20",
        "escalationtable": "30",
        "escalationprofiles": "30",
        "esc": "30",
        "escalation": "30",
        "matchtermstable": "17",
        "match": "17",
        "carrierdef": "28",
        "carriers": "28",
        "carrier": "28",
        "reportpresettable": "24",
        "reportpreset": "24",
        "schedule": "20",
        "vss": "31",
        "residenttimes": "32",
        "bleusages": "33",
        "report": "34",
        "reportdef": "35",
        "fingerprintrepeater": "37",
        "fingerprintrepeaters": "37",
        "fingerprintdetail": "38",
        "fingerprintdetails": "38",
        "fingerprintsummary": "39",
        "fingerprints": "39"
    };
    return tableIdDict[tbl];
}

export function setACNT(dData, invalue){
    dData.acnt = invalue;
    dData.rounds = dData?.campusData[invalue.toString()]?.is_rounding ? dData?.campusData[invalue.toString()]?.is_rounding : 0;
    dData.checkin = dData?.campusData[invalue.toString()]?.is_resident_checkin ? dData?.campusData[invalue.toString()]?.is_resident_checkin : 0;
    dData.evmSiteId = dData?.campusData[invalue.toString()]?.location_site_id ? dData.campusData[invalue.toString()].location_site_id : "0";
    dData.cloud = dData?.campusData[invalue.toString()]?.is_cloud ? dData?.campusData[invalue.toString()]?.is_cloud : 0;
    dData.hybrid = dData?.campusData[invalue.toString()]?.is_hybrid ? dData?.campusData[invalue.toString()]?.is_hybrid : false;
    dData.legacy = dData?.campusData[invalue.toString()]?.show_legacy ? dData?.campusData[invalue.toString()]?.show_legacy : false;
    dData.new = dData?.campusData[invalue.toString()]?.show_new ? dData?.campusData[invalue.toString()]?.show_new : false;
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function setCheckinACNT(dData, invalue){
    dData.checkin = invalue;
    dData.campusData[dData.acnt.toString()].is_resident_checkin = invalue;
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function setRoundsACNT(dData, invalue){
    dData.rounds = invalue;
    dData.campusData[dData.acnt.toString()].is_rounding = invalue;
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function setSoundsMute(dData, invalue){
    dData.soundsOff = invalue;
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function setReloadTimer(dData, invalue){
    dData.reloadTime = invalue;
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function campusToGuid(dData, campusId){
    if (dData.campusData[campusId]?.guid){
        return dData.campusData[campusId].guid;
    }else{
        return campusId;
    }
}

export function userToGuid(dData, userId){
    if (dData.cognito){
        return dData.cognito;
    }else{
        return userId;
    }
}

// Table Support Utilities

export function paginateData(records, pageNum, pageLimit){
    // Start = starting index for first item on page
    // End = ending index for first item on page
    // BeginDot = bool to determine starting ellipse or not
    // EndDot = bool to determine ending ellipse or note
    // pageMax = starting page to display in numbered sequence
    // pageMin = ending page to display in numbered sequence
    let recordLength, start, end, beginDot, endDot, pageMin, pageMax, max, diff;
    beginDot = false;
    endDot = false;
    diff = 0;
    recordLength = records?.length ? records.length : 0;
    // calculates the total page numbers and the starting and ending page number in sequence.
    max = Math.ceil(recordLength / pageLimit);
    if (max === 0){
        max = 1;
    }
    if (max > 5){
        pageMin = pageNum - 2;
        if (pageMin < 1){
            diff = 1 - pageMin;
        }
        pageMax = pageNum + (2 + diff)
        if (pageMax > max){
            diff = pageMax - max;
            pageMin = pageMin - diff;
        }
    }
    else{
        pageMin = 1;
        pageMax = max;
    }
    if (pageMin < 1){
        pageMin = 1;
    }
    if (pageMax > max){
        pageMax = max;
    }
    // Sets if ellipses show below
    if (pageMin > 1){
        beginDot = true;
    }
    if (pageMax < max){
        endDot = true;
    }
    // Sets the slice start and endpoint of the data below
    start = ((pageNum - 1) * pageLimit);
    end = pageNum * pageLimit - 1;
    if (end > recordLength){
        end = recordLength;
    }
    if (start < 0){
        start = 0;
    }
    return {start: start, end: end, pageMax: pageMax, pageMin: pageMin, beginDot: beginDot,
            endDot: endDot, max: max};
}

export function boolSort(a, b, column, order){
    if (order === "asc"){
        if (a[column] && !b[column]){
            return 1;
        }
        else if (!a[column] && b[column]){
            return -1;
        }
        else{
            return 0;
        }
    }else{
        if (b[column] && !a[column]){
            return 1;
        }
        else if (!b[column] && a[column]){
            return -1;
        }
        else{
            return 0;
        }
    }
}

export function sortFunc(a, b, column, order){
    let cA, cB, procColumn;
    if (typeof column === "object"){
        procColumn = column[0];
    }else{
        procColumn = column;
    }
    cA = a[procColumn];
    cB = b[procColumn];
    if (cA === null || cA === " "){
        cA = "";
    }
    if (cB === null || cB === " "){
        cB = "";
    }
    if (typeof cA === "string" && typeof cB === "string"){
        if (order === "asc"){
            return cA.localeCompare(cB, "en", {numeric: true});
        }else{
            return cB.localeCompare(cA, "en", {numeric: true});
        }
    }else{
        if(order === "desc") {
            if (cA < cB) {
                return 1;
            } else if (cA > cB) {
                return -1;
            } else {
                return 0;
            }
        } else{
            if (cA > cB) {
                return 1;
            } else if (cA < cB) {
                return -1;
            } else {
                return 0;
            }
        }
    }
}

export function checkAll(start, end, checkboxlist=[], setcheckboxlist=() => {}, headerField="uCheckHead",
                         rowCheckId="uCheck"){
    // Checks and unchecks all boxes from header checkbox
    let status, nxtCheck, entityList = [], newSet, curSet, itemsToRemove;
    curSet = new Set(checkboxlist);
    status = document.getElementById(headerField);
    if (status.checked){
        for (let i=start; i < end; i++){
            nxtCheck = document.getElementById(rowCheckId+i.toString());
            if (nxtCheck){
                nxtCheck.checked = true;
                entityList.push(nxtCheck.value.toString());
            }
        }
        newSet = new Set(entityList);
        setcheckboxlist([...newSet.difference(curSet)].concat([...curSet]));
    }else{
        for (let i=start; i < end; i++){
            nxtCheck = document.getElementById(rowCheckId+i.toString());
            if (nxtCheck) {
                nxtCheck.checked = false;
                entityList.push(nxtCheck.value.toString());
            }
        }
        newSet = new Set(entityList);
        itemsToRemove = curSet.intersection(newSet);
        setcheckboxlist(checkboxlist.filter((item) => !itemsToRemove.has(item)));
    }
}

export function checkUserAll(start, end, dData, checkboxlist=[], setcheckboxlist=() => {}, headerField="uCheckHead", rowCheckId="uCheck"){
    // Checks and unchecks all boxes from header checkbox with user validation setting.
    let status, nxtCheck, nxtRole, entityList = [];
    status = document.getElementById(headerField);
    if (status.checked){
        for (let i=start; i < end; i++){
            nxtCheck = document.getElementById(rowCheckId+i.toString());
            nxtRole = document.getElementById("user_role"+i.toString());
            if (nxtRole === null){
                nxtRole = document.getElementById("UserRole"+i.toString());
            }
            if (nxtCheck){
                if (nxtRole){
                    if (!compareRole(nxtRole.innerText, dData)){
                        nxtCheck.checked = true;
                        entityList.push(nxtCheck.value.toString());
                    }
                }else{
                    nxtCheck.checked = true;
                    entityList.push(nxtCheck.value.toString());
                }
            }
        }
        setcheckboxlist(entityList);
    }else{
        for (let i=start; i < end; i++){
            nxtCheck = document.getElementById(rowCheckId+i.toString());
            if (nxtCheck){
                nxtCheck.checked = false;
            }
        }
        setcheckboxlist([]);
    }
}

export function range(start, end){
    let arr = [];
    for (let i = start; i <= end; i++) {
        arr.push(i);
    }
    return arr;
}

export function columnSorter(column, sorter, setSorter){
    // Sorts current set of data in real time.
    let compareColumn = sorter.sortColumn;
    if (typeof compareColumn === "object"){
        compareColumn = compareColumn[0];
    }
    if (typeof column === "object"){
        column = column[0];
    }
    if (compareColumn !== column){
        setSorter(prevState => {
            return {sortColumn: column, sortOrder: "asc"};
        });
    }
    else{
        if (sorter.sortOrder === "asc"){
            setSorter(prevState => {
                return {...prevState, sortOrder: "desc"};
            });
        }
        else{
            setSorter(prevState => {
                return {...prevState, sortOrder: "asc"};
            });
        }
    }
}

// Modal Utility Functions

export function showModal(modalId){
    let modal;
    modal = new Modal(document.getElementById(modalId), {});
    modal.show();
}

export function hideModal(modalId){
    let modal;
    modal = Modal.getInstance(document.getElementById(modalId), {});
    modal.hide();
}

export function swapModal(curModalCnlBtn, newModal){
    document.getElementById(curModalCnlBtn)?.click();
    showModal(newModal);
}

export function deleteModalSingle(display, username, intext="Are you sure you wish to delete"){
    let modal, modalContent, oldModal;
    oldModal = document.getElementById("cancelEditBtn");
    modal = new Modal(document.getElementById("deletemodal"), {});
    modalContent = document.getElementById("deleteModalContent");
    modalContent.innerText = `${intext} ${display}?`;
    document.getElementById("uContent").value = username;
    oldModal.click();
    modal.show();
}

export function bulkDeleteModal(start, end, tableData, displayItem, recIdItem, nameItem, setDContent, intext="Are you sure you wish to delete the following records? \n"){
    let modal, display, recIdList, modalContent, nameList, tmpRecs;
    display = "";
    recIdList = [];
    nameList = [];
    for (let i=start; i < end; i++){
        let nxtCheck = document.getElementById("uCheck"+i.toString());
        if (nxtCheck?.checked){
            if (typeof displayItem === "object"){
                display += tableData[i][displayItem[0]] + " " + tableData[i][displayItem[1]] + ", ";
            }else{
                display += tableData[i][displayItem] + ", ";
            }
            tmpRecs = [];
            for (let i2 = 0; i2 < recIdItem.length; i2++){
                tmpRecs.push(tableData[i][recIdItem[i2]]);
            }
            recIdList.push(tmpRecs);
            nameList.push(tableData[i][nameItem]);
        }
    }
    setDContent(recIdList);
    display = display.slice(0, -2);
    modalContent = document.getElementById("deleteBulkModalContent");
    modalContent.innerText = `${intext}${display}`;
    document.getElementById("uContent").value = nameList;
    modal = new Modal(document.getElementById("bulkdeletemodal"), {});
    modal.show();
}


export function callActionModal(start, end, tableData, displayItem, recIdItem, nameItem, setDContent,
                                modalId, contentId, customText){
    let modal, display, recIdList, modalContent, nameList, tmpRecs;
    display = "";
    recIdList = [];
    nameList = [];
    for (let i=start; i < end; i++){
        let nxtCheck = document.getElementById("uCheck"+i.toString());
        if (nxtCheck?.checked){
            if (typeof displayItem === "object"){
                display += tableData[i][displayItem[0]] + " " + tableData[i][displayItem[1]] + ", ";
            }else{
                display += tableData[i][displayItem] + ", ";
            }
            tmpRecs = [];
            for (let i2 = 0; i2 < recIdItem.length; i2++){
                tmpRecs.push(tableData[i][recIdItem[i2]]);
            }
            recIdList.push(tmpRecs);
            nameList.push(tableData[i][nameItem]);
        }
    }
    setDContent(recIdList);
    display = display.slice(0, -2);
    modalContent = document.getElementById(contentId);
    modalContent.innerText = `${customText} \n\n${display}`;
    document.getElementById("uContent").value = nameList;
    modal = new Modal(document.getElementById(modalId), {});
    modal.show();
}


export function standardSort(a, b, ascending=true){
    let cA, cB;
    if (typeof a === "string"){
        if (!isNaN(a)){
            cA = parseInt(a);
        }else{
            cA = a;
        }
    }else{
        cA = a;
    }
    if (typeof b === "string"){
        if (!isNaN(b)){
            cB = parseInt(b);
        }else{
            cB = b;
        }
    }else{
        cB = b;
    }
    if(ascending) {
        if (cA < cB) {
            return 1;
        } else if (cA > cB) {
            return -1;
        } else {
            return 0;
        }
    }
    else{
        if (cA > cB) {
            return 1;
        } else if (cA < cB) {
            return -1;
        } else {
            return 0;
        }
    }
}

export function isTimeTodayPassed(sendTime, offset=0){
    let utcnow, nowdt, midnight, secFromMidnight, secondsFromTime;
    secondsFromTime = convertHHMMtoSec(sendTime);
    utcnow = DateTime.now().toUTC();
    nowdt = utcnow.plus({hours:offset});
    midnight = nowdt.startOf("day");
    secFromMidnight = nowdt.diff(midnight, "seconds").seconds;
    return secFromMidnight > secondsFromTime;
}

export function calculateSendTime(frequencyId, freqRate, sendTime, offset){
    let days, utcDatetime, parsedTime, sendHour, sendMinute, localDatetime, freqRateList = [];
    utcDatetime = DateTime.now().toUTC();
    localDatetime = utcDatetime.plus({hours: offset});
    parsedTime = sendTime.split(":");
    sendHour = parsedTime[0];
    sendMinute = parsedTime[1];
    for (let i=0; i < freqRate?.length; i++){
        freqRateList.push(parseInt(freqRate[i]));
    }

    if (frequencyId === 1){
        days =  freqRateList.length > 0 ? freqRateList[0] : 1;
        if (!isTimeTodayPassed(sendTime, offset)){
            days = 0;
        }
    }else if (frequencyId === 2){
        let curWeekday = localDatetime.weekday;
        freqRateList.sort((a,b) => standardSort(a, b, false))
        for (let i=0; i < freqRateList.length; i++){
            if (freqRateList[i] > curWeekday){
                days = freqRateList[i] - curWeekday;
            }
            if (days){
                break;
            }
        }
        if (!days){
            if (!isTimeTodayPassed(sendTime, offset)){
                days = 0;
            }else{
                days = 6 - curWeekday + freqRateList[0];
            }
        }
    }else{
        let curDay = localDatetime.day;
        // Sort list to ensure numerical order, this is important
        freqRateList.sort((a,b) => standardSort(a, b, false));
        // If zero in array, need to move to the end as represents end of month, very important.
        if (freqRateList.includes(0)){
            freqRateList = freqRateList.filter((value) => {return value !== 0});
            freqRateList.push(0);
        }
        // Iterate to verify if next scheduled day is later this month.
        for (let i=0; i < freqRateList.length; i++){
            if (freqRateList[i] > curDay){
                // Found a day this month, set days to timedelta.
                days = freqRateList[i] - curDay;
            }else if(freqRateList[i] === 0){
                // handling end of month symbolic integer
                if (curDay === localDatetime.endOf("month").day && !isTimeTodayPassed(sendTime, offset)){
                    days = 0;
                }else{
                    days = localDatetime.endOf("month").day - curDay;
                }
            }
            if (days || days === 0){
                // Break if days set to ensure we get the next upcomming one, not the last one.
                break;
            }
        }
        // If days is undefined that means no days later this month are set so we are looking at a day
        // earlier in the month for next month or possibly today but later today.
        if (!days && days !== 0){
            // Check to see if we need to schedule a report for later today if month day and time not passed is true.
            if (freqRateList.includes(curDay.toString()) && !isTimeTodayPassed(sendTime, offset)){
                days = 0;
            }else{
                days = localDatetime.endOf("month").day - curDay + freqRateList[0];
            }
        }
    }
    let nextDatetime = localDatetime.plus({days: days});
    nextDatetime = nextDatetime.set({hour: parseInt(sendHour), minute: parseInt(sendMinute), second: 0});
    nextDatetime = nextDatetime.minus({hours: offset});
    return nextDatetime.toFormat("yyyy-MM-dd HH:mm:ss");
}

export function toggleCollapse(button, content){
    document.getElementById(content).classList.toggle("show");
    if (document.getElementById(content).classList.contains("show")){
        document.getElementById(button).classList.toggle("fe-plus");
        document.getElementById(button).classList.toggle("fe-minus");
    }else{
        document.getElementById(button).classList.toggle("fe-plus");
        document.getElementById(button).classList.toggle("fe-minus");
    }
}

export function convertHHMMtoSec(intime){
    let seconds = 0;
    let timeArray = intime.split(":");
    seconds += parseInt(timeArray[0]) * 3600;
    seconds += parseInt(timeArray[1]) * 60;
    return seconds;
}

export function convertSectoHHMM(intime){
    let timestamp, hours, minutes;
    if (intime !== 86400){
        hours = pt2(Math.floor(intime / 3600));
        minutes = pt2(Math.round((intime % 3600) / 60));
        timestamp = `${hours}:${minutes}`;
    }else{
        timestamp = "23:59"
    }
    return timestamp;
}

export function timeOfDayUTCtoLocalized(secIn, tz){
    let offset, newTime;
    offset = tz * 3600;
    newTime = secIn + offset;
    if (newTime < 0){
      return 86400 + newTime;
    }else if (newTime > 86399){
      return newTime - 86400;
    }else{
      return newTime;
    }
}

export function highlightText(isExtreme, isWeighted, isOver, weightValue){
    // Builds hover text for weighted icons. Examples include '$Value $threshold: $weighted $overunder $value $end'
    return `${isExtreme} ${isOver ? "High" : "Low"} | ${isWeighted ? "Weighted" : "Value"} ${isOver ? "over" : "under"} ${weightValue}${isWeighted ? "% of total" : ""}`;
}

export function timeOfDayLocalizedtoUTC(secIn, tz){
    return timeOfDayUTCtoLocalized(secIn, tz * -1);
}

export function isThreshold(cellValue, compareValue, weight=50.0, weighted, overunder=">"){
    // This function is to return false if the cell value is less than 50% above the compare average.
    let ret = false;
    if (typeof cellValue === "string"){
        if (isNaN(cellValue)){
            return false;
        }
    }
    if (typeof compareValue === "string"){
        if (isNaN(compareValue)){
            return false;
        }
    }
    if (weighted){
        if ([">", "over", "gt"].includes(overunder.toLowerCase())){
            if ((parseFloat(cellValue) / parseFloat(compareValue) * 100) > weight){
                ret = true;
            }
        }else{
            if ((parseFloat(cellValue) / parseFloat(compareValue) * 100) < weight){
                ret = true;
            }
        }
    }else{
        if ([">", "over", "gt"].includes(overunder.toLowerCase())){
            if (parseFloat(cellValue) > weight){
                ret = true;
            }
        }else{
            if (parseFloat(cellValue) < weight){
                ret = true;
            }
        }
    }
    return ret;
}

export function setRoleInfo(weight, dData){
    dData.role_weight = weight;
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function reloadSessionCorp(corpId, index, cognitoId, dData, campusIdOverride=0){
    if (parseInt(index) >= 0){
        document.getElementById(`edit-modal${index}`)?.classList?.add("d-none");
        document.getElementById(`edit-modal-spinner${index}`)?.classList?.remove("d-none");
    }
    ApiRequester({reqEndpoint: "sessionLoad", pKey: "corporate_id", pVal: corpId, myId: cognitoId,
                        campus_override: campusIdOverride}).then(data => {
        if (parseInt(index) >= 0 || parseInt(index) === -99){
            if (parseInt(index) >= 0){
                document.getElementById(`edit-modal${index}`)?.classList?.remove("d-none");
                document.getElementById(`edit-modal-spinner${index}`)?.classList?.add("d-none");
            }
            if (data[0]){
                clearFilters(dData);
                toast.success("Session switched to new corp");
                sleepThenExecute(1000, () => {
                    return window.location.href = "/";
                });
            }else{
                toast.fail("Session switch failed, contact support if failure persists");
            }
        }else{
            if (data[0]){
                console.log("Session updated");
            }else{
                console.log("Session update failed");
            }
        }
    });
}


export function clearFilters(dData, write=true){
    let bData;
    if (write === true){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }
    dData.areaFilter = [];
    dData.areaGroupFilter = [];
    dData.areaTypeFilter = [];
    dData.devFilter = [];
    dData.devCatFilter = [];
    dData.resFilter = [];
    dData.residentGroupFilter = [];
    dData.typeFilter = [];
    dData.userFilter = [];
    dData.responseFilter = [false, "lt", 60];
    dData.userGroupFilter = [];
    dData.reasonFilter = [];
    dData.areaFilterLabel = [];
    dData.areaGroupFilterLabel = [];
    dData.areaTypeFilterLabel = [];
    dData.devFilterLabel = [];
    dData.devCatFilterLabel = [];
    dData.resFilterLabel = [];
    dData.residentGroupFilterLabel = [];
    dData.typeFilterLabel = [];
    dData.userFilterLabel = [];
    dData.userGroupFilterLabel = [];
    dData.reasonFilterLabel = [];
    dData.responseFilterLabel = ["Response time", "less than", "60 minutes"];
    if (write === true){
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
    }
    return dData;
}


export function setResFilters(dData, resId, resName){
    dData = clearFilters(dData, false);
    dData.resFilter = [resId];
    dData.resFilterLabel = [resName];
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function setAreaFilters(dData, areaId, areaName){
    dData = clearFilters(dData, false);
    dData.areaFilter = [areaId];
    dData.areaFilterLabel = [areaName];
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function setDeviceFilters(dData, devId, devName){
    dData = clearFilters(dData, false);
    dData.devFilter = [devId];
    dData.devFilterLabel = [devName];
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function setUserFilters(dData, userId, username){
    dData = clearFilters(dData, false);
    dData.userFilter = [userId];
    dData.userFilterLabel = [username];
    ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
}

export function sleepThenExecute(ms, process) {
  return new Promise(() => setTimeout(process, ms));
}

export function reloadSessionCampus(campusId, index, cognitoId){
    ApiRequester({reqEndpoint: "sessionLoad", pKey: "campus_id", pVal: campusId, myId: cognitoId}).then(data => {
        if (data[0]){
            console.log("Session updated");
        }else{
            console.log("Session update failed");
        }
    });
}

export function getIsoStandard(offset=0, showMillis=false, showTZ=false){
    let nowdt, newdt;
    nowdt = DateTime.now();
    newdt = nowdt.plus({days: offset}).set({millisecond: 0});
    return newdt.toISO({includeOffset: showTZ, suppressMilliseconds: !showMillis});
}

export function getBorderColor(alertType){
    if (["maint", "maintenance", "system"].includes(alertType.toLowerCase())){
        return "#9fc6ff";
    }else{
        return "#dc3545";
    }
}

export function HHMMtoLocalTime(inTime){
    let dt = DateTime.fromISO(inTime);
    return dt.toFormat("h:mm a");
}

export function determineUseId(catId, typeId){
    let useId = 0, realarm = false, alarmPropped = false, timeout = false, withouttimeout = false,
        alarmProppedClear = false, reset, realarmItem, notimeoutItem, timeoutItem, resetItem,
        alarmProppedItem, alarmProppedClearItem;

    alarmProppedClearItem = document.getElementById("alarmProppedClear");
    if (![undefined, null].includes(alarmProppedClearItem)){
        alarmProppedClear = alarmProppedClearItem?.checked;
    }
    alarmProppedItem = document.getElementById("alarmPropped");
    if (![undefined, null].includes(alarmProppedItem)){
        alarmPropped = alarmProppedItem?.checked;
    }
    resetItem = document.getElementById("onreset");
    if (![undefined, null].includes(resetItem)){
        reset = resetItem?.checked;
    }
    timeoutItem = document.getElementById("timeout");
    if (![undefined, null].includes(timeoutItem)){
        timeout = timeoutItem?.checked;
    }
    notimeoutItem = document.getElementById("withouttimeout");
    if (![undefined, null].includes(notimeoutItem)){
        withouttimeout = notimeoutItem?.checked;
    }
    realarmItem = document.getElementById("realarm");
    if (![undefined, null].includes(realarmItem)){
        realarm = realarmItem?.checked;
    }

    if (["2"].includes(catId.toString())){
        if (["2"].includes(typeId.toString())){
            useId = 88;
        }else if (["3"].includes(typeId.toString())){
            useId = 87;
        }else if (["4"].includes(typeId.toString())){
            useId = 134;
        }else if (["5"].includes(typeId.toString())){
            useId = 138;
        }else if (["6"].includes(typeId.toString())){
            useId = 86;
        }else if (["19"].includes(typeId.toString())){
            useId = 89;
        }
    }else if (["3"].includes(catId.toString())){
        // Door and Entry Devices
        // todo - Need to change local server to use device name, when all standardized, may pair down use ids for names
        if (["7", "23"].includes(typeId.toString())){
            // Doorbell & Doorbell w/ reset
            if (reset){
                useId = 56;
            }else if (realarm){
                useId = 11;
            }else if (timeout){
                useId = 145;
            }
        }else if (["8", "9", "20", "21"].includes(typeId.toString())){
            // Door & Window sensors
            if (reset){
                useId = 143;
            }else if (realarm){
                useId = 156;
            }else if (timeout){
                useId = 8;
            }else if (withouttimeout){
                useId = 141;
            }else if (alarmPropped){
                useId = 170;
            }else if (alarmProppedClear){
                useId = 172;
            }
        }else if (["33", "36"].includes(typeId.toString())){
            // Door/Window Transmitters
            if (["33"].includes(typeId.toString())){
                useId = 162;
            }else{
                useId = 172;
            }
        }else if (["27"].includes(typeId.toString())){
            useId = 152;
        }else if (["35"].includes(typeId.toString())){
            useId = 169;
        }
    }else if (["5"].includes(catId.toString())) {
        useId = 131;
    }else if (["4"].includes(catId.toString())){
        useId = 135;
    }else if (["8"].includes(catId.toString())){
        if (["12"].includes(typeId.toString())){
            useId = 94;
        }else if (["13"].includes(typeId.toString())){
            useId = 95;
        }
    }else if (["9"].includes(catId.toString())){
        if (["14"].includes(typeId.toString())){
            useId = 7;
        }else if (["34"]){
            useId = 166
        }
    }else if (["12"].includes(catId.toString())){
        if (["17"].includes(typeId.toString())){
            useId = 136;
        }else if (["18"].includes(typeId.toString())){
            useId = 137;
        }else if (["29"].includes(typeId.toString())){
            useId = 154;
        }
    }else if (["11"].includes(catId.toString())){
        if (["15"].includes(typeId.toString())){
            useId = 18;
        }else if (["16"].includes(typeId.toString())){
            useId = 139;
        }
    }else if (["1"].includes(catId.toString())){
        if (["1"].includes(typeId.toString())){
            useId = 2;
        }
    }else if (["15"].includes(catId.toString())){
        if (["24"].includes(typeId.toString())){
            useId = 4;
        }else if (["22"].includes(typeId.toString())){
            useId = 140;
        }else if (["32"].includes(typeId.toString())){
            useId = 160;
        }
    }else if (["16"].includes(catId.toString())){
        if (["25"].includes(typeId.toString())){
            useId = 5;
        }else if (["26"].includes(typeId.toString())){
            useId = 68;
        }else if (["30"].includes(typeId.toString())){
            useId = 159;
        }else if (["37"].includes(typeId.toString())){
            useId = 78;
        }else if (["38"].includes(typeId.toString())){
            useId = 79;
        }
    }else if (["17"].includes(catId.toString())){
        if (["28"].includes(typeId.toString())){
            useId = 153;
        }
    }
    return useId;
}


export function areaLearninBehaviorToggle(value){
    if (["7", "23"].includes(value.toString())){
        // Doorbell and Doorbell w/reset, only needs alarm behavior
        document.getElementById("behavior-heading-container")?.classList?.remove("d-none");
        if (["23"].includes(value.toString())){
            document.getElementById("onreset-switch").classList.remove("d-none");
        }else{
            document.getElementById("onreset-switch").classList.add("d-none");
        }
        document.getElementById("onclear-switch").classList.add("d-none");
        document.getElementById("alarmPropped-switch").classList.add("d-none");
        document.getElementById("alarmProppedClear-switch").classList.add("d-none");
        document.getElementById("onreset").checked = ["23"].includes(value.toString());
        document.getElementById("timeout").checked = ["7"].includes(value.toString());
    }
    else if (["8", "9", "20", "21"].includes(value.toString())){
        // Door and window based devices, includes with resets.
        document.getElementById("behavior-heading-container")?.classList?.remove("d-none");
        if (["21", "20"].includes(value.toString())){
            document.getElementById("onreset-switch").classList.remove("d-none");
            document.getElementById("alarmPropped-switch").classList.add("d-none");
            document.getElementById("alarmProppedClear-switch").classList.remove("d-none");
        }else {
            document.getElementById("onreset-switch").classList.add("d-none");
            document.getElementById("alarmPropped-switch").classList.remove("d-none");
            document.getElementById("alarmProppedClear-switch").classList.add("d-none");
        }
        document.getElementById("onclear-switch").classList.remove("d-none");
        document.getElementById("onreset").checked = ["21", "20"].includes(value.toString());
        document.getElementById("withouttimeout").checked = ["8", "9", "33"].includes(value.toString());
    } else {
        document.getElementById("behavior-heading-container")?.classList?.add("d-none");
    }
}


export function determineDevName(devName, devTypeId){
    if (["2", "19"].includes(devTypeId)){
        return "Waterproof Pendant";
    }else{
        return devName;
    }
}