import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {Suspense, useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import DetailedEvents from "./report-templates/DetailedEvents";
import { CSVLink } from "react-csv"
import {
    paginateData, getToday, getY2Date, getYear, getMonthRange, getWeekRange, getQuarterRange, formatDate,
    formatDateIso, sortFunc, handleSubmitActions, showModal, campusToGuid, getCurrentYear, formSuccess
} from "../libs";
import {Modal} from "bootstrap";
import {toast, ToastContainer} from "react-toastify";
import ResidentEvents from "./report-templates/Resident";
import CaregiverEvents from "./report-templates/Caregiver";
import Inspection from "./report-templates/Inspection";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Footer from "./Footer";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";
import CheckInTrans from "./report-templates/CheckInTrans";
import CheckInHistory from "./report-templates/CheckInHistory";


const LegacyEventReports = (props) => {

    let dData, radioSettings, filterFeature;

    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const fullDeviceList = ["Activity Monitoring", "Door and Window", "Environment", "Fall", "Other", "Pendant",
                            "Pressure Pad", "Resident Station", "Wandering"];

    const [gData, setData] = useState({
        reportData: {},
        status: "init",
        btnAvail: false,
        tableRows: [],
        summaryRows: []
    });
    let daysago = new Date().setDate(new Date().getDate() - 1);
    const [csvLink, setLink] = useState({
        filename: props.toFetch+".csv",
        headers: [],
        data: []
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [report, setReport] = useState(<div> </div>);
    const [start, setStart] = useState(new Date(daysago));
    const [end, setEnd] = useState(new Date(daysago));
    const [sorter, setSorter] = useState(() => {
        let ret;
        if (props.toFetch === "resident" || props.toFetch === "caregiver"){
            ret = {sortColumn: "TotalAlarms",
                sortOrder: "asc"};
        }else{
            ret = {sortColumn: "Text",
                sortOrder: "asc"};
        }
        return ret;
    })
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [queryDevices, setQueryDevices] = useState(dData.deviceTypes ? dData?.deviceTypes : []);
    const [tmpQueryDevices, setTmpQueryDevices] = useState(dData.deviceTypes ? dData?.deviceTypes : []);
    const [queryLocations, setQueryLocations] = useState(dData.locations ? dData?.locations : []);
    const [tmpQueryLocations, setTmpQueryLocations] = useState(dData.locations ? dData?.locations : []);
    const [devices, setDevices] = useState([]);
    const [repType, setRepType] = useState("Detailed");
    const [csvButtonHTML, setCSVButtonHTML] = useState("");
    const [startDisplay, setStartDisplay] = useState("");
    const [endDisplay, setEndDisplay] = useState("");
    const [campusId, setCampusId] = useState(dData.acnt);
    const [count, setCount] = useState(0);
    const [footer, setFooter] = useState(<div></div>);

    if (["alerts", "caregiver", "resident", "maintenance", "inspection", "checkin-history"].includes(props.toFetch)){
        radioSettings = (
        <div className={"d-inline-block align-bottom mb-2 mx-2"}>
            <div className={"form-check"}>
                <input className={"form-check-input"} type={"radio"} name={"repType"} id={"radio1"}
                       checked={repType === "Detailed"} onChange={() => setRepType("Detailed")}/>
                <label className={"form-check-label"} htmlFor={"radio1"}>
                    Detailed
                </label>
            </div>
            <div className={"form-check"}>
                <input className={"form-check-input"} type={"radio"} name={"repType"} id={"radio2"}
                       checked={repType === "Summary"} onChange={() => setRepType("Summary")}/>
                <label className={"form-check-label"} htmlFor={"radio2"}>
                    Summary
                </label>
            </div>
        </div>
        );
    }else{
        radioSettings = ""
    }

    if (["alerts", "caregiver", "resident", "maintenance"].includes(props.toFetch)){
        filterFeature = (
            <Button text={""} icon={true} iconClass={"fe fe-filter fs-3"} class={"btn btn-secondary mx-2 mb-2"} onClick={() => filterModal2()} />
        );
    }else{
        filterFeature = "";
    }

    useEffect(() => {
        setData({
        reportData: {},
        status: "init",
        btnAvail: false,
        tableRows: []
    })
    }, [props.toFetch]);

    useEffect(() => {
        let tmpDevices = [];
        for (let i=0; i < fullDeviceList?.length; i++){
            tmpDevices.push(
                <li className={"form-check form-switch mb-2"} key={"deviceList"+i.toString()}>
                    <label className={"form-check-label"} htmlFor={"checkD"+i.toString()}>{fullDeviceList[i]}</label>
                    <input style={{height: "22px"}} className={"form-check-input"} id={"checkD"+i.toString()} type={"checkbox"}
                           checked={tmpQueryDevices.includes(fullDeviceList[i])}
                           onChange={() => {
                               if (tmpQueryDevices.includes(fullDeviceList[i])){
                                   setTmpQueryDevices((prevState) => {
                                       return prevState.filter(item => item !== fullDeviceList[i])
                                   });
                               }else{
                                   setTmpQueryDevices((prevState) => {
                                       return [...prevState, fullDeviceList[i]]
                                   });
                               }
                           }} />
                </li>
            );
        }
        setDevices(tmpDevices);
    }, [tmpQueryDevices]);

    useEffect(() => {
        if (gData?.reportData && Object.keys(gData.reportData)?.length > 0) {
            gData.reportData.records.sort((a, b) => sortFunc(a, b, sorter?.sortColumn, sorter?.sortOrder));
            let pRules = paginateData(gData.reportData.records, page, perPage);
            setPRules(() => pRules);
            setData(prevState => {
                return {...prevState, tableRows: gData.reportData.records.slice(pRules.start, pRules.end)}
            });
        }
        if (gData?.reportData && Object.keys(gData.reportData)?.length > 0 && gData.reportData?.summary) {
            gData.reportData.summary.sort((a, b) => sortFunc(a, b, sorter?.sortColumn, sorter?.sortOrder));
            setData(prevState => {
                return {...prevState, summaryRows: gData.reportData.summary}
            });
        }
    }, [sorter]);

    useEffect(() => {
        if (gData?.reportData && Object.keys(gData.reportData)?.length > 0) {
            let pRules = paginateData(gData.reportData.records, page, perPage);
            setPRules(() => pRules);
            setData(prevState => {
                return {...prevState, tableRows: gData.reportData.records.slice(pRules.start, pRules.end)}
            });
        }
    }, [page, perPage]);

    useEffect(() => {
        if (gData?.reportData !== undefined && gData.status !== "init") {
            setData(prevState => {
                return {...prevState, status: ""}
            });
            if (gData.reportData.records?.length > 0 || gData?.reportData?.overlimit){
                setFooter(() => (
                    <div>
                        <Pagination>
                            <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                             endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                             max={ppRules.max}/>
                        </Pagination>
                        <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
                    </div>
                ));
                if (props?.toFetch === "alerts"){
                    setLink(() => {
                        return {filename: "alerts.csv",
                            headers: [
                                {label: "Event", key: "Text"},
                                {label: "Device", key: "Device"},
                                {label: "Residents", key: "Residents"},
                                {label: "Event Type", key: "EventType"},
                                {label: "Event Date", key: "EventDate"},
                                {label: "Event Hour", key: "EventHour"},
                                {label: "Occurred", key: "EventDateTime"},
                                {label: "Accepted", key: "AcceptDateTime"},
                                {label: "Accepted By", key: "AcceptUsername"},
                                {label: "Responded", key: "AckDateTime"},
                                {label: "Finished", key: "FinishDateTime"},
                                {label: "Reason", key: "Reason"},
                                {label: "Response Time", key: "ResponseTime"},
                                {label: "Response (seconds)", key: "RawResponseTime"}],
                            data: gData.reportData.records}
                    });
                    setReport(<DetailedEvents reportData={gData.reportData} sorter={sorter}
                                              start={startDisplay} end={endDisplay} repType={repType}
                                              setSorter={(value) => setSorter(value)} tableRows={gData.tableRows}/>);
                }
                else if(props?.toFetch === "resident"){
                    setLink(() => {
                    return {filename: "resident.csv",
                        headers: [
                            {label: "Resident", key: "Resident"},
                            {label: "Response Time (Min)", key: "RespTime"},
                            {label: "Response Time (Sec)", key: "RawRespTime"},
                            {label: "Avg Resp Time (Min)", key: "AvgRespTime"},
                            {label: "Avg Resp Time (Sec)", key: "RawAvgRespTime"},
                            {label: "Total Service Time (Hr)", key: "TotalService"},
                            {label: "Total Service Time (Sec)", key: "RawTotalService"},
                            {label: "Total Alarms", key: "TotalAlarms"},
                            {label: "Alarms Per Day", key: "AlarmsDay"}],
                        data: gData.reportData.records}
                    });
                    setReport(<ResidentEvents reportData={gData.reportData} sorter={sorter}
                                              start={startDisplay} end={endDisplay} repType={repType}
                                              setSorter={(value) => setSorter(value)} tableRows={gData.tableRows}/>);
                }
                else if(props?.toFetch === "caregiver"){
                    setLink(() => {
                    return {filename: "caregiver.csv",
                        headers: [
                            {label: "Caregiver", key: "Caregiver"},
                            {label: "Response Time (Min)", key: "RespTime"},
                            {label: "Response Time (Sec)", key: "RawRespTime"},
                            {label: "Avg Resp Time (Min)", key: "AvgRespTime"},
                            {label: "Avg Resp Time (Sec)", key: "RawAvgRespTime"},
                            {label: "Total Service Time (Hr)", key: "TotalService"},
                            {label: "Total Service Time (Sec)", key: "RawTotalService"},
                            {label: "Total Alarms", key: "TotalAlarms"}],
                        data: gData.reportData.records}
                    });
                    setReport(<CaregiverEvents reportData={gData.reportData} sorter={sorter}
                                               start={startDisplay} end={endDisplay} repType={repType}
                                               setSorter={(value) => setSorter(value)} tableRows={gData.tableRows}/>);
                }
                else if(props?.toFetch === "inspection"){
                    setLink(() => {
                    return {filename: "inspection.csv",
                        headers: [
                            {label: "Device", key: "DeviceUse"},
                            {label: "Serial Number", key: "serialNum"},
                            {label: "Location", key: "Location"},
                            {label: "Test", key: "Status"},
                            {label: "Last Alarm", key: "LastAlarmDateTime"}],
                        data: gData.reportData.records}
                    });
                    setReport(<Inspection reportData={gData.reportData} sorter={sorter}
                                          start={startDisplay} end={endDisplay} repType={repType}
                                          setSorter={(value) => setSorter(value)} tableRows={gData.tableRows}/>);
                }
                else if(props?.toFetch === "checkin-transaction"){
                    setLink(() => {
                    return {filename: "checkin_report.csv",
                        headers: [
                            {label: "Resident First Name", key: "resident_first_name"},
                            {label: "Resident Last Name", key: "resident_last_name"},
                            {label: "Checked In", key: "check_in_time"},
                            {label: "Device", key: "device_name"},
                            {label: "Area", key: "area_name"}],
                        data: gData.reportData.records}
                    });
                    setReport(<CheckInTrans reportData={gData.reportData} sorter={sorter} summaryRows={gData?.summaryRows}
                                          start={startDisplay} end={endDisplay} repType={repType}
                                          setSorter={(value) => setSorter(value)} tableRows={gData?.tableRows}/>);
                }
                else if(props?.toFetch === "checkin-history"){
                    setLink(() => {
                    return {filename: "checkin_report.csv",
                        headers: [
                            {label: "Resident First Name", key: "resident_first_name"},
                            {label: "Resident Last Name", key: "resident_last_name"},
                            {label: "Checked In", key: "check_in_time"},
                            {label: "Device", key: "device_name"},
                            {label: "Area", key: "area_name"}],
                        data: gData.reportData.records}
                    });
                    setReport(<CheckInHistory reportData={gData.reportData} sorter={sorter} summaryRows={gData?.summaryRows}
                                              start={startDisplay} end={endDisplay} repType={repType} campusId={campusId}
                                              setSorter={(value) => setSorter(value)} tableRows={gData.tableRows}/>);
                }
                gData.btnAvail = true;
            }else{
                setFooter(() => (
                    <div>
                    </div>
                ));
                setReport(<div>
                    <h4>No data to report.</h4>
                </div>);
                setFooter(() => (
                    <div>
                        <Pagination>
                            <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                             endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                             max={ppRules.max}/>
                        </Pagination>
                        <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
                    </div>
                ));
            }
        } else {
            if (gData.status !== "init"){
                setReport(<div>
                    <h4>For report requested, please contact your customer success manager.</h4>
                </div>);
            }else {
                setReport(<div>
                    <h4>No Report Loaded.</h4>
                </div>);
            }
            setFooter(() => (
                <div>
                    <Pagination>
                        <PaginationItems setPage={1} page={1} beginDot={false}
                                         endDot={false} pageMin={1} pageMax={1}
                                         max={1}/>
                    </Pagination>
                    <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
                </div>
            ));
        }
    }, [gData.reportData, sorter, gData.tableRows, gData.summaryRows, repType, perPage]);


    useEffect(() => {
        if (gData.reportData.records?.length > 0) {
            setCSVButtonHTML(() =>
                <div className={"d-inline-block"}>
                    <div className={"mx-2"}>
                        {gData.btnAvail === true
                            ? <CSVLink {...csvLink} uFEFF={false}>
                                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                                        class={"btn px-4 pt-2 btn-primary fs-4 mb-2"}/></CSVLink>
                            : ""}
                    </div>
                </div>
            );

        }else{
            setCSVButtonHTML(() => "");
        }
    }, [csvLink]);

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setCount(count => count + 1);
        }, 1000);
        if (gData.status !== "init" && count === 0){
            getReportData(props.toFetch, tmpQueryDevices, tmpQueryLocations);
        }
        return () => clearInterval(interval);
    }, [count]);


    function getReportData(tofetch, deviceTypes, locations){
        let rep, startDisplay, endDisplay, procedure;
        setData(prevState => {
            return {...prevState, status: "Loading"};
        });
        setReport(<div>
                </div>);
        if (tofetch === "alerts"){
            rep = "detailedEvents";
        }
        else if(tofetch === "resident"){
            rep = "residentEvents";
        }
        else if(tofetch === "caregiver"){
            rep = "caregiverEvents";
        }
        else if(tofetch === "maintenance"){
            rep = "maintenanceEvents";
        }
        else if(tofetch === "inspection"){
            rep = "inspection";
        }
        else if(tofetch === "checkin-transaction"){
            rep = "checkin";
            procedure = "sp_dev_checkin_transaction";
        }
        else if(tofetch === "checkin-history"){
            rep = "checkin";
            procedure = "sp_dev_checkin_archive";
        }
        startDisplay = formatDate(start);
        endDisplay = formatDate(end);
        setStartDisplay(() => startDisplay);
        setEndDisplay(() => endDisplay);

        // Conditional opportunity for different endpoints and reports.
        if (["checkin"].includes(rep)){
            ApiRequester({reqEndpoint: "myfetch", procedure: procedure, reqType: "stored",
                                payload: {"campus_id": dData.acnt, "start_time": formatDateIso(start), "end_time": formatDateIso(end)}}
            ).then(data => {
                setPage(1);
                let pRules = paginateData(data.records, 1, perPage);
                setPRules(() => pRules);
                setData(prevState => {
                    return {...prevState, reportData: data, summaryRows: data?.summary, tableRows: data?.records?.slice(pRules.start, pRules.end)}});
            });
        }else{
            ApiRequester({reqEndpoint: "get-report", repType: rep, CampusId: campusToGuid(dData, campusId), devices: deviceTypes,
                        startDate: formatDateIso(start), deviceKey: "std_use", locationKey: "locationname",
                        endDate: formatDateIso(end), locations: locations}
            ).then(data => {
                if (data?.overlimit){
                    toast.error("Report requested too big to display details, shorten date period and re-run report if details necessary.");
                }
                let tmpItem = data?.records;
                if (tmpItem === undefined){
                    data.records = [];
                }
                setPage(1);
                let pRules = paginateData(data.records, 1, perPage);
                setPRules(() => pRules);
                setData(prevState => {
                    return {...prevState, reportData: data, tableRows: data.records.slice(pRules.start, pRules.end)}});
            });
        }
    }

    function setButtonText(buttonId, text){
        let button;
        button = document.getElementById(buttonId);
        button.innerText = text;
    }

    function setListActive(ulItem, liItem){
        let ul, li, liItems;
        ul = document.getElementById(ulItem);
        liItems = ul.getElementsByTagName("li");
        for (let i=0; i < liItems?.length; i++){
            li = liItems[i];
            if (li.id === liItem){
                li.classList.add("active");
                li.setAttribute("aria-current", true);
            }else{
                li.setAttribute("aria-current", false);
                li.classList.remove("active");
            }
        }
    }

    function toggleDateWindow(toggle){
        let dateDiv;
        dateDiv = document.getElementById("dateWindow");
        if (toggle){
            dateDiv.classList.remove("d-none");
            dateDiv.classList.add("d-inline-block");
        }else{
            dateDiv.classList.add("d-none");
            dateDiv.classList.remove("d-inline-block");
        }
    }

    function filterModal2(){ // Modal for setting the devices to filter on.
        let modal;
        modal = new Modal(document.getElementById("filtermodal2"), {});
        modal.show();
    }

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit",
            "loadFilterSubmit", "cancelFilterBtn");
    }

    function filterSubmit2(){
        handleSubmitActions("filterSubmit2", "loadFilterSubmit2");
        setQueryDevices(tmpQueryDevices);
        setQueryLocations(tmpQueryLocations);
        dData.deviceTypes = tmpQueryDevices;
        dData.locations = tmpQueryLocations;
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Devices stored to session.", "filterSubmit2",
            "loadFilterSubmit2", "cancelFilterBtn2");
        if (gData.status !== "init"){
            getReportData(props.toFetch, tmpQueryDevices, tmpQueryLocations);
        }
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
                {csvButtonHTML}
                <div className={"dropdown d-inline"}>
                    <button type={"button"} className={"btn btn-secondary dropdown-toggle px-4 fs-3 mb-2 mx-2"}
                            data-bs-toggle={"dropdown"} id={"calendarDropdown"} data-bs-auto-close={"outside"}
                            style={{minWidth: "175px"}}>
                        <i className={"fe fe-calendar me-2"}><p id={"dropdownValue"} className={"fs-5 align-text-top"}>Yesterday</p></i>
                    </button>
                    <div className={"dropdown-menu dropdown-menu-end p-3"} aria-labelledby={"calendarDropdown"}>
                        <div className={"d-flex justify-content-between"}>
                            <ul className={"d-inline-block list-group-flush px-0"} id={"calendarList"}
                                style={{listStyle: "none", width: "max-content", minWidth: "fit-content"}}>
                                <li className={"list-group-item py-1"} id={"calendar-today"}>
                                    <Button class={"btn"} text={"Today"} onClick={() => {
                                        let dates = getToday();
                                        setListActive("calendarList", "calendar-today");
                                        setButtonText("dropdownValue", " Today");
                                        setStart(dates);
                                        setEnd(dates);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-week"}>
                                    <Button class={"btn"} text={"This Week"} onClick={() => {
                                        let dates = getWeekRange();
                                        setListActive("calendarList", "calendar-week")
                                        setButtonText("dropdownValue", " This Week");
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-month"}>
                                    <Button class={"btn"} text={"This Month"} onClick={() => {
                                        let dates = getMonthRange();
                                        setListActive("calendarList", "calendar-month")
                                        setButtonText("dropdownValue", " This Month");
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-quarter"}>
                                    <Button class={"btn"} text={"This Quarter"} onClick={() => {
                                        let dates = getQuarterRange();
                                        setListActive("calendarList", "calendar-quarter")
                                        setButtonText("dropdownValue", " This Quarter");
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-y2d"}>
                                    <Button class={"btn"} text={"Year to Date"} onClick={() => {
                                        let dates = getY2Date();
                                        setListActive("calendarList", "calendar-y2d")
                                        setButtonText("dropdownValue", " Year to Date");
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false)
                                    }} />
                                </li>
                                <li className={"list-group-item active py-1"} id={"calendar-yesterday"}>
                                    <Button class={"btn"} text={"Yesterday"} onClick={() => {
                                        let dates = getToday(-1);
                                        setListActive("calendarList", "calendar-yesterday");
                                        setButtonText("dropdownValue", " Yesterday");
                                        setStart(dates);
                                        setEnd(dates);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-pweek"}>
                                    <Button class={"btn"} text={"Last Week"} onClick={() => {
                                        let dates = getWeekRange(-1);
                                        setListActive("calendarList", "calendar-pweek")
                                        setButtonText("dropdownValue", " Last Week");
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-pmonth"}>
                                    <Button class={"btn"} text={"Last Month"} onClick={() => {
                                        let dates = getMonthRange(-1);
                                        setListActive("calendarList", "calendar-pmonth")
                                        setButtonText("dropdownValue", " Last Month");
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-pquarter"}>
                                    <Button class={"btn"} text={"Last Quarter"} onClick={() => {
                                        let dates = getQuarterRange(-1);
                                        setListActive("calendarList", "calendar-pquarter")
                                        setButtonText("dropdownValue", " Last Quarter");
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-pyear"}>
                                    <Button class={"btn"} text={"Last Year"} onClick={() => {
                                        let dates = getYear(-1);
                                        setListActive("calendarList", "calendar-pyear")
                                        setButtonText("dropdownValue", " Last Year");
                                        setStart(dates[0]);
                                        setEnd(dates[1]);
                                        toggleDateWindow(false)
                                    }}/>
                                </li>
                                <li className={"list-group-item py-1"} id={"calendar-custom"}>
                                    <Button class={"btn"} text={"Custom Date Range"} id={"customBtn"} onClick={() => {
                                        setListActive("calendarList", "calendar-custom");
                                        setButtonText("dropdownValue", " Custom");
                                        toggleDateWindow(true)}}/>
                                </li>
                            </ul>
                            <div id={"dateWindow"} className={"d-none ps-4"} style={{minWidth: "fit-content", width: "max-content"}}>
                                <div className={"form-floating d-inline-block me-2 mb-2 align-bottom"}>
                                    <h6 className={"fs-4"}>Start Date:</h6>
                                    <DatePicker
                                        className={"form-control"}
                                        selected={start}
                                        onChange={e => setStart(e)}
                                        selectsStart
                                        startDate={start}
                                        endDate={end}
                                        id={"startDate"}
                                    />
                                </div>
                                <div className={"form-floating d-inline-block me-2 mb-2 align-bottom"}>
                                    <h6 className={"fs-4"}>End Date:</h6>
                                    <DatePicker
                                        className={"form-control"}
                                        selected={end}
                                        onChange={e => setEnd(e)}
                                        selectsEnd
                                        startDate={start}
                                        endDate={end}
                                        minDate={start}
                                        id={"endDate"}
                                    />
                                    {/*<label htmlFor={"endDate"}>Report End</label>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {radioSettings}
                {gData.status === "Loading"
                    ?
                    <Button text={""} class={"btn px-4 pt-2 btn-toolbar btn-primary d-inline-block fs-3 mb-2 mx-2"}
                            spinner={true}/>
                    :
                    <Button text={""} icon={true} iconClass={"fe fe-refresh-cw"} class={"btn px-3 btn-toolbar btn-primary d-inline-block fs-3 mb-2 mx-2"}
                            onClick={() => {
                                if (["checkin-transaction"].includes(props?.toFetch)){
                                    setRepType(() => "Detailed")
                                }
                                getReportData(props.toFetch, queryDevices, queryLocations)
                            }}/>
                }
                {filterFeature}
            </div>
            <CampusDropDown campusId={campusId} setCount={(item) => setCount(item)} setCampusId={(item) => setCampusId(item)} pageAction={() => {}}/>
        </div>
    )

    const copyright = (
        <h6 className={"fs-5 pt-2"}>
            Copyright &copy; {getCurrentYear()}
        </h6>
    )
    const version = (
        <h6 className={"fs-5 pt-2"}>
            {process.env.REACT_APP_VERSION}
        </h6>
    )

    return (
        <div className={"main-content"}>
            <Header preTitle={"Event Reports"} Title={props.toFetch} content={headerContent}/>
            <div className={"container-fluid mt-4"}>
                {report}
                <div className={"footer-spacer"}>

                </div>
            </div>
            <Footer left={copyright} right={version} center={footer}/>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <div className={"modal fade"} id={"filtermodal2"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Filter Settings</h2>
                            <button type={"button"} className={"btn-close"} id={"filterClose2"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"filterForm2"}>
                            <div className={"modal-body"}>
                                <div className={"d-inline-block align-top"}>
                                    <ul>
                                        <h3>Devices:</h3>
                                        {devices}
                                    </ul>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelFilterBtn2"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadFilterSubmit2"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"filterSubmit2"} onClick={() => filterSubmit2()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </div>
    );
};

export default LegacyEventReports;