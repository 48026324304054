import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "./component-assets/Button";
import ApiRequester from "./ApiRequester";
import {
    paginateData, sortFunc, handleSubmitActions, actionAppearance, checkAll, formSuccess, showModal
} from "../libs"
import {ToastContainer} from "react-toastify";
import {CSVLink} from "react-csv";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Header from "./Header";
import Footer from "./Footer";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import PerPageModal from "./component-assets/PerPageModal";

const AllServers = (props) => {
    let dData, bData, headerData, csvHeaders;

    headerData = [
        {label: "Campus Name", key: "campus_name", align: "left",
            link: {enabled: true, linkto: "/profiles/campus", linkkey: "campus_id"}},
        {label: "Corporation", key: "corporate_name", align: "left",
            link: {enabled: true, linkto: "/profiles/corporate", linkkey: "corporate_id"}},
        {label: "Checkin Time", key: "checkin_time", align: "left", type: "datetime"},
        {label: "Recent Alarm Time", key: "recent_alarm_input_time", align: "left", type: "datetime"},
        {label: "Recent Alarm Bytes Raw", key: "recent_alarm_input_raw", align: "left", wrap: "break-all"},
        {label: "Recent Alarm Bytes Parsed", key: "recent_alarm_input_parsed", align: "left"},
        {label: "DB Version", key: "version_database", align: "left"},
        {label: "Code Version", key: "version_code", align: "left"},
    ]

    csvHeaders = [
        {label: "Campus", key: "campus_name"},
        {label: "Corporation", key: "corporate_name"},
        {label: "Checkin Time", key: "checkin_time"},
        {label: "Recent Alarm Time", key: "recent_alarm_input_time"},
        {label: "Recent Alarm Bytes Raw", key: "recent_alarm_input_raw"},
        {label: "Recent Alarm Bytes Parsed", key: "recent_alarm_input_parsed"},
        {label: "DB Version", key: "version_database"},
        {label: "Code Version", key: "version_code"}
    ]

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Main Table Data Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    // Main Table Control Variables
    const [count, setCount] = useState(0);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [search, setSearch] = useState("")
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    const [sorter, setSorter] = useState({
        sortColumn: "campus_name",
        sortOrder: "asc"
    });
    // Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "all_servers.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>)

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                payload: {campus_filter: ""}, procedure: "getpalcaredevices", reqType: "stored"}).then(data => {
                setDataOrigin(data ? data : []);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={false}
                                writeAccess={false} unk={"a-"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={() => {}} modal={false} parentComponent={"Customers"}
                                dData={dData} checkboxlist={[]} modalType={""} corpId={dData?.corp}
                                setCheckboxId={() => {}} checkidkey={"campus_id"} />);
    }, [activeData, sorter, page, perPage]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        let tmpActiveData, input, pRules;
        tmpActiveData = [];
        input = search.toString().toLowerCase();
        for (let i=0; i < dataOrigin.length; i++){
            if ((dataOrigin[i]?.campus_name && dataOrigin[i]?.campus_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.corporate_name && dataOrigin[i]?.corporate_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.checkin_time && dataOrigin[i]?.checkin_time?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.version_code && dataOrigin[i]?.version_code?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.version_database && dataOrigin[i]?.version_database?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.recent_alarm_input_time && dataOrigin[i]?.recent_alarm_input_time?.toLowerCase()?.search(input) !== -1)){
                tmpActiveData.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpActiveData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpActiveData);
    }, [search, dataOrigin]);

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end, [], () => {})}
                                       writeAccess={false} sortOrder={sorter.sortOrder} checkboxHeaderId={"uCheckHead"}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} modal={false} checkboxlist={[]}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                </div>
                {csvButton}
            </div>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Customers"} Title={"Servers"} content={headerContent}/>
                <div className={"container-fluid mt-4"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default AllServers;