import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {Suspense, useEffect, useState} from "react";
import Button from "./component-assets/Button";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import Alis from "./integration-settings-templates/Alis";
import Tels from "./integration-settings-templates/Tels";
import ApiRequester from "./ApiRequester";
import Alexa from "./integration-settings-templates/Alexa";
import AccuTech from "./integration-settings-templates/AccuTech";
import {ToastContainer} from "react-toastify";
import PCC from "./integration-settings-templates/PCC";
import ECP from "./integration-settings-templates/ECP";
import Eldermark from "./integration-settings-templates/Eldermark";

const IntegrationSettings = (props) => {

    let dData;
    if (ReactSession.get("PAL") != null) {
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Global Page Variables
    const [loadState, setLoadState] = useState("alexa");
    const [pageContent, setPageContent] = useState(<></>);
    const [loaded, setLoaded] = useState(false);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [count, setCount] = useState(1);
    // TELS INTEGRATION VARIABLES
    const [telsLoaded, setTelsLoaded] = useState(false);
    const [telsWoLoaded, setTelsWoLoaded] = useState(false);
    const [telsData, setTelsData] = useState([]);
    const [telsWoData, setTelsWoData] = useState([]);
    const [mEventTypeLoaded, setMEventTypeLoaded] = useState(false);
    const [mEventTypeData, setMEventTypeData] = useState([]);
    // ALIS INTEGRATION VARIABLES
    const [alisLoaded, setAlisLoaded] = useState(false);
    const [alisData, setAlisData] = useState([]);
    // ALEXA INTEGRATION VARIABLES
    const [alexaLoaded, setAlexaLoaded] = useState(false);
    const [alexaData, setAlexaData] = useState([]);
    // ACCU-TECH INTEGRATION VARIABLES
    const [accutechLoaded, setAccutechLoaded] = useState(false);
    const [accutechData, setAccutechData] = useState([]);
    // ACCU-TECH INTEGRATION VARIABLES
    const [pccLoaded, setPCCLoaded] = useState(false);
    const [pccData, setPCCData] = useState([]);
    // ECP INTEGRATION VARIABLES
    const [ecpLoaded, setECPLoaded] = useState(false);
    const [ecpData, setECPData] = useState([]);
    // ELDERMARK INTEGRATION VARIABLES
    const [elderMarkLoaded, setElderMarkLoaded] = useState(false);
    const [elderMarkData, setElderMarkData] = useState([]);


    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0) {
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: parseInt(dData.acnt), Tbl: "core_tels",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setTelsData(data ? data : []);
                setTelsLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "event_category_id", pVal: 2,
                                Tbl: "att_event_types", procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setMEventTypeData(data ? data : []);
                setMEventTypeLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: parseInt(dData.acnt),
                                Tbl: "core_tels_wo", procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setTelsWoData(data ? data : []);
                setTelsWoLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: parseInt(dData.acnt),
                                Tbl: "core_alis", procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setAlisData(data ? data : []);
                setAlisLoaded(true);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: parseInt(dData.acnt),
                                Tbl: "core_alexa_config", procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setAlexaData(data ? data : []);
                setAlexaLoaded(true);
            });
            setAccutechLoaded(true);
            setPCCLoaded(true);
            setECPLoaded(true);
            setElderMarkLoaded(true);
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    const tabNavigation = (
        <div className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString()
            ? "row align-items-center sticky-table-headX"
            : "row align-items-center sticky-table-head"}>
            <div className={"col"}>
                <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                    {loaded
                        ? <>
                            <li key={"set-alexa"} className={"nav-item"}>
                                <Button text={"Alexa"} title={"Alexa Integration Settings"}
                                        class={loadState === "alexa" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("alexa")}/>
                            </li>
                            <li key={"set-alis"} className={"nav-item"}>
                                <Button text={"Alis"} title={"Alis Integration Settings"}
                                        class={loadState === "alis" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("alis")}/>
                            </li>
                            <li key={"set-tels"} className={"nav-item"}>
                                <Button text={"Tels"} title={"Tels Integration Settings"}
                                        class={loadState === "tels" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("tels")}/>
                            </li>
                            <li key={"set-accutech"} className={"nav-item"}>
                                <Button text={"Accu-Tech"} title={"Accu-Tech Settings"}
                                        class={loadState === "accutech" ? "nav-link active" : "nav-link"}
                                        onClick={() => {
                                            setLoadState("accutech");
                                        }}/>
                            </li>
                            <li key={"set-pcc"} className={"nav-item"}>
                                <Button text={"Point Click Care"} title={"Point Click Care Settings"}
                                        class={loadState === "pcc" ? "nav-link active" : "nav-link"}
                                        onClick={() => {
                                            setLoadState("pcc");
                                        }}/>
                            </li>
                            <li key={"set-ecp"} className={"nav-item"}>
                                <Button text={"ECP"} title={"Extended Care Professional Settings"}
                                        class={loadState === "ecp" ? "nav-link active" : "nav-link"}
                                        onClick={() => {
                                            setLoadState("ecp");
                                        }}/>
                            </li>
                            <li key={"set-elder"} className={"nav-item"}>
                                <Button text={"Eldermark"} title={"Eldermark Settings"}
                                        class={loadState === "elder" ? "nav-link active" : "nav-link"}
                                        onClick={() => {
                                            setLoadState("elder");
                                        }}/>
                            </li>
                        </>
                        : <></>}
                </ul>
            </div>
        </div>);


    useEffect(() => {
        if (loaded) {
            if (loadState === "tels") {
                setPageContent(<Tels originData={telsData} eventTypeData={mEventTypeData} woData={telsWoData}
                                     setCount={(item) => setCount(item)}/>);
            } else if (loadState === "alis") {
                setPageContent(<Alis originData={alisData} setCount={(item) => setCount(item)}/>);
            } else if (loadState === "alexa") {
                setPageContent(<Alexa originData={alexaData} setCount={(item) => setCount(item)}/>);
            }else if (loadState === "accutech"){
                setPageContent(<AccuTech originData={accutechData} setCount={(item) => setCount(item)}/>);
            }else if (loadState === "pcc"){
                setPageContent(<PCC originData={[]} setCount={(item) => setCount(item)}/>);
            }else if (loadState === "ecp"){
                setPageContent(<ECP originData={[]} setCount={(item) => setCount(item)}/>);
            }else if (loadState === "elder"){
                setPageContent(<Eldermark originData={[]} setCount={(item) => setCount(item)}/>);
            }else{
                setPageContent(<></>);
            }
        }else{
            setPageContent(<p>Loading integrations. . .</p>);
        }
    }, [loadState, loaded]);


    useEffect(() => {
        if (![telsLoaded, alisLoaded, telsWoLoaded, mEventTypeLoaded, alexaLoaded, accutechLoaded, pccLoaded, elderMarkLoaded, ecpLoaded].includes(false)){
            setLoaded(true);
        }
    }, [telsLoaded, alisLoaded, telsWoLoaded, mEventTypeLoaded, alexaLoaded, accutechLoaded, pccLoaded, elderMarkLoaded, ecpLoaded]);

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)}
                            setCount={(item) => setCount(item)} pageAction={() => {
                setLoaded(false);
                setTelsLoaded(false);
                setTelsWoLoaded(false);
                setAlisLoaded(false);
                setAlexaLoaded(false);
                setAccutechLoaded(false);
                setECPLoaded(false);
                setElderMarkLoaded(false);
                setCount(0);
            }} />
        </div>
    )

    return (
        <div className={"main-content"}>
            <Header preTitle={"Settings"} Title={"Integrations"} content={headerContent}/>
            <div className={"container-fluid"}>
                {tabNavigation}
                {pageContent}
                <div className={"footer-spacer"}>
                </div>
            </div>
            <Footer />
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </div>
    );
};

export default IntegrationSettings;