import { ReactSession } from 'react-client-session';
import {Navigate} from "react-router-dom";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import Button from "./component-assets/Button";
import {Suspense, useEffect, useState} from "react";
import { toast, ToastContainer } from "react-toastify";
import {v4 as uuidv4} from "uuid"
import {
    durationToNow, getLocalTimeWithOffset, getLuxonUTCTime, convertIsoToLocalTime,
    getUTCTimestamp, handleSubmitActions, sortFunc, formCheck, convertHHMMtoSec
} from "../libs";
import ApiRequester from "./ApiRequester";
import Footer from "./Footer";
import CampusDropDown from "./component-assets/CampusDropDown";
import TableHeaders from "./component-assets/TableHeaders";
import TableBody from "./component-assets/TableBody";

function DemoPanelCloud(props) {
    let dData, bData, headerData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    headerData = [
        {label: "Event Start", key: "event_datetime_utc", align: "center", type: "datetime",
            border: {enabled: false, borderType: "left", borderColorKey: "event_category", borderWidth: "6px"}},
        {label: "Duration", key: "event_datetime_utc", align: "center", type: "duration"},
        {label: "Alert Category", key: "event_category", align: "center"},
        {label: "Alert Type", key: "event_type", align: "center"},
        {label: "Device / Desc", key: "device_name", align: "center",
            link: {enabled: false, type: "conditional", conditionKey: "manufacture_id",
                condition: {
                    1: {linkto: "/profiles/inovonics", linkkey: "device_id"},
                    5: {linkto: "/profiles/alexa", linkkey: "device_id"}
                }}},
        {label: "Description", key: "device_description", align: "left"},
        {label: "Area", key: "area_name", align: "center",
            link: {enabled: true, type: "standard", linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "Resident", key: "resident_name", align: "left",
            link: {enabled: true, type: "standard", linkto: "/profiles/resident", linkkey: "resident_id"}},
        {label: "Accepted By / Time", key: "accepted_display", align: "left"},
        {label: "Device Cleared", key: "ack_datetime_utc", align: "left"},
        {label: "Action", key: "event_id", align: "center", type: "button",
            button: {clickFunc: clearAlarm, text: "Clear", className: "btn btn-primary",
                    id: "clearSubmit"}}
    ];

    const [campusId, setCampusId] = useState(dData.acnt);
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [server, setServer] = useState(0);
    const [serverData, setServerData] = useState([]);
    const [serverOptions, setServerOptions] = useState([]);
    const [linkType, setLinkType] = useState("");
    const [deviceOptions, setDeviceOptions] = useState([]);
    const [entityOptions, setEntityOptions] = useState([]);
    const [residentData, setResidentData] = useState([]);
    const [areaData, setAreaData] = useState([]);
    const [simulateData, setSimulateData] = useState([]);
    const [eventCatId, setEventCatId] = useState(1);
    const [eventCatName, setEventCatName] = useState("Emergency");
    const [eventCatData, setEventCatData] = useState([]);
    const [eventCatOptions, setEventCatOptions] = useState([]);
    const [eventTypeId, setEventTypeId] = useState(1);
    const [eventTypeName, setEventTypeName] = useState("Emergency");
    const [eventTypeData, setEventTypeData] = useState([]);
    const [eventTypeOptions, setEventTypeOptions] = useState([]);
    const [count, setCount] = useState(0);
    const [alertCount, setAlertCount] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [sorter, setSorter] = useState({
        sortColumn: "event_datetime_utc",
        sortOrder: "asc"
    });
    const [resSorter, setResSorter] = useState({
        sortColumn: "resident_first_name",
        sortOrder: "asc"
    });
    const [areaSorter, setAreaSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "asc"
    });

    useEffect(() => {
        let interval;
        interval = null;
        interval = setInterval(() => {
             setAlertCount(count => count - 1);
        }, 1000);
        if (alertCount <= 0){
            ApiRequester({reqEndpoint: "get-alerts-cloud", CampusId: dData.acnt, ToGet: props?.toFetch,
                                UserId: dData.user}).then(data => {
                    setDataOrigin(data ? data : []);
            })
            setAlertCount((count) => 7);
        }
        return () => clearInterval(interval);
    }, [alertCount]);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getallresidentsanddevicesbycampusid", reqType: "stored"}).then(data => {
                setResidentData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getallareadevicesbycampusid", reqType: "stored"}).then(data3 => {
                setAreaData(data3);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_event_categories",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEventCatData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_event_types",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setEventTypeData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "core_palcare_devices",
                                procedure: "fetchbyparameter", reqType: "direct"}).then(data => {
                setServerData(data);
            });
            setCount(count => 1200);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData;
        tmpData = [];
        for (let i=0; i<dataOrigin.length; i++){
                tmpData.push(dataOrigin[i]);
        }
        setActiveData(tmpData);
    }, [dataOrigin]);

    useEffect(() => {
        let tmp = [];
        for (let i=0; i < activeData?.length; i++){
            activeData[i].elapsed = durationToNow(activeData[i].event_date_time);
            tmp.push(activeData[i]);
        }
        if (tmp?.length > 0) {
            tmp.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={0} end={tmp?.length ? tmp.length : 0} tableData={tmp}
                                checkbox={false} writeAccess={false} unk={"a-"}
                                actionAppearance={() => {}} tableHeaders={headerData} editModal={() => {}}
                                parentComponent={"Alerts"} dData={dData} checkboxlist={[]} setCheckboxId={() => {}}
                                checkidkey={"event_id"} />);
    }, [activeData, sorter, count]);

    useEffect(() => {
        let device, submitBtn, simulateBtn;
        setEntityOptions(() => []);
        setDeviceOptions(() => []);
        document.getElementById("datatype").value = "";
        document.getElementById("performRounds").classList.add("d-none");
        document.getElementById("performCheckin").classList.add("d-none");
        device = document.getElementById("device");
        submitBtn = document.getElementById("performSubmit");
        simulateBtn = document.getElementById("performSimulate");
        submitBtn.disabled = !(device && device.value !== "");
        simulateBtn.disabled = !(device && device.value !== "");
    }, [campusId]);

    useEffect(() => {
        let tmp = [];
        for (let i=0; i < serverData.length; i++){
            if (serverData[i].palcare_interface_id === 3){
                tmp.push(
                    <option key={`server-option${i}`} value={serverData[i].palcare_device_id}>
                        {serverData[i].description} ({serverData[i].mac_addr})
                    </option>
                );
            }
        }
        if (tmp.length <= 0){
            setServer(() => "");
            tmp.push(
                <option key={"server-option-null"} value={0}>No available servers</option>
            );
        }
        setServerOptions(tmp);
    }, [serverData])

    useEffect(() => {
        if (simulateData && simulateData?.length > 0){
            document.getElementById("simulate-table-div").classList.remove("d-none");
        }else{
            document.getElementById("simulate-table-div").classList.add("d-none");
        }
    }, [simulateData]);

    useEffect(() => {
        let tmp = [];
        if (eventCatData?.length > 0){
            eventCatData.sort((a, b) => sortFunc(a, b, "event_category_name", "asc"));
            for (let i=0; i < eventCatData.length; i++){
                tmp.push(
                    <option key={`escalation-cat-option${i}`} value={eventCatData[i]?.event_category_id}>
                        {eventCatData[i]?.event_category_name}
                    </option>);
            }
            setEventCatOptions(tmp);
        }
    }, [eventCatData]);

    useEffect(() => {
        let tmp = [];
        if (eventTypeData?.length > 0){
            eventTypeData.sort((a, b) => sortFunc(a, b, "event_type_name", "asc"));
            for (let i=0; i < eventTypeData.length; i++){
                if (eventTypeData[i].event_category_id === eventCatId){
                    tmp.push(
                        <option key={`event-type-option${i}`} value={eventTypeData[i]?.event_type_id}>
                            {eventTypeData[i]?.event_type_name}
                        </option>
                    );
                }
            }
            setEventTypeOptions(tmp);
        }
    }, [eventTypeData, eventCatId]);

    function buildEntityOne(){
        let tmpA = [], tmpB = [], dataType
        dataType = document.getElementById("datatype").value;
        setDeviceOptions(() => []);
        if (dataType === "resident"){
            setLinkType(() => "Resident");
            residentData.sort((a, b) => sortFunc(a, b, resSorter.sortColumn, resSorter.sortOrder));
            for (let i = 0; i < residentData.length; i++){
                if (residentData[i]?.devices?.length > 0){
                    tmpA.push(
                        <option key={`resident-${i}-option`} value={i.toString()}>
                            {residentData[i].resident_first_name} {residentData[i].resident_last_name}
                        </option>
                    );
                }else{
                    tmpB.push(
                        <option key={`resident-${i}-option`} value={i.toString()} disabled={true}>
                            {residentData[i].resident_first_name} {residentData[i].resident_last_name} (NO DEVICES)
                        </option>);
                }
            }
        }else{
            setLinkType(() => "Area")
            areaData.sort((a, b) => sortFunc(a, b, areaSorter.sortColumn, areaSorter.sortOrder));
            for (let i = 0; i < areaData.length; i++){
                if (areaData[i]?.devices?.length > 0){
                    tmpA.push(<option key={"area-"+i+"-option"} value={i.toString()}>{areaData[i].area_name}</option>)
                }else{
                    tmpB.push(<option key={"area-"+i+"-option"} value={i.toString()} disabled={true}>{areaData[i].area_name} (NO DEVICES)</option>)
                }
            }
        }
        tmpA = tmpA.concat(tmpB)
        setEntityOptions(() => tmpA)
    }

    function buildEntityTwo(){
        let tmp = [], dataType, entityIndex;
        dataType = document.getElementById("datatype").value;
        entityIndex = document.getElementById("dataindex").value;
        if (dataType === "resident"){
            residentData[parseInt(entityIndex)]?.devices.sort((a, b) => sortFunc(a, b, "description", "asc"));
            for (let i = 0; i < residentData[parseInt(entityIndex)]?.devices?.length; i++){
                tmp.push(
                    <option key={`resident-${i}-option`} value={i.toString()}>
                        {residentData[parseInt(entityIndex)].devices[i]?.description} ({residentData[parseInt(entityIndex)].devices[i]?.manufacture_uuid})
                    </option>
                );
            }
        }else{
            areaData[parseInt(entityIndex)]?.devices.sort((a, b) => sortFunc(a, b, "description", "asc"));
            for (let i = 0; i < areaData[parseInt(entityIndex)]?.devices?.length; i++){
                tmp.push(
                    <option key={`area-${i}-option`} value={i.toString()}>
                        {areaData[parseInt(entityIndex)].devices[i]?.description} ({areaData[parseInt(entityIndex)].devices[i]?.manufacture_uuid})
                    </option>
                );
            }
        }
        setDeviceOptions(() => tmp);
    }

    function deviceSet(deviceName){
        let device = document.getElementById("device");
        let submitBtn = document.getElementById("performSubmit");
        let simulateBtn = document.getElementById("performSimulate");
        if (device && deviceName && deviceName.toLowerCase().search("aio") !== -1){
            document.getElementById("performRounds").classList.remove("d-none");
            document.getElementById("performCheckin").classList.remove("d-none");
        }else{
            document.getElementById("performRounds").classList.add("d-none");
            document.getElementById("performCheckin").classList.add("d-none");
        }
        submitBtn.disabled = !(device && device.value !== "");
        simulateBtn.disabled = !(device && device.value !== "");
    }

    // Alert Sending Functions

    function alertSubmit(){
        let payload, device, dataType, entityIndex, deviceIndex;
        handleSubmitActions("performSubmit", "loadPerformSubmit");
        dataType = document.getElementById("datatype").value;
        entityIndex = document.getElementById("dataindex").value;
        deviceIndex = document.getElementById("device").value;
        if (dataType === "resident"){
            let resident = residentData[parseInt(entityIndex)];
            device = residentData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
            payload = getV4(dataType, resident, "", device, server, "Emergency");
        }else{
            let area = areaData[parseInt(entityIndex)];
            device = areaData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
            payload = getV4(dataType, "", area, device, server, "Emergency");
        }
        ApiRequester({reqEndpoint: "v4Send", Method: "POST", Payload: payload, Action: "create"}).then(data => {
            if (data[0]) {
                toast.success(`Alert created for ${device?.device_name}`);
                setAlertCount(() => 0);
            } else {
                toast.error(`Failed to create alarm for ${device?.device_name}`);
            }
            handleSubmitActions("loadPerformSubmit", "performSubmit");
        });
    }

    function roundsSubmit(){
        let entityIndex, deviceIndex, area, device, payload, v2Data;
        handleSubmitActions("performRounds", "loadPerformRounds");
        entityIndex = document.getElementById("dataindex").value;
        deviceIndex = document.getElementById("device").value;
        area = areaData[parseInt(entityIndex)];
        device = areaData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
        v2Data = getV4("area", "", area, device, server, "");
        payload = [{device_id: device.device_id, resident_id: v2Data?.residents[0]?.id, area_id: v2Data?.location?.id, campus_id: campusId,
                    local_create_time: getLocalTimeWithOffset(dData?.campusData[campusId.toString()]?.tz_offset),
                    create_time: getLuxonUTCTime()}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_rounds", Payload: payload, urlType: "PUT", User: dData.user,
                            PKey: "rounds_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if (data[0]) {
                toast.success(`Rounds triggered with ${device?.device_name} in room ${area?.area_name}`);
            } else {
                toast.error(`Failed to create rounds with ${device?.device_name} in room ${area?.area_name}`);
            }
            handleSubmitActions("loadPerformRounds", "performRounds");
        });
    }

    function checkinSubmit(){
        let entityIndex, deviceIndex, area, device, payload, v4Data;
        handleSubmitActions("performCheckin", "loadPerformCheckin");
        entityIndex = document.getElementById("dataindex").value;
        deviceIndex = document.getElementById("device").value;
        area = areaData[parseInt(entityIndex)];
        device = areaData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
        v4Data = getV4("area", "", area, device, server, "");
        payload = [{device_id: device.device_id, resident_id: v4Data?.residents[0]?.id, area_id: v4Data?.location?.id,
                    campus_id: campusId, local_create_time: getLocalTimeWithOffset(dData?.campusData[campusId.toString()]?.tz_offset),
                    create_time: getLuxonUTCTime()}];
        ApiRequester({reqEndpoint: "mysave", Tbl: "core_checkin", Payload: payload, urlType: "PUT", User: dData.user,
                            PKey: "checkin_id", Condition: "primary", CampusId: campusId, ClientId: dData.sessionId}).then(data => {
            if (data[0]) {
                toast.success(`${v4Data?.residents[0]?.resident_name} Checked in.`);
            } else {
                toast.error(`Failed to check-in ${v4Data?.residents[0]?.resident_name}`);
            }
            handleSubmitActions("loadPerformCheckin", "performCheckin");
        });
    }

    function getV4(datatype, resident, area, device, serverId, eType){
        let payload, areaName, areaId, timestamp, residents, fName = [], lName = [], fullName = [];

        timestamp = getUTCTimestamp();
        if (datatype === "resident"){
            residents = [{
                resident_id: resident?.resident_id,
                resident_first_name: resident?.resident_first_name,
                resident_last_name: resident?.resident_last_name,
                resident_name: `${resident?.resident_first_name} ${resident?.resident_last_name}`
            }];
            fName.push(resident?.resident_first_name);
            lName.push(resident?.resident_last_name);
            fullName.push(`${resident?.resident_first_name} ${resident?.resident_last_name}`);
            areaId = resident?.area_id;
            areaName = resident?.area_name;
        }else{
            areaId = area?.area_id;
            areaName = area?.area_name;
            residents = [];
            for (let i = 0; i < residentData.length; i++){
                if (residentData[i]?.area_id === area?.area_id){
                    residents.push({
                        resident_id: residentData[i]?.resident_id,
                        resident_first_name: residentData[i]?.resident_first_name,
                        resident_last_name: residentData[i]?.resident_last_name,
                        resident_name: `${residentData[i]?.resident_first_name} ${residentData[i]?.resident_last_name}`
                    });
                    fName.push(residentData[i]?.resident_first_name);
                    lName.push(residentData[i]?.resident_last_name);
                    fullName.push(`${residentData[i]?.resident_first_name} ${residentData[i]?.resident_last_name}`);
                }
            }
        }

        payload = {
            campus_id: campusId,
            event_id: uuidv4(),
            device_id: device?.device_id ? device.device_id : 0,
            device_name: device?.device_name ? device.device_name: "",
            device_description: device?.description ? device.description : "",
            device_use_id: device?.device_use_id ? device.device_use_id : 0,
            manufacturer_uuid: device?.manufacturer_uuid ? device.manufacturer_uuid : "",
            event_category_id: eventCatId,  // todo - hard code or keep, see below todos
            event_category: eventCatName,
            event_datetime_utc: timestamp,
            event_stage_id: 1,
            event_stage: "CREATE",
            event_type_id: 1,  // todo - revisit, may need to handle this
            event_type: "Emergency",  // todo - if revisit above, also handle
            palcare_device_id: 0,  // todo - connect this
            residents: residents,
            area_id: areaId,
            area_name: areaName,
            group_ids: [],  // todo - connect this
            source: "Portal Demo Panel",
            generated_by_id: 6,
            timezone_id: dData?.campusData[campusId.toString()]?.timezone_id,
            timezone_offset: dData?.campusData[campusId.toString()]?.tz_offset,
            version: "4.0",
            area_group_id: 0,  // todo - connect this
            area_group_name: "",  // todo - connect this
            resident_first_name: fName.join(","),
            resident_last_name: lName.join(","),
            resident_name: fullName.join(","),
            manufacture_id: 1,
            manufacture_name: "Inovonics",
            finish_by_id: 0,
            near_area_id: 0,
            near_area_name: "",
            presses: 1,
            vendor: "",
            vendor_id: 0,
            accepted_display: "",
            accepted_username: "",
            accepted_user_id: 0,
            arrived_datetime_utc: "",
            associated_event_id: "",
            finish_datetime_utc: "",
            ack_datetime_utc: "",
            reason_id: 0,
            notes: "",
            release_datetime_utc: ""
        };
        return payload;
    }

    function clearAlarm(alarmId, curIndex){
        handleSubmitActions("clearSubmit"+curIndex.toString(), "clearSubmitLoad"+curIndex.toString());
        let payload = {campus_id: campusId, event_id: alarmId, ack_datetime_utc: getUTCTimestamp(),
                       event_stage_id: 2, event_stage: "DONE", finish_by_id: 5}
        ApiRequester({reqEndpoint: "v4Send", Method: "POST", Payload: payload, Action: "clear"}).then(data => {
            if (data[0]){
                document.getElementById("clearSubmitLoad"+curIndex)?.classList?.add("d-none");
                handleSubmitActions("clearSubmitLoad"+curIndex.toString(), "clearSubmit"+curIndex.toString());
                toast.success(`Event cleared.`);
                setAlertCount(0);
            }
            else{
                handleSubmitActions("clearSubmitLoad"+curIndex.toString(), "clearSubmit"+curIndex.toString());
                toast.error(`Failed to clear event`);
            }
        });
    }

    function simulateSubmit(){
        let dataType, entityIndex, deviceIndex, device, durationtime, timeofday, timeItem, durationItem;
        handleSubmitActions("performSimulate", "loadPerformSimulate");
        dataType = document.getElementById("datatype").value;
        entityIndex = document.getElementById("dataindex").value;
        deviceIndex = document.getElementById("device").value;
        durationItem = document.getElementById("sim-end")?.value;
        durationtime = ![null, undefined].includes(durationItem) ? durationItem : 30
        timeItem = document.getElementById("sim-time")?.value;
        timeofday = ![null, undefined].includes(timeItem) ? convertHHMMtoSec(timeItem) : 0
        if (dataType === "resident"){
            device = residentData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
        }else{
            device = areaData[parseInt(entityIndex)]?.devices[parseInt(deviceIndex)];
        }
        ApiRequester({reqEndpoint: "simulate", campus_id: campusId, device_id: device?.device_id,
                            timeofday: timeofday, runtime: parseInt(durationtime),
                            event_category_id: parseInt(eventCatId)}).then(data => {
            setSimulateData(data ? data : []);
            handleSubmitActions("loadPerformSimulate", "performSimulate");
        });
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)}
                            setCount={(item) => {setCount(item); setAlertCount(item)}}
                            pageAction={() => {window.location.reload(true)}} />
        </div>
    )
    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Demo"} Title={"Demo Panel 4.0"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"w-50"} style={{maxWidth: "650px", minWidth: "500px"}}>
                            <div className={"d-inline-block w-50 pe-3"}>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={true} defaultValue={""}
                                            id={"datatype"}
                                            onChange={(e) => {
                                                buildEntityOne(e.target.value);
                                                document.getElementById("dataindex").value = "";
                                                document.getElementById("device").value = "";
                                                deviceSet("");
                                            }}>
                                        <option value={""} disabled={true}></option>
                                        <option value={"area"}>Area</option>
                                        <option value={"resident"}>Resident</option>
                                    </select>
                                    <label htmlFor={"datatype"}>Device Type</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={true} defaultValue={""}
                                            id={"dataindex"}
                                            onChange={(e) => {
                                                buildEntityTwo(e.target.value);
                                                document.getElementById("device").value = "";
                                                deviceSet("");
                                            }}>
                                        <option value={""} disabled={true}></option>
                                        {entityOptions}
                                    </select>
                                    <label htmlFor={"dataindex"}>{linkType}</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={true} id={"device"}
                                            defaultValue={""}
                                            onChange={(e) => {
                                                deviceSet(e.target.innerText)
                                            }}>
                                        <option value={""} disabled={true}></option>
                                        {deviceOptions}
                                    </select>
                                    <label htmlFor={"device"}>Device</label>
                                </div>
                            </div>
                            <div className={"d-inline-block w-50 ps-3"}>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={false} id={"serverId"}
                                            value={server}
                                            onChange={(e) => {
                                                setServer(e.target.value);
                                            }}>
                                        {serverOptions}
                                    </select>
                                    <label htmlFor={"serverId"}>Server</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={false} id={"eventCategoryId"}
                                            value={eventCatId}
                                            onChange={(e) => {
                                                setEventCatId(parseInt(e.target.value));
                                                setEventCatName(e.target.innerText);
                                            }}>
                                        {eventCatOptions}
                                    </select>
                                    <label htmlFor={"eventCategoryId"}>Event Category</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <select className={"form-select"} required={false} id={"eventTypeId"}
                                            value={eventTypeId}
                                            onChange={(e) => {
                                                setEventTypeId(parseInt(e.target.value));
                                                setEventTypeName(e.target[e.target.selectedIndex].innerText);
                                            }}>
                                        {eventTypeOptions}
                                    </select>
                                    <label htmlFor={"eventTypeId"}>Event Type</label>
                                </div>
                            </div>
                            <div className={"mb-3 d-block"}>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true} text={"Creating"} id={"loadPerformSubmit"}/>
                                <button type={"button"} className={"btn btn-danger ms-3"} id={"performSubmit"}
                                        onClick={() => alertSubmit()}>Create Alarm
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true} text={"Creating"} id={"loadPerformRounds"}/>
                                <button type={"button"} className={"btn btn-primary d-none ms-3"}
                                        id={"performRounds"}
                                        onClick={() => roundsSubmit()}>Rounds
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true}
                                        spinner={true} text={"Creating"} id={"loadPerformCheckin"}/>
                                <button type={"button"} className={"btn btn-primary d-none ms-3"}
                                        id={"performCheckin"}
                                        onClick={() => checkinSubmit()}>Check-In
                                </button>
                            </div>
                        </div>
                        <div className={"w-50"}>
                            <div className={"w-75 d-inline-block pe-2"}>
                                <div className={"mb-3 ps-4"}>
                                    <div className={"py-2 px-3"} style={{
                                        border: "1px inset lightgrey", overflowY: "auto",
                                        maxHeight: "350px", minHeight: "225px",
                                        backgroundColor: "#FFFFFF", borderRadius: "7px"
                                    }}>
                                        <div className={"text-center mb-2"}>
                                            <h4>Careflow Simulator Output</h4>
                                        </div>
                                        <table id={"simulate-table-div"} className={"d-none"}>
                                            <thead>
                                            <tr style={{borderBottom: "2px solid #d2ddec"}}>
                                                <th>Event Time</th>
                                                <th>Event Action</th>
                                                <th>To</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {simulateData.map((simRec) => {
                                                return (
                                                    <tr style={{borderBottom: "1px solid #d2ddec"}}>
                                                        <td className={"align-top"}
                                                            style={{minWidth: "100px"}}>{convertIsoToLocalTime(simRec?.timestamp)}</td>
                                                        <td className={"align-top"}
                                                            style={{minWidth: "175px"}}>{simRec?.handler}</td>
                                                        {typeof simRec?.to === "object"
                                                            ? <td className={"align-top"}
                                                                  style={{minWidth: "100px"}}>{simRec?.to.map((item, i) => {
                                                                if (i === 0) {
                                                                    return item
                                                                } else {
                                                                    return (<><br/>{item}</>)
                                                                }
                                                            })}</td>
                                                            : <td className={"align-top"}
                                                                  style={{minWidth: "100px"}}>{simRec?.to}</td>
                                                        }
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"w-25 d-inline-block ps-2 align-top"}>
                                <div className={"text-start mb-2 mt-1"}>
                                    <h4>Simulator Settings</h4>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"number"} className={"form-control"} step={1} min={1} max={60}
                                           defaultValue={30} required={true} id={"sim-end"}/>
                                    <label className={"fs-5"} htmlFor={"sim-end"}>Duration (Min)</label>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"time"} className={"form-control"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           id={"sim-time"}/>
                                    <label className={"fs-5"} htmlFor={"sim-time"}>Time of Day</label>
                                </div>
                                <div>
                                    <Button type={"button"} class={"btn btn-primary d-none"} disabled={true}
                                            spinner={true} text={"Calculating"} id={"loadPerformSimulate"}
                                            style={{width: "155px"}}/>
                                    <button type={"button"} className={"btn btn-primary"} id={"performSimulate"}
                                            style={{width: "155px"}}
                                            onClick={() => {
                                                setSimulateData([]);
                                                simulateSubmit();
                                            }}>Simulate Careflow
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row"}>
                        <table className={"table table-sm table-white table-hover"}>
                            <thead
                                className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                            </thead>
                            <tbody>
                            {tableRows}
                            </tbody>
                        </table>
                    </div>
                    <div className={"footer-spacer"}>
                    </div>
                </div>
                <Footer/>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true}
                                autoClose={10000}/>
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default DemoPanelCloud;