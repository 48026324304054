import {Link, Navigate} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import {ToastContainer } from "react-toastify";
import {CSVLink} from "react-csv";
import Header from "./Header";
import Button from "./component-assets/Button";
import {
    paginateData, sortFunc, handleSubmitActions, formSuccess, showModal
} from "../libs";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Footer from "./Footer";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";

const CampusLogs = (props) => {
    let dData, bData, headerData, csvHeaders;

    headerData = [
        {label: "Timestamp", key: "create_time", align: "center", type: "datetime"},
        {label: "Action", key: "action", align: "center"},
        {label: "From", key: "prev_value", align: "left"},
        {label: "To", key: "new_value", align: "left"},
        {label: "Source", key: "source", align: "center"},
        {label: "User", key: "display_name", align: "center"},
    ];

    csvHeaders = [
        {label: "Timestamp", key: "create_time"},
        {label: "Action", key: "action"},
        {label: "Source", key: "source"},
        {label: "Table", key: "table"},
        {label: "Username", key: "username"},
        {label: "User Display", key: "display_name"},
        {label: "Campus ID", key: "campus_id"},
    ];

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Main Table Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [count, setCount] = useState(0);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [resGroupIdList, setResGroupIdList] = useState([]);
    const [search, setSearch] = useState("");
    const [campusId, setCampusId] = useState(dData.acnt);
    const [editable, setEditable] = useState(false);
    const [sorter, setSorter] = useState({
        sortColumn: "create_time",
        sortOrder: "desc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "auditlogs.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                    class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                payload: {campus_id: campusId},
                                procedure: "getauditlogsbycampusid", reqType: "stored"}).then(data => {
                setDataOrigin(data ? data : []);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData?.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={false}
                                writeAccess={false} unk={"a-"} actionAppearance={() => {}}
                                tableHeaders={headerData} editModal={() => {}} parentComponent={"Logs"} dData={dData}
                                modal={false} checkboxlist={[]} setCheckboxId={() => {}} checkidkey={""} />);
    }, [activeData, sorter, page, perPage]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        let tmpGroups, input, pRules;
        tmpGroups = [];
        input = search.toString().toLowerCase();
        for (let i=0; i < dataOrigin?.length; i++){
            if ((dataOrigin[i]?.create_time && dataOrigin[i]?.create_time?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.source && dataOrigin[i]?.source?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.display_name && dataOrigin[i]?.display_name?.toLowerCase()?.search(input) !== -1)){
                tmpGroups.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpGroups, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpGroups);
    }, [search, dataOrigin]);

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        setResGroupIdList([]);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    const tableHeaders = <TableHeaders checkbox={false} headerData={headerData} checkAll={() => {}}
                                       writeAccess={false} sortOrder={sorter.sortOrder} modal_name={"Edit"}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter}
                                       modal={false} checkboxlist={[]} actionAppearance={() => {}}/>;

    const contentHeader = (
        <div className={"d-flex justify-content-between"}>
            <div>
                <Link to={`/profiles/campus/${campusId}`}>
                    <Button text={"Return to Campus"} type={"button"} class={"btn btn-primary mt-1 me-4"} />
                </Link>
            </div>
            <div style={{minWidth: "360px"}}>
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"} />
                </div>
                {csvButton}
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Audit Logs"} Title={"Campus Logs"} content={contentHeader} />
                <div className={"container-fluid"}>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer} />
            </div>
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage}
                          setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default CampusLogs;