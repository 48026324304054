import { Navigate } from 'react-router-dom';
import Navigation from "./Navigation";
import { ReactSession } from 'react-client-session';
import {Suspense, useEffect, useState} from "react";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import ApiRequester from "./ApiRequester";
import {
    getDurationFromFutureEpoch, showModal, getIsoStandard, formCheck, getCurrentEpochTimeInSeconds,
    formSuccess, formFail, handleSubmitActions, compareRole, iAmHigherAuthority
} from "../libs";
import CampusDropDown from "./component-assets/CampusDropDown";
import Button from "./component-assets/Button";
import {ToastContainer} from "react-toastify";
import SessionReload from "./component-assets/SessionReload";
import HeaderBanner from "./component-assets/HeaderBanner";

const EVM = (props) => {
    let dData, bData;

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    const [siteId, setSiteId] = useState(dData.evmSiteId.toString());
    const [siteDomain, setSiteDomain] = useState("https://visitor.centegix.com/");
    const [jwtToken, setJWT] = useState(undefined);
    const [srcMap, setSrcMap] = useState("");
    const [evm, setEvm] = useState(dData.evmSiteId.toString() !== "0");
    const [count, setCount] = useState(0);
    const [evmData, setEvmData] = useState([]);
    const [campusId, setCampusId] = useState(dData.acnt);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "jwt", headerValue: `Bearer ${process.env.REACT_APP_EVM_TOKEN}`,
                id: siteId, duration: 3600, url: `${siteDomain}api/token`}).then(data => {
                if (data?.token){
                    setJWT(data?.token ? data.token : "");
                    setCount(count => getDurationFromFutureEpoch(data?.expires, -60));
                }else{
                    setCount(() => 600);
                }
            });
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        if (jwtToken){
            setSrcMap(() => (<iframe style={{width: "100%", height: "1000px", maxHeight: "1000px"}}
                                     title={"EVM map"} className={"d-flex"}
                                     src={`${siteDomain}live/${siteId}/map?token=${jwtToken}`}></iframe>));

            ApiRequester({reqEndpoint: "centegix", method: "POST", site_domain: siteDomain, site_id: siteId,
                                start: getIsoStandard(-60), end: getIsoStandard(), token: jwtToken,
                                request_type: "get"}).then(data => {
                setEvmData(() => data ? data : []);
            });
        }
    }, [jwtToken]);

    useEffect(() => {
        setJWT(undefined)
        setSiteId(() => dData.evmSiteId)
        setCount(() => 0)
    }, [dData.evmSiteId]);

    function formAddSubmit(){
        let fname, lname, mac;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        fname = document.getElementById("fname").value;
        lname = document.getElementById("lname").value;
        mac = document.getElementById("macAddr").value;
        mac = mac.replaceAll(":", "").replaceAll("-", "").toUpperCase();
        ApiRequester({reqEndpoint: "centegix", method: "POST", site_domain: siteDomain, mac_addr: mac,
                            fname: fname, lname: lname, site_id: siteId, checkin: getIsoStandard(),
                            checkin_id: getCurrentEpochTimeInSeconds(), token: jwtToken, request_type: "add"}).then(data => {
            if (data[0]){
                formSuccess("Badge added successfully", "addSubmit", "loadAddSubmit",
                    "cancelAddBtn", "addForm");
            }else{
                formFail("Failed to add badge.", "addSubmit", "loadAddSubmit");
            }
        });
    }

    function formRemoveSubmit(){
        let mac;
        handleSubmitActions("deleteSubmit", "loadDeleteSubmit");
        mac = document.getElementById("macAddrE").value;
        mac = mac.replaceAll(":", "").replaceAll("-", "").toUpperCase();
        ApiRequester({reqEndpoint: "centegix", method: "POST", site_domain: siteDomain, mac_addr: mac,
                            site_id: siteId, checkin: getIsoStandard(), checkout: getIsoStandard(),
                            checkin_id: getCurrentEpochTimeInSeconds(), token: jwtToken, request_type: "remove"}).then(data => {
            if (data[0]){
                formSuccess("Badge removed successfully", "deleteSubmit", "loadDeleteSubmit",
                    "cancelDeleteBtn", "deleteForm");
            }else{
                formFail("Failed to add badge.", "deleteSubmit", "loadDeleteSubmit");
            }
        });
    }

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div>
                <Button text={"Remove Badges"} icon={true} iconClass={"fe fe-minus me-2"} class={"btn btn-danger me-3"} onClick={() => {showModal("removemodal")}} />
                <Button text={"Add Badge"} icon={true} iconClass={"fe fe-plus me-2"} class={"btn btn-primary me-3"} onClick={() => {showModal("addmodal")}} />
            </div>
            <CampusDropDown setCount={() => setCount} campusId={campusId} setCampusId={(item) => setCampusId(item)} pageAction={() => {

            }} />
        </div>
    );

    return (!compareRole("caregiver", dData) || iAmHigherAuthority(20, dData?.role_weight)) ? (
        <>
            <Navigation />
            {dData?.myVersion !== dData?.currentVersion && dData?.cloud ? <HeaderBanner /> : <></>}
            {dData?.migrated && <SessionReload />}
            <div className={"main-content"}>
                <Header preTitle={""} Title={"RTLS Map"} content={headerContent} />
                <div className={"container-fluid mt-4"}>
                    {evm
                        ? <div>{srcMap}</div>
                        : <></>
                    }
                </div>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Badge</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"macAddr"} onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^([0-9a-fA-F]{2}[:\\-]?){5}([0-9a-fA-F]{2})$"}/>
                                    <label htmlFor={"macAddr"}>Badge Identifier (Mac Address)</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter the unique badge mac address.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"fname"} onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[a-zA-Z ]+$"}/>
                                    <label htmlFor={"name"}>First Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a first name
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"lname"} onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[a-zA-Z ]+$"}/>
                                    <label htmlFor={"name"}>Last Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a last name
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} onClick={() => formAddSubmit()} id={"addSubmit"}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"removemodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Remove Badge</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"deleteForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"macAddrE"} onKeyUp={() => formCheck("deleteForm", "deleteSubmit")}
                                           required={true} pattern={"^([0-9a-fA-F]{2}[:\\-]?){5}([0-9a-fA-F]{2})$"}/>
                                    <label htmlFor={"macAddrE"}>Badge Identifier (Mac Address)</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter or scan the badge mac address.
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelDeleteBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true} text={"Loading"} id={"loadDeleteSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} onClick={() => formRemoveSubmit()} id={"deleteSubmit"}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default EVM;