import Header from "./Header";
import Footer from "./Footer";
import {Chart, registerables} from "chart.js";
import { MapContainer, TileLayer, CircleMarker, Popup } from "react-leaflet";
import {ReactSession} from "react-client-session";
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import annotationPlugin from 'chartjs-plugin-annotation';
import TableHeaders from "./component-assets/TableHeaders";
import {checkAll, formSuccess, handleSubmitActions, showModal, sortFunc} from "../libs";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import TableBody from "./component-assets/TableBody";
import {ToastContainer} from "react-toastify";

const CorpDash = () => {

    Chart.register(...registerables)
    Chart.register(annotationPlugin);

    let dData, headerData, areaTypeHeaders;

    headerData = [
        {label: "Community", key: "Campus_Name", align: "left"},
        {label: "Alarms/Day", key: "alarmsPerDay", align: "center"},
        {label: "Avg Response Time", key: "avgRespTime", align: "center"},
        {label: "% Over 20 min", key: "per20min", align: "center"},
    ]

    areaTypeHeaders = [
        {label: "Area Type", key: "area_type_name", align: "left"},
        {label: "Description", key: "area_type_description", align: "left"},
    ];

    if (ReactSession.get("PAL") !== undefined){
        let bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Data Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [corpId, setCorpId] = useState(dData.corp);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [hourDataLoaded, setHourDataLoaded] = useState(false);
    // Page General Variables
    const [activeList, setActiveList] = useState([]);
    const [hourData, setHourData] = useState([]);
    const [count, setCount] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);

    // Page Stat Variables
    const [itemId, setItemId] = useState(0);
    const [avgRT, setAvgRT] = useState("");
    const [avgST, setAvgST] = useState("");
    const [percRT, setPercRT] = useState("");
    const [alarmDay, setAlarmDay] = useState("");
    const [deviceCnt, setDeviceCnt] = useState("");
    const [resCnt, setResCnt] = useState("");
    const [totalAlarms, setTotalAlarms] = useState("");
    const [totalCampuses, setTotalCampuses] = useState("");
    const [markers, setMarkers] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "Campus_Name",
        sortOrder: "asc"
    });
    // Filter page variables
    const [areaTypeOriginData, setAreaTypeOriginData] = useState([]);
    const [areaTypeTableRows, setAreaTypeTableRows] = useState([]);
    const [areaTypeDataCnt, setAreaTypeDataCnt] = useState(0);
    const [areaTypeIdList, setAreaTypeIdList] = useState([]);
    const [areaTypeSearch, setAreaTypeSearch] = useState("");
    const [areaTypeSorter, setAreaTypeSorter] = useState({
        sortColumn: "description",
        sortOrder: "asc"
    });

    const [areaTypeFilterList, setAreaTypeFilterList] = useState([]);
    const [tabItem, setTabItem] = useState("area-type")
    const [filterCount,setFilterCount] = useState(0);

    const spinner = (
        <div className={"spinner-border"} role={"status"} style={{borderWidth: ".175em"}}>
        </div>
    )

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "corpdashboardstats",
                                corporation_id: corpId, area_type_filter: areaTypeFilterList.join(",")}).then(data => {
                setDataOrigin(data);
                setTotalCampuses(data?.length > 0 ? data.length - 1 : 0);
                setDataLoaded(true);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let campusId;
        if (itemId === 0){
            ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "corpdashboardhourchart",
                            corporation_id: corpId, area_type_filter: areaTypeFilterList.join(",")}).then(data => {
                setHourData(data !== undefined ? data : {});
                setHourDataLoaded(true);
            });
        }else{
            for (let i=0; i < activeData?.length; i++){
                if (activeData[i].item_id === itemId){
                    campusId = activeData[i].campus_id;
                    break
                }
            }
            if (campusId){
                ApiRequester({reqEndpoint: "get-mysql-report", rep_type: "dashboardhourchart",
                            campus_id: campusId, area_type_filter: areaTypeFilterList.join(",")}).then(data => {
                    setHourData(data !== undefined ? data : {});
                    setHourDataLoaded(true);
                });
            }
        }
    }, [itemId, activeData])

    useEffect(() => {
        let tmp;
        tmp = dataOrigin;
        if (tmp.length > 0){
            tmp.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setActiveData(tmp);
    }, [sorter, dataOrigin])

    useEffect(() => {
        // Builds the table with activeData QUAD 4
        let curIndex, tmp, tmpRows = [];
        // let pRules = paginateData(activeData, page, perPage);
        // setPRules(pRules);
        for (let i=0; i < activeData?.length; i++){
            curIndex = i.toString();
            if (itemId === activeData[i]?.item_id){
                tmp = [<td key={"tcheckbox"+curIndex}>
                        <div className={"form-check"}>
                            <input className={"form-check-input table-checkbox"} type={"radio"} value={""} checked={true}
                                   name={"uCheck"+curIndex} id={"uCheck"+curIndex}
                                                   onChange={() => {
                                        setItemId(() => activeData[i]?.item_id);
                                    }} style={{width: "20px", height: "20px"}} />
                            <label className={"form-check-label"} htmlFor={"uCheck"+curIndex}> </label>
                        </div>
                    </td>
                ];
            }else{
                tmp = [<td key={"tcheckbox"+curIndex}>
                        <div className={"form-check"}>
                            <input className={"form-check-input table-checkbox"} type={"radio"} value={""} checked={false}
                                   name={"uCheck"+curIndex} id={"uCheck"+curIndex}
                                   onChange={() => {
                                        setItemId(() => activeData[i]?.item_id);
                                    }} style={{width: "20px", height: "20px"}} />
                            <label className={"form-check-label"} htmlFor={"uCheck"+curIndex}> </label>
                        </div>
                    </td>
                ];
            }
            for (let i2=0; i2<headerData.length; i2++){
               tmp.push(
                    <td className={headerData[i2]?.align ? "px-1 text-"+headerData[i2]?.align : "px-1"}
                        id={headerData[i2].key+curIndex} key={headerData[i2].key+curIndex}>
                        {activeData[i][headerData[i2].key]}
                    </td>
                );
            }
            if (itemId === activeData[i]?.item_id){
                tmpRows.push(
                    <tr className={"clickable"} key={"tableRow"+curIndex} id={"tableRow"+curIndex} onClick={() => {
                        setItemId(() => activeData[i]?.item_id);
                    }}>
                        {tmp}
                    </tr>
                );
            }else {
                tmpRows.push(
                    <tr className={"clickable"} key={"tableRow"+curIndex} id={"tableRow"+curIndex} onClick={() => {
                        setItemId(() => activeData[i]?.item_id);
                    }}>
                        {tmp}
                    </tr>
                );
            }
        }
        setTableRows(tmpRows);
    }, [activeData, page, sorter, perPage, itemId]);

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setFilterCount(filterCount => filterCount + 1);
        }, 1000);
        if (filterCount <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt, Tbl: "att_area_type",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setAreaTypeOriginData(data);
            });
            setFilterCount(filterCount => 600);
        }
        return () => clearInterval(interval);
    }, [filterCount]);

    useEffect(() => {
        let i, tmp = [], input;
        input = areaTypeSearch?.toString()?.toLowerCase();
        for (i = 0; i < areaTypeOriginData?.length; i++){
            if ((areaTypeOriginData[i]?.area_type_name && areaTypeOriginData[i]?.area_type_name?.toLowerCase()?.search(input) !== -1) ||
                (areaTypeOriginData[i]?.area_type_description && areaTypeOriginData[i]?.area_type_description?.toLowerCase()?.search(input) !== -1)){
                tmp.push(areaTypeOriginData[i]);
            }
        }
        setAreaTypeDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, areaTypeSorter.sortColumn, areaTypeSorter.sortOrder));
        setAreaTypeTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                        actionAppearance={() => {}} tableHeaders={areaTypeHeaders} checkboxId={"uCheckAreaType"}
                                        parentComponent={"EventReports"} dData={dData} checkboxlist={areaTypeIdList}
                                        modal={false} editModal={() => {}} unk={"area-type-x-"}
                                        setCheckboxId={(value) => {setAreaTypeIdList(value)}} checkidkey={"area_type_id"} />)
    }, [areaTypeOriginData, areaTypeSorter, areaTypeIdList, areaTypeSearch])

    useEffect(() => {
        // Builds the corporate map based on activeData QUAD 1
        let tmp = [], color, cRadius, avgResp = 0.0, avgST = 0.0, avgPerc = 0.0, tmpAlarmDay = 0.0, totalDevices = 0, totalResidents = 0, totalAlarms = 0;
        cRadius = 15
        if (dataLoaded){
            for (let i=0; i < activeData.length; i++){
                if (itemId === activeData[i]?.item_id){
                    avgResp = avgResp + activeData[i]?.avgRespTime;
                    avgST = avgST + activeData[i]?.avgServTime;
                    avgPerc = avgPerc + activeData[i]?.per20min;
                    tmpAlarmDay = tmpAlarmDay + activeData[i]?.alarmsPerDay;
                    totalAlarms = totalAlarms + activeData[i]?.totalAlarms;
                    totalDevices = totalDevices + activeData[i]?.totalDevices;
                    totalResidents = totalResidents + activeData[i]?.totalResidents;
                    if (activeData[i]?.avgRespTime > 20.0){
                        color = {color: "#BE4D25"}
                    }else{
                        color = {color: "#0081C7"}
                    }
                }
                if (itemId === activeData[i]?.item_id || itemId === 0){
                    if (activeData[i]?.item_id !== 0){
                        tmp.push(
                            <CircleMarker
                                eventHandlers={{mouseover: (event) => event.target.openPopup(),
                                                mouseout: (event) => event.target.closePopup()}}
                                center={[activeData[i]?.Campus_Latitude ? activeData[i].Campus_Latitude : 0, activeData[i]?.Campus_Longitude ? activeData[i].Campus_Longitude : 0]} pathOptions={color} radius={cRadius} key={"map-marker-"+i.toString()}>
                                <Popup className={"fs-4"}>{activeData[i]?.Campus_Name}<br />{activeData[i]?.totalAlarms} Alarms</Popup>
                            </CircleMarker>
                        )
                    }
                }
            }
            setMarkers(tmp);
            if (activeData.length > 0){
                avgResp = avgResp/1;
                avgST = avgST/1;
                avgPerc = avgPerc/1;
                tmpAlarmDay = tmpAlarmDay/1;
            }
            setAlarmDay(tmpAlarmDay?.toFixed(1) ? tmpAlarmDay.toFixed(1) : 0);
            setAvgRT(avgResp?.toFixed(1) ? avgResp.toFixed(1) : 0.0);
            setAvgST(avgST?.toFixed(1) ? avgST.toFixed(1) : 0.0);
            setPercRT(avgPerc?.toFixed(1) ? avgPerc.toFixed(1) : 0.0);
            setTotalAlarms(totalAlarms);
            setResCnt(totalResidents);
            setDeviceCnt(totalDevices);
        }
    }, [activeData, itemId])

    useEffect(() => {
        if (hourData !== undefined && hourDataLoaded){
            if (Chart.getChart("perhour") !== undefined){
                const curChart = Chart.getChart("perhour");
                curChart.data.labels = hourData?.labels ? hourData.labels : [];
                curChart.data.datasets[0].data = hourData?.events ? hourData.events : [];
                curChart.data.datasets[1].data = hourData?.responses ? hourData.responses : [];
                curChart.options.plugins.annotation.annotations.line1.value = hourData?.avgValue ? hourData.avgValue : 0
                curChart.options.plugins.annotation.annotations.line2.value = hourData?.avgResp ? hourData.avgResp : 0;
                curChart.update();
            }
            else{
                new Chart('perhour', {
                    options: {
                        responsive: true,
                        plugins: {
                            annotation: {
                                drawTime: 'afterDatasetsDraw',
                                annotations: {
                                    line1: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "a",
                                        borderColor: "#0081C778",
                                        value: hourData?.avgValue ? hourData.avgValue : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#0081C7",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Alarm Average: ${hourData?.avgValue ? hourData.avgValue : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    },
                                    line2: {
                                        type: 'line',
                                        borderDash: [8, 4],
                                        borderWidth: 4,
                                        scaleID: "b",
                                        borderColor: "#BE4D2578",
                                        value: hourData?.avgResp ? hourData.avgResp : 0,
                                        label: {
                                            display: false,
                                            backgroundColor: "#BE4D25",
                                            borderColor: "#000000",
                                            borderWidth: 2,
                                            content: `Response Time Average: ${hourData?.avgResp ? hourData.avgResp : 0}`,
                                        },
                                        enter({element}, event) {
                                            element.label.options.display = true;
                                            return true;
                                        },
                                        leave({element}, event) {
                                            element.label.options.display = false;
                                            return true;
                                        }
                                    }
                                }
                            },
                            legend: {
                                display: true
                            },
                        },
                        scales: {
                            x: {
                                title: {
                                    display: true,
                                    text: "Time of the Day"
                                },
                                grid: {
                                    display: false
                                },
                            },
                            a: {
                                id: "a",
                                position: "left",
                                title: {
                                    display: true,
                                    text: "Total Alarms"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            },
                            b: {
                                id: "b",
                                position: "right",
                                title: {
                                    display: true,
                                    text: "Average Response Time"
                                },
                                grid: {
                                    display: false
                                },
                                suggestedMax: 10,
                                suggestedMin: 0,
                                ticks: {
                                    callback: function (value) {
                                        return value
                                    }
                                }
                            }
                        }
                    },
                    data: {
                        labels: hourData?.labels?.length > 0 ? hourData.labels : ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12",
                                "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"],
                        datasets: [{
                            type: "bar",
                            label: 'Total Alarms',
                            data: hourData?.events ? hourData.events : [],
                            backgroundColor: ["#0081C7"],
                            hoverBackgroundColor: ["#0081C778"],
                            yAxisID: "a",
                            order: 2
                        },{
                            type: "line",
                            label: "Average Response Time",
                            data: hourData?.responses ? hourData.responses : [],
                            backgroundColor: ["#BE4D25"],
                            hoverBackgroundColor: ["#BE4D2578"],
                            borderColor: ["#BE4D2578"],
                            fill: false,
                            yAxisID: "b",
                            order: 1
                        }]
                    }
                });
            }
        }
    }, [hourData]);

    function clearFilters(){
        // Function used in filter modal to clear all toggled checkboxes across all tabs.
        setAreaTypeIdList([]);
        document.getElementById("uCheckHead2").checked = false;
    }

    function showFilterModal(){
        // Function that preps and populates the filter modal with the checks when called based on stored session.
        setAreaTypeIdList(areaTypeFilterList);
        document.getElementById("uCheckHead2").checked = areaTypeFilterList.length === areaTypeOriginData.length;
        showModal("filtermodal2");
    }

    function filterSubmit2(){
        // Function used to set filters to vars on submit from filter modal.
        handleSubmitActions("filterSubmit2", "loadFilterSubmit2");
        setAreaTypeFilterList(areaTypeIdList);
        setCount(() => 0);
        formSuccess("Filters saved", "filterSubmit2", "loadFilterSubmit2", "cancelFilterBtn2");
    }

    function filtersOn(){
        return [areaTypeFilterList].some(filterArray => filterArray.length > 0);
    }

    const tableHeaders = <TableHeaders checkbox={false} modal={true} headerData={headerData} sortOrder={sorter?.sortOrder} checkAll={() => {}}
                                       actionAppearance={() => {}} writeAccess={dData?.access?.Users?.UserManagement?.Write}
                                       sortColumn={sorter?.sortColumn} sorter={sorter} setSorter={setSorter}/>

    const areaTypeTableHeaders = <TableHeaders checkbox={true} headerData={areaTypeHeaders} checkboxlist={areaTypeIdList}
                                               checkAll={() => checkAll(0, areaTypeDataCnt ? areaTypeDataCnt : 0, areaTypeIdList, setAreaTypeIdList, "uCheckHead2", "uCheckAreaType")}
                                               writeAccess={true} sortOrder={areaTypeSorter.sortOrder} checkboxHeaderId={"uCheckHead2"}
                                               sortColumn={areaTypeSorter.sortColumn} sorter={areaTypeSorter} setSorter={setAreaTypeSorter}
                                               actionAppearance={() => {}} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div className={"form-floating mx-4"} style={{minWidth: "200px"}}>
            </div>
            <div>
                <button type={"button"} className={"btn btn-secondary mx-2 mb-2"} style={{height: "46.5px"}}
                        onClick={() => {
                            showFilterModal();
                        }}>
                    <i className={"fe fe-filter fs-3"}/>
                    {filtersOn() && <div className={"filter-caret"}></div>}
                </button>
            </div>
        </div>
    )
    return (
        <div className={"main-content"}>
            <Header preTitle={"Dashboard"} Title={"Corporate"} content={headerContent}/>
            <div className={"container-fluid"}>
                <div className={"row mb-3"} style={{height: "45%"}}>
                    <div className={"col-12 col-lg-6 col-xl"}>
                        <MapContainer className={"rounded"} zoom={4} center={activeData.length > 0 ? [activeData[0]?.Campus_Latitude, activeData[0]?.Campus_Longitude] : [43.022098, -87.943538]} style={{zIndex: 1}}>
                            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                            {markers}
                        </MapContainer>
                    </div>
                    <div className={"col-12 col-lg-6 col-xl"}>
                        <div className={"card"} style={{width: "100%", height: "100%"}}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Community KPIs</h4>
                                <h5 className={"card-header-title"}>* All / selected communities</h5>
                            </div>
                            <div className={"card-body"}>
                                <div className={"row h-50"}>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{totalCampuses !== "" ? totalCampuses.toLocaleString() : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>Total Communities</h5>
                                    </div>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{resCnt !== "" ? resCnt.toLocaleString() : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>Total Residents</h5>
                                    </div>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{totalAlarms !== "" ? totalAlarms.toLocaleString() : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>Total Alarms</h5>
                                    </div>
                                </div>
                                <div className={"row h-50"}>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{alarmDay !== "" ? alarmDay.toLocaleString() : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>Avg Alarms/Day</h5>
                                    </div>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{avgRT !== "" ? avgRT : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>Avg Response Time (min)</h5>
                                    </div>
                                    <div className={"col-12 col-lg-4 col-xl"}>
                                        <h1 className={"mb-0 mt-4 text-center"}>{percRT !== "" ? percRT+"%" : spinner}</h1>
                                        <h5 className={"mt-1 text-center"}>20+ Min Responses</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12 col-lg-6 col-xl"}>
                        <div className={"card"} style={{width: "100%", height: "100%"}}>
                            <div className={"card-header"}>
                                <h4 className={"card-header-title"}>Alerts Per Day</h4>
                                <h5>* All communities totaled only</h5>
                            </div>
                            <div className={"card-body"}>
                                <div className={"chart-container"}>
                                    <canvas className={"chart-canvas"} id={"perhour"}> </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-6 col-xl"}>
                        <div className={"card"} style={{height: "100%"}}>
                            <h5 className={"pb-0 text-end"} style={{padding: "1rem"}}>* Select table rows to show selected only</h5>
                            <div className={"card-body py-0"} style={{maxHeight: "425px", overflowY: "auto", padding: "1rem"}}>
                                <table className={"table table-sm table-white"}>
                                    <thead>
                                        {tableHeaders}
                                    </thead>
                                    <tbody style={{maxHeight: "445px"}}>
                                        {tableRows}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"footer-spacer"}>

                </div>
            </div>
            <div className={"modal fade"} id={"filtermodal2"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Filter Settings</h2>
                            <Button text={"Clear All"} class={"btn ms-3 pb-0 hover-text-white"} onClick={() => clearFilters()} />
                            <button type={"button"} className={"btn-close"} id={"filterClose2"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"filterForm2"}>
                            <div className={"modal-body"}>
                                <div className={"row align-items-center"} style={{backgroundColor: "#f9fbfd"}}>
                                    <div className={"col"}>
                                        <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                                            <li className={"nav-item"}>
                                                <Button text={"Area Types"} class={tabItem === "area-type" ? "nav-link active" : "nav-link"}
                                                        onClick={() => setTabItem("area-type")} />
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className={"mt-4"}>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"areaTypeSearch"} className={"form-control search-input"}
                                               onKeyUp={(e) => setAreaTypeSearch(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"} />
                                    </div>
                                    <table className={"table table-sm table-white table-hover"}
                                           style={{maxHeight: "400px", overflowY: "auto", display: "block"}}>
                                        <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                            {areaTypeTableHeaders}
                                        </thead>
                                        <tbody>
                                            {areaTypeTableRows}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelFilterBtn2"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadFilterSubmit2"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"filterSubmit2"} onClick={() => filterSubmit2()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
            <Footer left={<h4 className={"mt-2 me-auto"}>* Stats based on 28 days, including only resident devices & alerts {"<"} 60 minutes</h4>} />
        </div>
    );
};

export default CorpDash;