import {Link, Navigate, useLocation} from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import { Suspense } from "react";
import {AES, enc} from "crypto-js";
import Header from "./Header";
import {useEffect, useState} from "react";
import ApiRequester from "./ApiRequester";
import Button from "./component-assets/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import {Modal} from 'bootstrap';
import {CSVLink} from "react-csv";
import {
    getAccessSettings, paginateData, sortFunc, boolSort, iAmHigherAuthority, swapModal,
    formFail, handleSubmitActions, actionAppearance, deleteModalSingle, roleWeightById,
    bulkDeleteModal, campusToGuid, formSuccess, showModal, roleWeightByName, checkAll
} from "../libs"
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Footer from "./Footer";
import TableHeaders from "./component-assets/TableHeaders";
import TableBody from "./component-assets/TableBody";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import SingleDeleteModal from "./component-assets/SingleDeleteModal";
import PerPageModal from "./component-assets/PerPageModal";
import CampusDropDown from "./component-assets/CampusDropDown";

const UserManagement = (props) => {

    let dData, bData, headerData, csvHeaders, headerData2;

    const useLocationData = useLocation();

    headerData = [
        {label: "Username", key: "username", align: "left",
            link: {enabled: true, linkto: "/profiles/user", linkkey: "user_id"}},
        {label: "Display Name", key: "display_name", align: "left"},
        {label: "Email", key: "email", align: "left"},
        {label: "Role", key: "user_role_name", align: "left"},
        {label: "Groups", key: "group_name", align: "left", type: "ellipsis"}
    ]
    csvHeaders = [
        {label: "Username", key: "username"},
        {label: "Display Name", key: "display_name"},
        {label: "Email", key: "email"},
        {label: "User Role", key: "user_role_name"},
        {label: "Group Ids", key: "group_ids"}
    ]

    headerData2 = [
        {label: "Group Name", key: "group_name", align: "left"},
        {label: "Pager Channel", key: "capcode", align: "left"},
    ];

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Main Table Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [loadState, setLoadState] = useState("individuals");
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [editable, setEditable] = useState(dData?.access?.Users?.UserManagement?.Write);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [stateData, setStateData] = useState(useLocationData.state);
    const [userIdList, setUserIdList] = useState([]);
    const [search, setSearch] = useState("");
    const [sorter, setSorter] = useState({
        sortColumn: "username",
        sortOrder: "asc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // User Role Variables
    const [roleData, setRoleData] = useState([]);
    const [roleDisplayOptions, setRoleDisplayOptions] = useState([]);
    const [roleOptions, setRoleOptions] = useState([]);
    // User Group Picker Variables
    const [groupData, setGroupData] = useState([]);
    const [groupIdList, setGroupIdList] = useState([]);
    const [oldGroupIdList, setOldGroupIdList] = useState([]);
    const [groupDataCnt, setGroupDataCnt] = useState([]);
    const [groupTableRows, setGroupTableRows] = useState([]);
    const [storedIndex, setStoredIndex] = useState(0);
    const [search2, setSearch2] = useState("");
    const [sorter2, setSorter2] = useState({
        sortColumn: "group_name",
        sortOrder: "asc"
    });
    // Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "UserManagement.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"}
                    class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>)

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
             setCount((count) => {return count - 1});
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getgroupsforusersbycampusid", reqType: "stored"}).then(data => {
                setDataOrigin(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId,
                                procedure: "sp_dev_getgroupsbycampusid", reqType: "stored"}).then(data => {
                setGroupData(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: campusId, Tbl: "att_user_roles",
                                procedure: "fetchall", reqType: "direct"}).then(data => {
                setRoleData(data);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            if (sorter.sortColumn === "LoggedIn"){
                activeData.sort((a, b) => boolSort(a, b, sorter.sortColumn, sorter.sortOrder));
            }else{
                activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
            }
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={true}
                                writeAccess={editable} unk={"a-"}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={editModal} parentComponent={"UserManagement"}
                                dData={dData} modal={true} checkboxlist={userIdList}
                                setCheckboxId={(value) => {setUserIdList(value)}} checkidkey={"user_id"} />);
    }, [activeData, sorter, page, perPage, dataOrigin, userIdList, groupData]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        let tmp = [], tmp2 = [];
        if (roleData){
            roleData.sort((a, b) => sortFunc(a, b, "user_role_weight", "asc"));
            for (let i=0; i < roleData.length; i++){
                if (iAmHigherAuthority(roleData[i]?.user_role_weight, dData?.role_weight)){
                    tmp.push(<option key={`role-option-${i}`} value={roleData[i]?.user_role_id}>{roleData[i]?.user_role_name}</option>);
                }
                tmp2.push(<option key={`role-display-option-${i}`} value={roleData[i]?.user_role_id}>{roleData[i]?.user_role_name}</option>);
            }
            setRoleOptions(tmp);
            setRoleDisplayOptions(tmp2);
        }
    }, [roleData]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        if (stateData && stateData?.message){
            if (stateData.message?.error){
                toast.error(stateData.message.error);
            }else if (stateData.message?.success){
                toast.success(stateData.message.success);
            }
        }
    }, [stateData]);

    useEffect(() => {
        // Performs realtime search of data as user types.
        let tmpUsers, i, input, pRules;
        tmpUsers = [];
        input = search.toString().toLowerCase();
        for (i=0; i<dataOrigin.length; i++){
            if ((dataOrigin[i]?.username && dataOrigin[i]?.username?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.display_name && dataOrigin[i]?.display_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.email && dataOrigin[i]?.email?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.user_role_name && dataOrigin[i]?.user_role_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.DeviceName && dataOrigin[i]?.DeviceName?.toLowerCase()?.search(input) !== -1)){
                tmpUsers.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpUsers, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpUsers);
    }, [search, dataOrigin]);

    useEffect(() => {
        let i, tmp = [], input;
        input = search2?.toString()?.toLowerCase();
        for (i = 0; i < groupData.length; i++){
            if ((groupData[i]?.group_name && groupData[i]?.group_name?.toLowerCase()?.search(input) !== -1) ||
                (groupData[i]?.capcode && groupData[i]?.capcode?.toLowerCase()?.search(input) !== -1)){
                tmp.push(groupData[i]);
            }
        }
        setGroupDataCnt(tmp.length);
        tmp.sort((a, b) => sortFunc(a, b, sorter2.sortColumn, sorter2.sortOrder));
        setGroupTableRows(<TableBody start={0} end={tmp.length} tableData={tmp} checkbox={true} writeAccess={true}
                                     actionAppearance={() => {}} tableHeaders={headerData2} checkboxId={"uCheckGroup"}
                                     parentComponent={"Groups"} dData={dData} checkboxlist={groupIdList}
                                     modal={false} editModal={() => {}} unk={"x-"}
                                     setCheckboxId={(value) => {setGroupIdList(value)}} checkidkey={"group_id"} />)
    }, [groupData, groupIdList, sorter2, search2, activeData]);

    function editModal(userIndex){
        let display, email, role, dataIndex, user, modal, delBtn, username, passBtn, submitBtn, cognitoId,
            rDisplay, roleDiv, rDisplayOption, gIdList = [];
        dataIndex = parseInt(userIndex);
        setSearch2("");
        setStoredIndex(dataIndex);
        document.getElementById("lastIndex").value = dataIndex;
        username = document.getElementById("usernameE");
        display = document.getElementById("displayE");
        email = document.getElementById("emailE");
        role = document.getElementById("userroleE");
        rDisplay = document.getElementById("roledisplaydivE");
        rDisplayOption = document.getElementById("roledisplayE");
        roleDiv = document.getElementById("roledivE");
        user = document.getElementById("userIdE");
        delBtn = document.getElementById("deleteSingleBtn");
        passBtn = document.getElementById("passBtn");
        submitBtn = document.getElementById("editSubmit");
        cognitoId = document.getElementById("cognitoE");
        email.required = ["palcare-admin", "palcare", "corporate", "corporate-admin", "admin"].includes(activeData[dataIndex]?.user_role);
        display.value = activeData[dataIndex]?.display_name;
        username.value = activeData[dataIndex]?.username;
        rDisplayOption.value = activeData[dataIndex]?.user_role_name;
        role.value = activeData[dataIndex]?.user_role_id;
        if (["1", "2"].includes(activeData[dataIndex]?.user_role_id?.toString())){
            document.getElementById("passwordR").pattern = "^(?!123456$)[ -~]{6,}$";
        }else{
            document.getElementById("passwordR").pattern = "[ -~]{6,}$";
        }
        email.value = activeData[dataIndex]?.email;
        user.value = activeData[dataIndex]?.user_id;
        cognitoId.value = activeData[dataIndex]?.cognito_id;
        document.getElementById("uCheckHead2").checked = false;
        for (let i2=0; i2<activeData[dataIndex]?.group_ids?.length; i2++){
            gIdList.push(activeData[dataIndex]?.group_ids[i2]?.group_id.toString());
        }
        setGroupIdList(gIdList);
        setOldGroupIdList(gIdList);
        document.getElementById("uCheckHead2").checked = gIdList && gIdList.length === groupData.length;
        delBtn.onclick = () => {
            setDContent([[user.value, cognitoId.value, role.value, username.value]]);
            deleteModalSingle(display.value, username.value);
        };
        if (dData.username === username.value){
            display.readOnly = !dData?.displayEdit;
            role.disabled = true;
            roleDiv.classList.add("d-none");
            rDisplay.classList.remove("d-none");
            email.readOnly = false;
            delBtn.classList.add("d-none");
            passBtn.classList.remove("d-none");
            submitBtn.classList.remove("d-none");
        }else{
            if (iAmHigherAuthority(roleWeightById(parseInt(role.value), roleData), dData?.role_weight)){
                delBtn.classList.remove("d-none");
                passBtn.classList.remove("d-none");
                submitBtn.classList.remove("d-none");
                display.readOnly = false;
                email.readOnly = false;
                role.disabled = false;
                roleDiv.classList.remove("d-none");
                rDisplay.classList.add("d-none");
            }else{
                delBtn.classList.add("d-none");
                passBtn.classList.add("d-none");
                submitBtn.classList.add("d-none");
                display.readOnly = true;
                email.readOnly = true;
                role.disabled = true;
                roleDiv.classList.add("d-none");
                rDisplay.classList.remove("d-none");
            }
        }
        formCheck("editForm", "editSubmit");
        modal = new Modal(document.getElementById("editmodal"), {});
        modal.show();
    }

    function formClear(formId, buttonId, addFormField, formAddSubmit, formEditSubmit){
        let formItem;
        formItem = document.getElementById(formId);
        if (formItem){
            formItem?.reset();
        }
        formCheck(formId, buttonId);
    }

    function formCheck(formId, buttonId){
        // Checks form for validation, toggles save button based on validation being met.
        let form = document.getElementById(formId).querySelectorAll("input");
        let formArray, selectItem;
        let btn = document.getElementById(buttonId);
        if (formId === "passForm"){
            let pass1 = document.getElementById("passwordR");
            document.getElementById("passwordCR").pattern = pass1.value;
        }
        if (formId === "addForm"){
            selectItem = document.getElementById("userrole");
        }
        else{
            selectItem = document.getElementById("userroleE");
        }
        formArray = Array.from(form);
        if (formArray.every((formItem) => {return formItem.checkValidity()}) && selectItem.checkValidity()){
            btn.disabled = false;
            btn.classList.remove("btn-danger");
            btn.classList.add("btn-primary");
        }
        else{
            btn.disabled = true;
            btn.classList.remove("btn-primary");
            btn.classList.add("btn-danger");
        }
    }

    function checkUserAll(start, end, dData={}, checkboxlist=[], setcheckboxlist=() => {}, headerField="uCheckHead", rowCheckId="uCheck"){
        // Checks and unchecks all boxes from header checkbox with user validation setting.
        let status, nxtCheck, nxtRole, entityList = [], nxtUsername;
        status = document.getElementById(headerField);
        if (status.checked){
            for (let i=start; i < end; i++){
                nxtCheck = document.getElementById(rowCheckId+i.toString());
                nxtRole = document.getElementById(`user_role_name${i}`);
                nxtUsername = document.getElementById(`username${i}`);
                if (nxtCheck){
                    if (nxtRole){
                        if (iAmHigherAuthority(roleWeightByName(nxtRole.innerText, roleData), dData?.role_weight) || dData.username === nxtUsername.innerText){
                            nxtCheck.checked = true;
                            entityList.push(nxtCheck.value.toString())
                        }
                    }else{
                        nxtCheck.checked = true;
                        entityList.push(nxtCheck.value.toString())
                    }
                }
            }
            setcheckboxlist(entityList);
        }else{
            for (let i=start; i < end; i++){
                nxtCheck = document.getElementById(rowCheckId+i.toString());
                if (nxtCheck){
                    nxtCheck.checked = false;
                }
            }
            setcheckboxlist([]);
        }
    }

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        setUserIdList([]);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit",
            "cancelFilterBtn");
        setCount(0)
    }

    function formAddSubmit(){
        let msg, username, password, email, display, role, payload, pAccess;
        handleSubmitActions("addSubmit", "loadAddSubmit");
        username = document.getElementById("username").value.toLowerCase();
        display = document.getElementById("display").value;
        email = document.getElementById("email").value;
        password = document.getElementById("password").value;
        role = document.getElementById("userrole").value;
        pAccess = getAccessSettings(parseInt(role));
        payload = [{Action: "new", DisplayName: display, UserEmail: email.toLowerCase(), UserRole: parseInt(role),
                    Migrated: true, CampusGuid: campusToGuid(dData, campusId), PortalAccess: pAccess,
                    GroupIds: groupIdList, Username: username, UserPass: password, CampusId: campusId, ClientId: dData.sessionId}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
                if (data[0] === true){
                    if (dData.username === username){
                        dData.email = email.toLowerCase()
                        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
                    }
                    setUserIdList([]);
                    formSuccess("User has been added.", "addSubmit", "loadAddSubmit", "cancelAddBtn", "addForm");
                    setCount(0);
                }
                else{
                    if (data[1] === "1"){
                        msg = "Username already exists, please change username.";
                    }
                    else if (data[1] === "2"){
                        msg = "Error, user created, failed saving user details. Contact your customer success manager for assistance.";
                    }
                    else if (data[1] === "8"){
                        msg = "Request aborted, missing required data. Contact your customer success manager for assistance.";
                    }
                    else if (data[1] === "9"){
                        msg = "Request aborted, no matching action. Contact your customer success manager for assistance.";
                    }
                    else{
                        msg = "Unknown failure, request not processed.";
                    }
                    formFail(msg, "addSubmit", "loadAddSubmit");
                }
        });
    }

    function formEditSubmit(){
        // User add form submit function
        let userId, email, pAccess, display, username, role, payload, cognitoId, lastIndex, oldRole, oldDisplay,
            oldEmail, oldPayload, oldUsername, oldCognito;
        lastIndex = document.getElementById("lastIndex").value;
        username = document.getElementById("usernameE").value;
        if (iAmHigherAuthority(activeData[lastIndex]?.user_role_weight, dData?.role_weight) || (dData.username === username)){
            handleSubmitActions("editSubmit", "loadEditSubmit");
            userId = document.getElementById("userIdE").value;
            if (dData.username !== username || (dData.username === username && dData.displayEdit)){
                display = document.getElementById("displayE").value;
            }else{
                display = dData.display;
            }
            if (dData.username === username){
                role = dData.role_id;
            }else{
                role = document.getElementById("userroleE").value;
            }
            email = document.getElementById("emailE").value;
            cognitoId = document.getElementById("cognitoE").value;
            pAccess = getAccessSettings(parseInt(role));
            oldRole = activeData[lastIndex]?.user_role_id;
            oldUsername = activeData[lastIndex]?.username;
            oldDisplay = activeData[lastIndex]?.display_name;
            oldCognito = activeData[lastIndex]?.cognito_id;
            oldEmail = activeData[lastIndex]?.email;
            payload = [{Action: "update", UserId: userId, DisplayName: display, UserEmail: email.toLowerCase(),
                        UserRole: parseInt(role), CampusGuid: campusToGuid(dData, campusId),
                        PortalAccess: pAccess, GroupIds: groupIdList, Username: username, CampusId: campusId,
                        Migrated: true, CognitoId: cognitoId, ClientId: dData.sessionId}];
            oldPayload = [{user_id: userId, display_name: oldDisplay, email: oldEmail, user_role_id: oldRole,
                           group_ids: oldGroupIdList, username: oldUsername, cognito_id: oldCognito}];
            ApiRequester({reqEndpoint: "user-manage", Payload: payload, OldPayload: oldPayload, User: dData.user}).then(data => {
                if (data[0]){
                    formSuccess("User updated.", "editSubmit", "loadEditSubmit", "cancelEditBtn");
                    setCount(0);
                    setUserIdList([]);
                    if (username === dData?.username){
                        dData.email = email.toLowerCase();
                        dData.display = display;
                        dData.groups = groupIdList;
                        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
                    }
                }
                else{
                    formFail("Error during save, error code: " + data[1], "editSubmit", "loadEditSubmit");
                }
            });
        }else{
            toast.warning("Permissions not valid for this action. See your administrator to make this change.")
        }
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let usernames, payload, i, denyList = [];
        handleSubmitActions(submitBtn, loadBtn);
        usernames = document.getElementById("uContent").value.split(",");
        payload = [];
        for (i=0; i < dContent.length; i++){
            if (iAmHigherAuthority(roleWeightById(parseInt(dContent[i][2]), roleData), dData?.role_weight)){
                payload.push({Action: "remove", UserId: dContent[i][0], Username: usernames[i], CampusId: campusId,
                            RequestedBy: dData?.username, Migrated: true, CognitoId: dContent[i][1], ClientId: dData.sessionId});
            }else{
                denyList.push(dContent[i][3]);
            }
        }
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
            if (data[0]){
                if (denyList.length > 0){
                    toast.warning("You do not have permissions to delete the following users: " + denyList.join(", "));
                }
                formSuccess("Users have been deleted.", submitBtn, loadBtn, closeId);
                setCount(0);
                setUserIdList([]);
                document.getElementById("uCheckHead").checked = false;
                checkUserAll(start, end);
                actionAppearance(start, end, ["deleteBtn", "groupsBtn"]);
            }
            else{
                formFail(`Error during delete, error code: ${data[1]}`, submitBtn, loadBtn);
            }
        });
    }

    function passSubmit(){
        let password, username, msg, payload;
        handleSubmitActions("passSubmit", "loadPassSubmit");
        username = document.getElementById("usernameE").value;
        password = document.getElementById("passwordR").value;
        payload = [{Action: "reset", CampusId: campusId, Username: username, UserPass: password, Migrated: true}];
        ApiRequester({reqEndpoint: "user-manage", Payload: payload}).then(data => {
            if (data[0] === true){
                formSuccess("Password reset successfully.", "passSubmit", "loadPassSubmit", "cancelPassBtn", "passForm");
                setCount(0);
            }
            else{
                if (data[1] === "3"){
                    msg = "Current password does not match records.";
                }
                else if (data[1] === "4"){
                    msg = "User not authorized.";
                }
                else if (data[1] === "5"){
                    msg = "Error encountered authenticating user. Please contact support for assistance if error persists.";
                }
                else if (data[1] === "6"){
                    msg = "New password failed to be set, check password and try again. Contact support if failure persists.";
                }
                else{
                    msg = "Unknown failure, request not processed.";
                }
                formFail(msg, "passSubmit", "loadPassSubmit");
            }
        });
    }
    const tableHeaders = <TableHeaders checkbox={true} headerData={headerData} checkAll={() => checkUserAll(ppRules.start, ppRules.end, dData, userIdList, setUserIdList)}
                                       writeAccess={dData?.access?.Users?.UserManagement?.Write} sortOrder={sorter.sortOrder}
                                       sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} modal={true}
                                       actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}
                                       checkboxlist={userIdList} modal_name={"Edit"}/>

    const groupTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxlist={groupIdList}
                                         checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, groupIdList, setGroupIdList, "uCheckHead2", "uCheckGroup")}
                                         writeAccess={true} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead2"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />
    // Must have separate header component per table so rendered header ID's do not conflict
    const groupAddTableHead = <TableHeaders checkbox={true} headerData={headerData2} checkboxlist={groupIdList}
                                         checkAll={() => checkAll(0, groupDataCnt ? groupDataCnt : 0, groupIdList, setGroupIdList, "uCheckHead3", "uCheckGroup")}
                                         writeAccess={true} sortOrder={sorter2.sortOrder} checkboxHeaderId={"uCheckHead3"}
                                         sortColumn={sorter2.sortColumn} sorter={sorter2} setSorter={setSorter2}
                                         actionAppearance={() => {}} />

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {editable && <Button text={"Delete"} type={"button"} class={"btn btn-danger ms-2 hide mt-1"}
                                     id={"deleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                     onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData,
                                         "display_name", ["user_id", "cognito_id", "user_role_id", "username"], "username", setDContent)} />
                }
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"} />
                </div>
                {csvButton}
                {editable && <Button text={"New User"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                     icon={true} iconClass={"fe fe-plus me-2"}
                                     onClick={() => {
                                         document.getElementById("uCheckHead3").checked = false;
                                         setSearch2("");
                                         setStoredIndex(undefined);
                                         setGroupIdList([]);
                                         showModal("addmodal");
                                         formCheck("addForm", "addSubmit");
                                     }} />
                }
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)} setCount={(item) => setCount(item)} pageAction={() => {}}/>
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (dData?.slauth === true && dData?.tKey != null && dData?.tKey !== "" && dData?.acnt != null) ? (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Community"} Title={"Users"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                        <li className={"nav-item"}>
                            <Link to={"/users/usermanagement"}>
                                <Button text={"Individuals"} class={loadState === "individuals" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("individuals")} />
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/users/groups"}>
                                <Button text={"Groups"} class={loadState === "groups" ? "nav-link active" : "nav-link"}
                                    onClick={() => setLoadState("groups")} />
                            </Link>
                        </li>
                    </ul>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                            {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                            {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add User</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"addForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"username"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"^[0-9a-zA-Z_@\\-]+"}/>
                                    <label htmlFor={"username"}>Username</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid username. No spaces allowed
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"display"}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"[a-zA-Z0-9 ]+"} />
                                    <label htmlFor={"display"}>Display Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a display name, standard alpha numeric only
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"email"}
                                           required={!["caregiver", "6"].includes(document.getElementById("userrole")?.value?.toString())}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"} />
                                    <label htmlFor={"email"}>Email</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid email!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"password"}
                                           onFocus={() => {
                                                if (["1", "2"].includes(document.getElementById("userrole").value)){
                                                    document.getElementById("password").pattern = "^(?!123456$)[ -~]{6,}$";
                                                }else{
                                                    document.getElementById("password").pattern = "[ -~]{6,}$";
                                                }
                                            }}
                                           onKeyUp={() => formCheck("addForm", "addSubmit")}
                                           required={true} pattern={"[ -~]{6,}$"} />
                                    <label htmlFor={"password"}>Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <div className={"form-floating"}>
                                    <select className={"form-select"} required={true} id={"userrole"}
                                        onChange={(e) => {
                                            document.getElementById("email").required = !["caregiver", "6"].includes(e?.target?.value?.toString());
                                            if (["1", "2"].includes(e.target.value)){
                                                document.getElementById("password").pattern = "^(?!123456$)[ -~]{6,}$";
                                            }else{
                                                document.getElementById("password").pattern = "[ -~]{6,}$";
                                            }
                                            formCheck("addForm", "addSubmit")}}>
                                        {roleOptions}
                                    </select>
                                    <label htmlFor={"userrole"}>User Role</label>
                                </div>
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {groupAddTableHead}
                                            </thead>
                                            <tbody>
                                                {groupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"}>
                                <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                        data-bs-dismiss={"modal"}>Cancel
                                </button>
                                <Button type={"button"} class={"btn btn-primary d-none"} disabled={true} spinner={true}
                                        text={"Loading"} id={"loadAddSubmit"}/>
                                <button type={"button"} className={"btn btn-primary"} id={"addSubmit"} onClick={() => formAddSubmit()}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className={"modal fade"} id={"editmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Edit User</h2>
                            <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"editForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <input type={"hidden"} id={"userIdE"} />
                                <input type={"hidden"} id={"usernameE"} />
                                <input type={"hidden"} id={"cognitoE"} />
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"displayE"}
                                            onKeyUp={() => formCheck("editForm", "editSubmit")} required={true} pattern="[a-zA-Z ]+"/>
                                    <label htmlFor={"displayE"}>Display Name</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a display name!
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"emailE"}
                                           onKeyUp={() => formCheck("editForm", "editSubmit")} pattern={"[A-Za-z0-9._%+\\-]+@[A-Za-z0-9.\\-]+\\.[A-Za-z]{2,}$"} />
                                    <label htmlFor={"emailE"}>Email</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid email!
                                    </div>
                                </div>
                                <div className={"form-floating"} id={"roledivE"}>
                                    <select className={"form-select"} required={true} id={"userroleE"}
                                            onChange={(e) => {
                                                document.getElementById("emailE").required = !["caregiver", "6"].includes(e?.target?.value?.toString());
                                                formCheck("editForm", "editSubmit")}}>
                                        {roleOptions}
                                    </select>
                                    <label htmlFor={"userroleE"}>User Role</label>
                                </div>
                                <div className={"form-floating d-none"} id={"roledisplaydivE"}>
                                    <select className={"form-select"} required={false} id={"roledisplayE"} disabled={true}>
                                        {roleDisplayOptions}
                                    </select>
                                    <label htmlFor={"roledisplayE"}>User Role</label>
                                </div>
                                <hr />
                                <div className={"mt-1"}>
                                    <h3>Groups</h3>
                                    <div className={"form-outline d-inline-flex align-bottom ms-2 my-3"}>
                                        <input type={"search"} id={"search2E"} className={"form-control search-input"}
                                               value={search2}
                                               onChange={(e) => setSearch2(e.target.value)} />
                                        <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left"}
                                                style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                                    </div>
                                    <div style={{maxHeight: "400px", overflowY: "auto"}}>
                                        <table className={"table table-sm table-white table-hover"}>
                                            <thead className={"sticky-table-head"} style={{top: "0px"}}>
                                                {groupTableHead}
                                            </thead>
                                            <tbody>
                                                {groupTableRows}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className={"modal-footer"} style={{justifyContent: "space-between"}}>
                                <div>
                                    {editable && <Button text={"Delete"} type={"button"} class={"btn btn-danger me-3"}
                                                         id={"deleteSingleBtn"} icon={true}
                                                         iconClass={"fe fe-trash-2 me-2"}/>
                                    }
                                    {editable && <Button text={"Password Reset"} type={"button"} class={"btn btn-primary ms-3"}
                                                         id={"passBtn"} icon={true} iconClass={"fe fe-lock me-2"}
                                                         onClick={() => swapModal("cancelEditBtn", "passmodal")} />
                                    }
                                </div>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelEditBtn"} data-bs-dismiss={"modal"}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadEditSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary ms-3"} id={"editSubmit"} onClick={() => formEditSubmit()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <SingleDeleteModal deletePerform={() => deletePerform("deleteSubmit", "loadDeleteSubmit", "confirmClose", ppRules.start, ppRules.end)}
                                header={"Delete User"}/>
            <BulkDeleteModal deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", ppRules.start, ppRules.end)}
                             start={ppRules.start} end={ppRules.end} header={"Delete Users"} />
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <div className={"modal fade"} id={"passmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                 tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                <div className={"modal-dialog"}>
                    <div className={"modal-content"}>
                        <div className={"modal-header bg-pal"}>
                            <h2 className={"modal-title"} id={"staticBackdropLabel"}>Reset Password</h2>
                            <button type={"button"} className={"btn-close"} id={"passClose"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                        </div>
                        <form id={"passForm"} className={"was-validated"}>
                            <div className={"modal-body"}>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordR"}
                                            onKeyUp={() => formCheck("passForm", "passSubmit")}
                                           required={true} />
                                    <label htmlFor={"passwordR"}>New Password</label>
                                    <div className={"invalid-feedback"}>
                                        Please enter a valid password! 6 Character minimum.
                                    </div>
                                </div>
                                <div className={"form-floating mb-3"}>
                                    <input type={"text"} className={"form-control"} id={"passwordCR"}
                                            onKeyUp={() => formCheck("passForm", "passSubmit")} required={true}  pattern={"[ -~]{6,}"} />
                                    <label htmlFor={"passwordCR"}>Confirm Password</label>
                                    <div className={"invalid-feedback"}>
                                        Password does not match.
                                    </div>
                            </div>
                                </div>
                            <div className={"modal-footer"}>
                                <div>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelPassBtn"}
                                            onClick={() => {
                                                swapModal("passClose", "editmodal");
                                                formClear("passForm", "passSubmit");
                                            }}>Cancel</button>
                                    <Button type={"button"} class={"btn btn-primary d-none ms-3"} disabled={true} spinner={true} text={"Loading"} id={"loadPassSubmit"}/>
                                    <button type={"button"} className={"btn btn-danger ms-3"} id={"passSubmit"} onClick={() => passSubmit()}>Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <input className={"d-none"} type={"hidden"} id={"lastIndex"} />
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    ) : <Navigate to={"/"}/>;
};

export default UserManagement;