import { Link, Navigate } from 'react-router-dom';
import { ReactSession } from 'react-client-session';
import {AES, enc} from "crypto-js";
import {Suspense, useEffect, useState} from "react";
import Button from "./component-assets/Button";
import {
    actionAppearance, checkAll, showModal, formFail, handleSubmitActions,
    paginateData, sortFunc, formSuccess, iAmHigherAuthority, formCheck, bulkDeleteModal
} from "../libs";
import ApiRequester from "./ApiRequester";
import TableBody from "./component-assets/TableBody";
import TableHeaders from "./component-assets/TableHeaders";
import {CSVLink} from "react-csv";
import {Pagination} from "react-bootstrap";
import PaginationItems from "./component-assets/PaginationItems";
import Header from "./Header";
import Footer from "./Footer";
import BulkDeleteModal from "./component-assets/BulkDeleteModal";
import PerPageModal from "./component-assets/PerPageModal";
import {toast, ToastContainer} from "react-toastify";
import CampusDropDown from "./component-assets/CampusDropDown";

const ServerDevices = (props) => {
    let dData, bData, headerData, csvHeaders;

    headerData = [
        {label: "Description", key: "description", align: "left",
            link: {enabled: true, linkto: "/profiles/server", linkkey: "palcare_device_id"}},
        {label: "Service", key: "palcare_interface_name", align: "center"},
        {label: "Area", key: "area_name", align: "left",
            link: {enabled: true, linkto: "/profiles/area", linkkey: "area_id"}},
        {label: "IP Address", key: "ip_address", align: "center"},
        {label: "Mac Address", key: "mac_addr", align: "center",
            link: {enabled: true, linkto: "/profiles/server", linkkey: "palcare_device_id"}},
        {label: "Status", key: "is_active", align: "center", type: "bool", f: "Backup", t: "Active"}
    ];
    csvHeaders = [
        {label: "Description", key: "description"},
        {label: "Area", key: "area_name"},
        {label: "IP Address", key: "ip_address"},
        {label: "Mac Address", key: "mac_addr"},
        {label: "Service", key: "palcare_interface_name"}
    ];

    if (ReactSession.get("PAL") != null){
        bData = AES.decrypt(ReactSession.get("PAL"), process.env.REACT_APP_ESECRET);
        dData = JSON.parse(bData.toString(enc.Utf8));
    }

    // Permission Variables
    const [addRights, setAddRights] = useState(iAmHigherAuthority(80, dData.role_weight));
    const [deleteRights, setDeleteRights] = useState(iAmHigherAuthority(80, dData.role_weight));
    // Main Table Variables
    const [dataOrigin, setDataOrigin] = useState([]);
    const [activeData, setActiveData] = useState([]);
    const [tableRows, setTableRows] = useState([]);
    const [loadState, setLoadState] = useState("server");
    const [locationData, setLocationData] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [sorter, setSorter] = useState({
        sortColumn: "description",
        sortOrder: "asc"
    });
    const [count, setCount] = useState(0);
    const [campusId, setCampusId] = useState(dData.acnt);
    const [storePage, setStorePage] = useState(1);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(dData.perPage);
    const [tmpPerPage, setTmpPerPage] = useState(dData.perPage);
    const [dContent, setDContent] = useState([]);
    const [deviceIdList, setDeviceIdList] = useState([]);
    const [search, setSearch] = useState("");
    const [areaSorter, setAreaSorter] = useState({
        sortColumn: "area_name",
        sortOrder: "asc"
    });
    const [ppRules, setPRules] = useState({
        start: 0,
        end: 1,
        beginDot: false,
        endDot: false,
        pageMin: 1,
        pageMax: 1,
        max: 1
    });
    // Other Enact Once Variables
    const [csvLink, setLink] = useState({
        filename: "server_devices.csv",
        headers: [],
        data: []
    });
    const [csvButton, setCSVButton] = useState(
        <CSVLink {...csvLink} uFEFF={false}>
            <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
        </CSVLink>);

    useEffect(() => {
        let interval = null;
        interval = setInterval(() => {
             setCount(count => count - 1);
        }, 1000);
        if (count <= 0){
            ApiRequester({reqEndpoint: "myfetch", payload: {device_filter: "", campus_id: campusId},
                                procedure: "sp_dev_getPalcareDevicesByCampusID", reqType: "stored"}).then(data => {
                setDataOrigin(data);
            });
            ApiRequester({reqEndpoint: "myfetch", pKey: "campus_id", pVal: dData.acnt,
                                procedure: "sp_dev_getareasbycampusid", reqType: "stored"}).then(data3 => {
                setLocationData(data3);
            });
            setCount(count => 600);
        }
        return () => clearInterval(interval);
    }, [count]);

    useEffect(() => {
        let pRules = paginateData(activeData, page, perPage);
        setPRules(pRules);
        if (activeData.length > 0) {
            activeData.sort((a, b) => sortFunc(a, b, sorter.sortColumn, sorter.sortOrder));
        }
        setTableRows(<TableBody start={pRules.start} end={pRules.end} tableData={activeData} checkbox={deleteRights}
                                writeAccess={deleteRights} unk={"a-"} modal={false}
                                actionAppearance={() => actionAppearance(pRules.start, pRules.end, ["deleteBtn"])}
                                tableHeaders={headerData} editModal={() => {}} parentComponent={"Community"}
                                dData={dData} checkboxlist={deviceIdList} setCheckboxId={(value) => {setDeviceIdList(value)}}
                                checkidkey={"palcare_device_id"} />);
    }, [activeData, sorter, page, perPage, dataOrigin, deviceIdList]);

    useEffect(() => {
        setLink(prevState => {
            return {...prevState,
                headers: csvHeaders,
                data: dataOrigin}
        });
    }, [dataOrigin]);

    useEffect(() => {
        let i, tmp = [];
        locationData.sort((a, b) => sortFunc(a, b, areaSorter.sortColumn, areaSorter.sortOrder));
        for (i = 0; i < locationData.length; i++){
            tmp.push(
                <option key={"location-option"+i.toString()} value={locationData[i].area_id}>{locationData[i].area_name}</option>
            );
        }
        setLocationOptions(() => tmp);
    }, [locationData]);

    useEffect(() => {
        setCSVButton(
            <CSVLink {...csvLink} uFEFF={false}>
                <Button text={"Export"} icon={true} iconClass={"fe fe-download me-2"} class={"btn btn-primary ms-2 px-4 mt-1"}/>
            </CSVLink>
        );
    }, [csvLink]);

    useEffect(() => {
        // Performs realtime search of data as location types.
        let tmpData, input, pRules;
        tmpData = [];
        input = search.toString().toLowerCase();
        for (let i=0; i<dataOrigin.length; i++){
            if ((dataOrigin[i]?.description && dataOrigin[i]?.device_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.ip_address && dataOrigin[i]?.ip_address?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.area_name && dataOrigin[i]?.area_name?.toLowerCase()?.search(input) !== -1) ||
                (dataOrigin[i]?.mac_addr && dataOrigin[i]?.mac_addr?.toLowerCase()?.search(input) !== -1)){
                tmpData.push(dataOrigin[i]);
            }
        }
        pRules = paginateData(tmpData, page, perPage);
        if (input && page > pRules.pageMax){
            setPage(pRules.pageMax);
        }else if (!input){
            setPage(storePage);
        }
        setPRules(pRules);
        setActiveData(tmpData);
    }, [search, dataOrigin]);

    function filterSubmit(){
        handleSubmitActions("filterSubmit", "loadFilterSubmit");
        setPerPage(tmpPerPage);
        setDeviceIdList([]);
        dData.perPage = tmpPerPage;
        setPage(1);
        ReactSession.set("PAL", AES.encrypt(JSON.stringify(dData), process.env.REACT_APP_ESECRET).toString());
        formSuccess("Page parameters stored to session.", "filterSubmit", "loadFilterSubmit", "cancelFilterBtn");
        setCount(0);
    }

    function formAddSubmit(){
        // User add form submit function
        let macAddr, size, payload, description, areaId, interfaceId, backup;
        if (addRights){
            handleSubmitActions("addSubmit", "loadAddSubmit");
            macAddr = document.getElementById("mac").value;
            macAddr = macAddr.replaceAll(":", "").replaceAll("-", "").toUpperCase();
            size = document.getElementById("campusSize").value;
            description = document.getElementById("description").value;
            areaId = document.getElementById("locationId").value;
            interfaceId = document.getElementById("interface").value;
            backup = document.getElementById("isBackup").checked;
            payload = [{mac_addr: macAddr, campus_id: campusId, size: size, description: description, product_id: 5,
                        interface_id: parseInt(interfaceId), area_id: parseInt(areaId), is_active: !backup,
                        palcare_interface_id: parseInt(interfaceId)}];
            ApiRequester({reqEndpoint: "mytransaction", TransType: "server_add", Payload: payload,
                                ClientId: dData.sessionId, User: dData.user}).then(data => {
                if (data[0] === true){
                    formSuccess(`${macAddr} has been added.`, "addSubmit", "loadAddSubmit",
                        "cancelAddBtn", "addForm");
                    setCount(0);
                }
                else{
                    formFail(`Error during add, ${data[1]}`, "addSubmit", "loadAddSubmit");
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    function deletePerform(submitBtn, loadBtn, closeId, start, end){
        let payload = [], idList = [];
        if (deleteRights){
            handleSubmitActions(submitBtn, loadBtn);
            for (let i=0; i < dContent.length; i++){
                idList.push(dContent[i][0])
            }
            for (let i2=0; i2 < idList.length; i2++){
                for (let i3=0; i3 < dataOrigin.length; i3++){
                    if (idList[i2] === dataOrigin[i3]?.palcare_device_id){
                        payload.push({palcare_device_id: idList[i2], campus_id: dData.acnt,
                            mac_addr: dataOrigin[i3]?.mac_addr});
                        break;
                    }
                }
            }
            ApiRequester({reqEndpoint: "mytransaction", TransType: "server_delete", Payload: payload,
                                User: dData.user, ClientId: dData.sessionId}).then(data => {
                if (data[0]){
                    setCount(0);
                    formSuccess("Servers deleted", submitBtn, loadBtn, closeId);
                    document.getElementById("uCheckHead").checked = false;
                    checkAll(start, end);
                    actionAppearance(start, end, ["deleteBtn"]);
                }
                else{
                    formFail("Server delete failed, contact support for assistance.", submitBtn, loadBtn);
                }
            });
        }else{
            toast.warn("You do not have permissions to perform this action.");
        }
    }

    const tableHeaders = <TableHeaders checkbox={deleteRights} headerData={headerData} checkAll={() => checkAll(ppRules.start, ppRules.end, deviceIdList, setDeviceIdList)}
                                   writeAccess={deleteRights} sortOrder={sorter.sortOrder}
                                   sortColumn={sorter.sortColumn} sorter={sorter} setSorter={setSorter} checkboxlist={deviceIdList}
                                   actionAppearance={() => actionAppearance(ppRules.start, ppRules.end, ["deleteBtn"])}/>

    const headerContent = (
        <div className={"d-flex justify-content-between"}>
            <div style={{minWidth: "360px"}}>
                {deleteRights && <Button text={"Disable"} type={"button"} class={"btn btn-danger me-2 hide"}
                                     id={"deleteBtn"} icon={true} iconClass={"fe fe-trash-2 me-2"}
                                     onClick={() => bulkDeleteModal(ppRules.start, ppRules.end, activeData,
                                         ["description", "mac_addr"], ["palcare_device_id", ""], "", setDContent)} />}
                <div className={"form-outline d-inline-flex align-bottom ms-2"}>
                    <input type={"search"} id={"search1"} className={"form-control search-input mt-1"}
                         onKeyUp={(e) => setSearch(e.target.value)} />
                    <Button text={""} type={"button"} class={"btn btn-primary smooth-radius-left mt-1"}
                            style={{height: "42px"}} icon={true} iconClass={"fe fe-search"}/>
                </div>
                {csvButton}
                {addRights && <Button text={"New Server"} type={"button"} class={"btn btn-primary ms-2 mt-1"}
                                     icon={true} iconClass={"fe fe-plus me-2"}
                                     onClick={() => {
                                         document.getElementById("locationId").value = "";
                                         document.getElementById("interface").value = 3;
                                         document.getElementById("campusSize").value = "2";
                                         showModal("addmodal");}} />}
            </div>
            <CampusDropDown campusId={campusId} setCampusId={(item) => setCampusId(item)}
                            setCount={(item) => setCount(item)} pageAction={() => {}} />
        </div>
    );

    const footer = (
        <div>
            <Pagination>
                <PaginationItems setPage={setPage} page={page} beginDot={ppRules.beginDot}
                                 endDot={ppRules.endDot} pageMin={ppRules.pageMin} pageMax={ppRules.pageMax}
                                 max={ppRules.max}/>
            </Pagination>
            <Button class={"btn btn-secondary fs-5 ms-4"} text={perPage} onClick={() => showModal("filtermodal")}/>
        </div>
    );

    return (
        <>
            <div className={"main-content"}>
                <Header preTitle={"Community"} Title={"Devices"} content={headerContent}/>
                <div className={"container-fluid"}>
                    <ul className={"nav nav-tabs nav-overflow header-tabs"}>
                        <li className={"nav-item"}>
                            <Link to={"/community/devices"}>
                                <Button text={"Nurse Call"}
                                        class={loadState === "inovonics" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("inovonics")}/>
                            </Link>
                        </li>
                        <li className={"nav-item"}>
                            <Link to={"/community/wander_devices"}>
                                <Button text={"Wandering"}
                                        class={loadState === "accutech" ? "nav-link active" : "nav-link"}
                                        onClick={() => setLoadState("accutech")}/>
                            </Link>
                        </li>
                        {iAmHigherAuthority(80, dData?.role_weight)
                            ? <>
                                <li className={"nav-item"}>
                                    <Link to={"/community/system_devices"}>
                                        <Button text={"System Devices"}
                                                class={loadState === "system" ? "nav-link active" : "nav-link"}
                                                onClick={() => setLoadState("system")}/>
                                    </Link>
                                </li>
                                <li className={"nav-item"}>
                                    <Link to={"/community/server_devices"}>
                                        <Button text={"Servers"}
                                                class={loadState === "server" ? "nav-link active" : "nav-link"}
                                                onClick={() => setLoadState("server")}/>
                                    </Link>
                                </li>
                            </>
                            : <></>
                        }
                    </ul>
                    <table className={"table table-sm table-white table-hover"}>
                        <thead className={dData?.corp && dData?.myCorp?.toString() !== dData?.corp?.toString() ? "sticky-table-headX" : "sticky-table-head"}>
                        {tableHeaders}
                        </thead>
                        <tbody id={"tBody"}>
                        {tableRows}
                        </tbody>
                    </table>
                    <div className={"footer-spacer"}>

                    </div>
                </div>
                <div className={"modal fade"} id={"addmodal"} data-bs-backdrop={"static"} data-bs-keyboard={"false"}
                     tabIndex={-1} aria-labelledby={"staticBackdropLabel"} aria-hidden={"true"}>
                    <div className={"modal-dialog"}>
                        <div className={"modal-content"}>
                            <div className={"modal-header bg-pal"}>
                                <h2 className={"modal-title"} id={"staticBackdropLabel"}>Add Server</h2>
                                <button type={"button"} className={"btn-close"} data-bs-dismiss={"modal"} aria-label={"Close"} />
                            </div>
                            <form id={"addForm"} className={"was-validated"}>
                                <div className={"modal-body"}>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"mac"}
                                               onKeyUp={() => formCheck("addForm", "addSubmit")}
                                               required={true}
                                               pattern={"^([0-9A-Fa-f]{2}[:\\-]){5}([0-9A-Fa-f]{2})|[0-9A-Fa-f]{12}$"}/>
                                        <label htmlFor={"mac"}>MAC Address</label>
                                        <div className={"invalid-feedback"}>
                                            Enter a valid mac address, colons and dashes optional
                                        </div>
                                    </div>
                                    <div className={"mb-3"}>
                                        <div className={"form-floating d-inline-block"} style={{width: "66%"}}>
                                            <select className={"form-select"} value={undefined} required={true}
                                                    id={"campusSize"}
                                                    onChange={() => formCheck("addForm", "addSubmit")}>
                                                <option value={"1"}>Green Server</option>
                                                <option value={"2"}>White Server</option>
                                            </select>
                                            <label htmlFor={"campusSize"}>Server Type</label>
                                        </div>
                                        <div className={"form-check form-switch d-inline-block ms-3"} style={{verticalAlign: "super"}}>
                                            <input className={"form-check-input"} id={"isBackup"} type={"checkbox"}/>
                                            <label className={"form-check-label text-dark"} htmlFor={"isBackup"}>Backup Server</label>
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <select className={"form-select"} value={undefined} required={true}
                                                id={"interface"}
                                                onChange={() => formCheck("addForm", "addSubmit")}>
                                            <option value={2}>Firepanel</option>
                                            <option value={1}>Hard-Wired</option>
                                            <option value={3}>Inovonics</option>
                                        </select>
                                        <label htmlFor={"interface"}>Product Interface</label>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <input type={"text"} className={"form-control"} id={"description"}
                                               onKeyUp={() => formCheck("addForm", "addSubmit")}
                                               pattern={"^[0-9a-zA-Z_ \\-]+$"} required={true}/>
                                        <label htmlFor={"description"}>Description</label>
                                        <div className={"invalid-feedback"}>
                                            Accepts alpha numeric characters, spaces, dashes, and underscores
                                        </div>
                                    </div>
                                    <div className={"form-floating mb-3"}>
                                        <select className={"form-select"} value={undefined} required={true}
                                                id={"locationId"}
                                                onChange={() => formCheck("addForm", "addSubmit")}>
                                            <option value={""}></option>
                                            {locationOptions}
                                        </select>
                                        <label htmlFor={"locationId"}>Area</label>
                                    </div>
                                </div>
                                <div className={"modal-footer"}>
                                    <button type={"button"} className={"btn btn-secondary"} id={"cancelAddBtn"}
                                            data-bs-dismiss={"modal"}>Cancel
                                    </button>
                                    <Button type={"button"} class={"btn btn-primary d-none"} disabled={true}
                                            spinner={true} text={"Loading"} id={"loadAddSubmit"}/>
                                    <button type={"button"} className={"btn btn-primary"}
                                            onClick={() => formAddSubmit()} id={"addSubmit"}>Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <Footer center={footer}/>
            </div>
            <BulkDeleteModal deletePerform={() => deletePerform("bulkDeleteSubmit", "loadBulkDeleteSubmit", "bulkConfirmClose", ppRules.start, ppRules.end)}
                             start={ppRules.start} end={ppRules.end} header={"Disable Devices"} />
            <PerPageModal filterSubmit={() => filterSubmit()} tmpPerPage={tmpPerPage} setTmpPerPage={(sudocode) => setTmpPerPage(sudocode)} setPage={(sudocode) => setPage(sudocode)}/>
            <input type={"hidden"} id={"dContent"}/>
            <input type={"hidden"} id={"uContent"}/>
            <Suspense fallback={"Loading..."}>
                <ToastContainer position={"bottom-right"} theme={"colored"} newestOnTop={true} pauseOnHover={true} autoClose={10000} />
            </Suspense>
        </>
    );
};

export default ServerDevices;